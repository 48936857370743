import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./AvailabilityEdit.scss";
import { useEffect, useState } from "react";
import { CustomDropDown } from "../../Components/common/CustomDropDown/CustomDropDown";
import { updateCandidateAvailability } from "../../APIs/devReport";

const text1 = "Developers";
const text2 = "Edit Availability";

const availibilityOptions = [
  "immediate",
  "serving notice period",
  "not yet resigned",
];

export const AvailabilityEdit = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [days, setDays] = useState(0);


  const updateAvailability = (user) => {
    if (user?.lastWorkingDate && user?.lastWorkingDate.includes(' ')) {
      user.lastWorkingDate = user.lastWorkingDate.replace(' ', 'T');
    }
    if (user?.joiningDate && user?.joiningDate.includes(' ')) {
      user.joiningDate = user.joiningDate.replace(' ', 'T');
    }
    return user;
  };

  const handleUpdate = async () => {
    // updateCandidateAvailability;
    try {
      const data = {
        name: userData?.emailId,
        availability: userData?.availabilityStatus,
        candName: userData?.userName,
      };
      if (
        userData?.availabilityStatus?.toLowerCase() ===
        availibilityOptions[2].toLowerCase()
      ) {
        data["noticePeriod"] = days;
      } else if (
        userData?.availabilityStatus.toLowerCase() ===
        availibilityOptions[1].toLowerCase()
      ) {
        data["lastWorkingDate"] = userData.availability;
      } else if (
        userData?.availabilityStatus.toLowerCase() ===
        availibilityOptions[0].toLowerCase()
      ) {
        data["joiningDate"] = userData.availability;
      }
      const updatedData = updateAvailability(data);
      const res = await updateCandidateAvailability(updatedData);
      if (res) {
        navigate("/view-developers");
      }
    } catch (err) {
      console.log("handleUpdate error==>", err);
    }
  };

  useEffect(() => {
    setUserData({
      ...JSON.parse(sessionStorage.getItem("edit_user_availaability")),
    });

    return () => {
      sessionStorage.removeItem("edit_user_availaability");
    };
  }, []);

  return (
    <div className="availibilty-edit">
      <GoToAllUsers title={text1} />
      <EditTitle title={text2} />
      <UserName name={userData?.userName} />
      {userData?.emailId && (
        <EditData
          availability={userData?.availability}
          availabilityStatus={
            userData?.availabilityStatus
              ? userData?.availabilityStatus.toLowerCase()
              : ""
          }
          setUserData={setUserData}
          days={days}
          setDays={setDays}
        />
      )}
      <SubmitButton handleUpdate={handleUpdate} />
    </div>
  );
};

const GoToAllUsers = ({ title }) => {
  return (
    <div className="back-arrow">
      <Link to={"/view-developers?page=1"} className="goto">
        <span className="arrow-main">
          <ArrowBackIosIcon />
        </span>
        <span>{title}</span>
      </Link>
    </div>
  );
};

const UserName = ({ name }) => {
  return <div className="userName text2">{name}</div>;
};

const EditTitle = ({ title }) => {
  return <div className="userName">{title}</div>;
};

const EditData = ({
  availability,
  availabilityStatus,
  setUserData,
  days,
  setDays,
}) => {
  const handleAvailabilityStatusChange = (val) => {
    setUserData((prev) => ({ ...prev, availabilityStatus: val }));
  };

  const handleAvailabilityChange = (val) => {
    setUserData((prev) => ({ ...prev, availability: val }));
  };

  return (
    <div className="availability-status">
      <CustomDropDown
        options={availibilityOptions}
        label={"Availability"}
        handleChange={(val) => handleAvailabilityStatusChange(val)}
        value={availabilityStatus}
        placeholder={"Select..."}
      />
      <div className="input-container">
        <div className="label">
          {availabilityStatus?.toLowerCase() === "serving notice period"
            ? "Last Working Date"
            : availabilityStatus?.toLowerCase() === "immediate"
              ? "Joining Date"
              : availabilityStatus?.toLowerCase() === "not yet resigned"
                ? "Notice Period"
                : ""}
        </div>
        <div className="input-content">
          <input
            type={
              availabilityStatus?.toLowerCase() === "not yet resigned"
                ? "number"
                : "date"
            }
            value={
              availabilityStatus?.toLowerCase() === "not yet resigned"
                ? days
                : availability?.split(" ")[0]
            }
            onChange={(e) =>
              availabilityStatus?.toLowerCase() === "not yet resigned"
                ? setDays(e.target.value)
                : handleAvailabilityChange(e.target.value)
            }
            placeholder="enter days"
          />
        </div>
      </div>
    </div>
  );
};

const SubmitButton = ({ handleUpdate }) => {
  return (
    <div className="submit-button">
      <button onClick={() => handleUpdate()}>Update</button>
    </div>
  );
};
