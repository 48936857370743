import  React,{useState,useEffect,useRef} from 'react';
import { Dialog,DialogTitle,TextField,Typography,IconButton,Stack, Button } from '@mui/material';
import { formatDateWithYearAndMonth, calculateTotalExperience } from "../../../../utils/dateUtils";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CircleIcon from "@mui/icons-material/Circle";
import circleWithGreenDot from "../../../../assets/svgs/circle-with-green-dot.svg";
import UpdateButton from '../UpdateButton';
import SkillAdd from '../SkillAdd/SkillAdd';

import CloseIcon from '@mui/icons-material/Close';
const emails = ['username@gmail.com', 'user02@gmail.com'];
const formatToInputValue = (dateString) => {
    if (!dateString) return "";
    const [month, year] = dateString.split("/");
    return `${year}-${month}`;
  };
  const formatToMonthYear = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero
    const year = date.getFullYear();
    return `${month}/${year}`;
  };
const EducationAdd = ( {open,onClose,handleSumbit}) => {
    const [work,setWork]=useState({degree:"",
      endDate:"",
      institution
      :"",
      location
      :"", 
      percentage:"",
      startDate:""} )
    const [openSkill,setOpenSkill]=useState(false);
    const [errors, setErrors] = useState({});

    const validateFields = () => {
      let fieldErrors = {};
     
        fieldErrors = {
          degree: !work.degree,
          institution: !work.institution,
          startDate: !work.startDate,
          endDate: !work.endDate,
          cgpa: !work.cgpa && !work.percentage,
          location:!work.location
        };
     
      setErrors(fieldErrors);
      return !Object.values([fieldErrors]).some((error) => Object.values(error).includes(true));
    };
   
    const onFieldChange = (index, name, value) => {
        setWork((prevWork) => ({
          ...prevWork,
          [name]: value,
        }));
      };
    const index=0;
    const [highlightText, setHighlightText] = useState("");
  
    // Convert initial highlights array to a string with bullets
    useEffect(() => {
      if (work?.highlights && Array.isArray(work?.highlights)) {
        const formattedHighlights = work?.highlights.map((highlight) => `• ${highlight}`).join("\n");
        setHighlightText(formattedHighlights);
      }
    }, [work?.highlights]);
  
    // Function to handle changes in the text area
    const handleHighlightsChange = (value) => {
      setHighlightText(value);
      saveHighlights(value);
    };
  
    // Function to add a new bullet point
    const handleAddBulletPoint = () => {
      const updatedText = `${highlightText}\n• `;
      setHighlightText(updatedText);
    };
  
    // Function to save highlights as an array of strings
    const saveHighlights = (value) => {
      const highlightsArray = value
        .split("\n")
        .map((line) => line.replace(/^•\s*/, "").trim())
        .filter((line) => line !== "");
  
      onFieldChange(index, "highlights", highlightsArray);
    };
  
    // Function to handle start date change and format it to "MM/YYYY"
    const handleStartDateChange = (value) => {
      if (!value) {
        onFieldChange(index, "startDate", "");
        return;
      }
      const formattedDate = formatToMonthYear(value);
      onFieldChange(index, "startDate", formattedDate);
    };
  
    // Function to handle end date change and format it to "MM/YYYY"
    const handleEndDateChange = (value) => {
      if (!value) {
        onFieldChange(index, "endDate", "");
        return;
      }
      const formattedDate = formatToMonthYear(value);
      onFieldChange(index, "endDate", formattedDate);
    };
  
    // Function to remove a skill from the tech stack
    const handleRemoveSkill = (skillIdx) => {
      const updatedTechStack = work?.techStack.filter((_, idx) => idx !== skillIdx);
      onFieldChange(index, "techStack", updatedTechStack);
    };
  
    const textFieldRef = useRef(null); // Ref for the TextField
  
    // Scroll to the bottom when content changes
    useEffect(() => {
      if (textFieldRef.current) {
        textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
      }
    }, [highlightText]);
    const handleAddHighlight = (index) => {
        // const updatedExperiences = [...workExperiences];
        // updatedExperiences[index].highlights.push("");
        // setWorkExperiences(updatedExperiences);
      };

      const handleClickUpdate=()=>{
        console.log("handleClickUpdate",work,highlightText)
        if(validateFields()){
        handleSumbit(work);
        }
      }

      const handleSkillAddSubmit = (techStackArray) => {
        // Filter out empty strings from techStackArray
        const validTechStack = techStackArray.filter((tech) => tech.trim() !== '');
      
        // If validTechStack is not empty, update the state
        if (validTechStack.length > 0) {
          setWork((prevWork) => ({
            ...prevWork,
            techStack: [...(prevWork.techStack || []), ...validTechStack], // Append to existing techStack
          }));
        }
      };
      
      
      const handleCloseSkill=()=>{
        setOpenSkill(false);
      }
  
    return (
        
       
        
        <Dialog open={open} maxWidth="md" sx={{ padding: '20px' }}>
            <SkillAdd open={openSkill} onClose={handleCloseSkill} handleSumbit={handleSkillAddSubmit}/>

        

      {/* Title and Add More Button */}
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 0,
        }}
      >
        <span>Add Education</span>
        <IconButton
          onClick={()=>{setWork({});onClose()}}
          sx={{
            color: '#062B6E',
          }}
        >
          <CloseIcon />
        </IconButton>
        
      </DialogTitle>
          <div
            className="work-experience-item"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "20px", // Added padding
              boxSizing: "border-box", // Ensure padding is considered within width
            }}
          >
            <div
              className="item-container"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
             <div className="designation-main" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div className="designation" style={{ display: "flex", alignItems: "center", gap: "12px" }}>
  
  
  {/* Position TextField with custom styles */}
  <TextField
    // label="Position"
    value={work.degree || ""}
    onChange={(e) => onFieldChange(index, "degree", e.target.value)}
    variant="outlined"
    size="small"
    style={{ width: "220px", marginRight: "12px" }} // Added marginRight for gap
    placeholder='Add Degree'
    error={!!errors.degree}
    InputLabelProps={{
      shrink: true,
      style: {
        color: "#212121",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        letterSpacing: "0.2px",
      },
    }}
    InputProps={{
      style: {
        color: "#212121",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        letterSpacing: "0.2px",
        textAlign: "center",
      },
    }}
  />

  <div className="dot">
    <CircleIcon style={{ color: "green", fontSize: "10px" }} />
  </div>

  <Stack direction="row" spacing={2} alignItems="center" gap="12px" marginLeft={2}>
    {/* Start Date Field with formatting */}
    <TextField
      label="Start Date"
      type="month"
      value={formatToInputValue(work.startDate)}
      onChange={(e) => handleStartDateChange(e.target.value)}
      InputLabelProps={{ shrink: true }}
      size="small"
      style={{ width: "125px", marginRight: "12px" }} // Added marginRight for gap
      error={!!errors.startDate}
    />

    {/* End Date Field with formatting */}
    -
    <TextField
      label="End Date"
      type="month"
      value={formatToInputValue(work.endDate)}
      onChange={(e) => handleEndDateChange(e.target.value)}
      InputLabelProps={{ shrink: true }}
      size="small"
      style={{ width: "125px", marginRight: "12px" }} // Added marginRight for gap
      error={!!errors.endDate}
    />

    <span>
      {work.startDate && work.endDate
        ? `(${calculateTotalExperience(work.startDate, work.endDate)})`
        : ""}
    </span>
  </Stack>
</div>


<TextField

  value={work.institution || ""}
  onChange={(e) => onFieldChange(index, "institution", e.target.value)}
  variant="outlined"
  size="small"
  style={{ width: "200px", textAlign: "center" }} // Center alignment of TextField
  placeholder='Add Institute'
  error={!!errors.institution}
  InputProps={{
    inputProps: {
      style: {
        color: "#4F4F4F",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        letterSpacing: "0.2px",
        textAlign: "left",
      },
    },
  }}
/>

<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
  <span style={{
    color: "#4F4F4F",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.2px"
  }}>
    Location:
  </span>

  <TextField
   
    value={work.location || ""}
    error={!!errors.location}
    placeholder='Add Location'
    onChange={(e) => onFieldChange(index, "location", e.target.value)}
    variant="outlined"
    size="small"
    style={{ width: "200px" }}
    
    InputProps={{
      inputProps: {
        style: {
          color: "#4F4F4F",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          letterSpacing: "0.2px",
          textAlign: "left",
        },
      },
    }}
  />
</div>
<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
  <span style={{
    color: "#4F4F4F",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.2px"
  }}>
    CGPA/Percentage:
  </span>

  <TextField
   placeholder='Add CGPA/Percentage'
    value={work.percentage || work?.cgpa}
    onChange={(e) => {
        onFieldChange(index, "cgpa", e.target.value);
        onFieldChange(index, "percentage", e.target.value)}}
    variant="outlined"
    size="small"
    style={{ width: "200px" }}
    // error={!!work.cgpa}
    
    InputProps={{
      inputProps: {
        style: {
          color: "#4F4F4F",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          letterSpacing: "0.2px",
          textAlign: "left",
        },
      },
    }}
  />
</div>

        </div>
             
        
             
              
        
            

            </div>
            <UpdateButton handleClickUpdateButton={handleClickUpdate} />
          </div>
         
        </Dialog>
        
        
    );
  };

export default EducationAdd;





