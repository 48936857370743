import axios from "axios";
import { CITY_LIST, COUNTRY_LIST, STATE_LIST, USA_CITY_LIST } from "./URLS";

export const getCountries = async () => {
  try {
    const urlEndpoint = COUNTRY_LIST;
    // const Config = {};
    return await axios.get(urlEndpoint).then((res) => res.data);
  } catch (err) {
    console.log("countries error ==>", err.message);
  }
};

export const getStates = async (data) => {
  try {
    const urlEndpoint = STATE_LIST;

    const Config = {};
    return await axios
      .post(urlEndpoint, data, Config)
      .then((response) => response.data);
  } catch (err) {
    console.log("states error ==>", err.message);
  }
};

export const getCities = async (data) => {
  try {
    const urlEndpoint = CITY_LIST;
    const Config = {};
    return await axios
      .post(urlEndpoint, data, Config)
      .then((response) => response.data);
  } catch (err) {
    console.log("states error ==>", err.message);
  }
};
