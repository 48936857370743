import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseIcon from '@mui/icons-material/Close';

const InterestAdd = ({ open, onClose,handleSumbit }) => {
  const [skills, setSkills] = useState(['']); // Initial state with one empty field

  // Function to handle adding new skill field
  const handleAddMore = () => {
    setSkills([...skills, '']); // Adds an empty string to the skills array
  };

  // Function to handle input change for skill fields
  const handleInputChange = (index, value) => {
    const updatedSkills = [...skills];
    updatedSkills[index] = value;
    setSkills(updatedSkills);
  };

  // Function to handle removing a skill field
  const handleRemoveSkill = (index) => {
    const updatedSkills = skills.filter((_, idx) => idx !== index);
    setSkills(updatedSkills);
  };

  // Function to handle saving skills
  const handleSave = () => {
    // Filter out empty strings
    const validSkills = skills.filter((skill) => skill.trim() !== '');
    console.log('Saved Skills:', validSkills,handleSumbit); // Save or process the skills array
    handleSumbit(validSkills);
    setSkills(['']);
    onClose(); // Close the dialog after saving
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {/* Close Icon */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '8px',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            color: '#062B6E',
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      {/* Title and Add More Button */}
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 0,
        }}
      >
        <span>Add Interest</span>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddMore}
          sx={{
            textTransform: 'none',
            color: '#062B6E',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '0.2px',
            borderRadius: '4px',
            border: '1px solid #062B6E',
          }}
        >
          Add More
        </Button>
      </DialogTitle>

      {/* Content with skill fields */}
      <DialogContent dividers={false}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
          {skills.map((skill, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginBottom: '8px',
                width: 'calc(50% - 8px)', // Set each field to 50% width
              }}
            >
              <TextField
                value={skill}
                onChange={(e) => handleInputChange(index, e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                placeholder={`Interest ${index + 1}`}
                InputProps={{
                  style: {
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                  },
                }}
              />
              <IconButton
                size="small"
                onClick={() => handleRemoveSkill(index)}
                aria-label="remove skill"
                sx={{ color: '#062B6E' }}
                disabled={!skill.trim()} // Enable button only if the field is not empty
              >
                <CancelRoundedIcon />
              </IconButton>
            </div>
          ))}
        </div>
      </DialogContent>

      {/* Save Button */}
      <DialogActions
        sx={{
          justifyContent: 'flex-start', // Aligns the button to the left
        }}
      >
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{
            backgroundColor: '#062B6E',
            color: '#FFF',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 500,
            textTransform: 'none',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#041C42',
            },
            marginLeft:'16px'
          }}
        >
         Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InterestAdd;
