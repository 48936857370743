import Popup from "../popUp/popUp";
import close from "../../../Assessts/SVGs/Close.svg";
import "./CommonDialog.scss";
export const CommonDialog = ({ data, buttonsList }) => {
  return (
    <Popup>
      <div className="dialog-main">
        <div className="dialog-close" onClick={buttonsList?.Close?.action}>
          <img src={close} alt="close-icon" />
        </div>
        {data?.icon && (
          <div>
            <img src={data?.icon} alt="icon" />
          </div>
        )}
        <div className="dialog-title1" style={data?.style1}>
          {data?.message1}
        </div>
        <div className="dialog-title2">{data?.message2}</div>
        <div className="action-buttons">
          {buttonsList?.Cancel && (
            <button
              className="dialog-cancel"
              onClick={buttonsList?.Cancel?.action}
            >
              {buttonsList?.Cancel?.name}
            </button>
          )}
          {buttonsList?.Okay && (
            <button className="dialog-okay" onClick={buttonsList?.Okay?.action}>
              {buttonsList?.Okay?.name}
            </button>
          )}
          {buttonsList?.Delete && (
            <button
              className="dialog-okay"
              onClick={buttonsList?.Delete?.action}
            >
              {buttonsList?.Delete?.name}
            </button>
          )}
        </div>
      </div>
    </Popup>
  );
};
