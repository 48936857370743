import { Link, useNavigate } from "react-router-dom";
import "./ProgressEdit.scss";
import { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CustomDropDown } from "../../Components/common/CustomDropDown/CustomDropDown";
import { UPDATE_USER_PROGRESS } from "../../APIs/URLS";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../APIs/axiosInstance";

const text1 = "Developers";
const text2 = "Edit Progress";
const text3 = "Current State";
const text4 = "Desired State";

const progressOptions = [
  "Professional Details",
  "Round1",
  "Round2",
  "Round3",
  "Round4",
  "Round5",
];

export const ProgressEdit = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [updatedProgress, setUpdatedProgress] = useState("");

  const handleUpdate = async () => {
    // updateCandidateAvailability;
    try {
      const endPoint = UPDATE_USER_PROGRESS;

      const selectedRoundIndex = progressOptions.indexOf(updatedProgress);
      // Calculate the end index for the slice; if `userData.progress` is not defined or out of bounds,
      // slice to the end of the array
      const endIndex =
        userData?.progress && userData.progress < progressOptions.length
          ? userData.progress
          : progressOptions.length;

      // Slice from the element right after selectedRoundIndex to endIndex (non-inclusive of endIndex)
      const deleteData = progressOptions.slice(
        selectedRoundIndex + 1,
        endIndex
      );

      const data = {
        id: userData?.emailId,
        round: deleteData,
      };

      console.log(data);
      const Config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
        },
        data: data,
      };

      const res = await axiosInstance.delete(endPoint, Config);
      if (res) {
        console.log(res);
        sessionStorage.removeItem("edit_user_progress");
        navigate("/view-developers?page=1");
      }
    } catch (err) {
      console.log("handleUpdate error progress==>", err);
      toast.error(err.response.data.exception);
    }
  };

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("edit_user_progress"));
    console.log(data);
    setUserData({ ...data });
    setUpdatedProgress(data?.progress);

    return () => {
      sessionStorage.removeItem("edit_user_progress");
    };
  }, []);

  return (
    <div className="availibilty-edit">
      <GoToAllUsers title={text1} />
      <EditTitle title={text2} />
      <UserName name={userData?.userName} />
      <UserProgress
        userData={userData}
        updatedProgress={updatedProgress}
        setUpdatedProgress={setUpdatedProgress}
      />
      <SubmitButton handleUpdate={handleUpdate} />
    </div>
  );
};

const GoToAllUsers = ({ title }) => {
  return (
    <div className="back-arrow">
      <Link to={"/view-developers?page=1"} className="goto">
        <span className="arrow-main">
          <ArrowBackIosIcon />
        </span>
        <span>{title}</span>
      </Link>
    </div>
  );
};

const UserName = ({ name }) => {
  return <div className="userName text2">{name}</div>;
};

const EditTitle = ({ title }) => {
  return <div className="userName">{title}</div>;
};

const SubmitButton = ({ handleUpdate }) => {
  return (
    <div className="submit-button">
      <button onClick={() => handleUpdate()}>Update</button>
    </div>
  );
};

const UserProgress = ({ userData, updatedProgress, setUpdatedProgress }) => {
  // Filter options based on progress
  const progressOptions2 = progressOptions.filter((option) => {
    const optionIndex = progressOptions.indexOf(option);
    const progressIndex = progressOptions.indexOf(userData.progress);
    return optionIndex <= progressIndex;
  });
  return (
    <div className="progress-update">
      <div className="col-1">
        <div className="text">{text3}</div>
        <div className="item">{userData?.progress}</div>
      </div>
      <div>
        <div className="text">{text4}</div>
        <div>
          <CustomDropDown
            options={progressOptions2}
            handleChange={(val) => setUpdatedProgress(val)}
            value={updatedProgress}
            placeholder={"Select..."}
          />
        </div>
      </div>
    </div>
  );
};
