import React, { useEffect, useState } from "react";
import Popup from "../common/popUp/popUp";
import closeIcon from "../../Assessts/SVGs/Close.svg";
import deleteIcon from "../../Assessts/SVGs/delete.svg";
import addIcon from "../../Assessts/SVGs/add.svg";
import sucessIcon from "../../Assessts/SVGs/success.svg";
import "./AddRubric.scss";
import { postExpertRubricQuestion, postHRRubricQuestion, updateExpertRubricQuestion, updateHRRubricQuestion } from "../../APIs/rubric";
import { CommonDialog } from "../common/Dialog/CommonDialog";

const AddRubric = ({
  setShowRubricDialog,
  sectionName,
  questions,
  setSectionName,
  setQuestions,
  isEdit,
  oldSectionName,
  user
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [buttonsList, setButtonsList] = useState({});

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  });

  useEffect(() => {
    const isDisabled =
      !(sectionName?.trim() || "") ||
      questions.some((question) => question.text.trim() === "");
    setIsSaveButtonDisabled(isDisabled);
  }, [sectionName, questions]);

  const handleSubmit = async () => {
    const key = user === "HR" ? "hrAssessmentQuestions" : "expertAssessmentQuestions" ;
    let data = {
      [key]: [
        {
          sectionName,
          questions: questions.map((que) => que.text),
        },
      ],
    };
    let res;
    if (isEdit) {
      data.oldSectionName =  oldSectionName;
      res = user === "HR" ? await updateHRRubricQuestion(data) : await updateExpertRubricQuestion(data);
    } else {
      res = user === "HR" ? await postHRRubricQuestion(data) : await postExpertRubricQuestion(data);
    }
    if (res) {
      setShowDialog(true);
      const message = isEdit
        ? "Rubric has been updated."
        : "A new rubric has been added.";
      const data = {
        message2: message,
        icon: sucessIcon,
      };
      setDialogData({ ...data });
      setButtonsList({
        Okay: {
          name: "Okay",
          action: handleDialogClose,
        },
        Close: {
          name: "Close",
          action: handleDialogClose,
        },
      });
      setSectionName("");
      setQuestions([{ id: Date.now(), text: "" }]);
    }
  };

  const handleAddButton = () => {
    setQuestions([...questions, { id: Date.now(), text: "" }]);
  };

  const handleDeleteButton = (id) => {
    setQuestions(questions.filter((question) => question.id !== id));
  };

  const handleQuestionChange = (id, text) => {
    setQuestions(
      questions.map((question) =>
        question.id === id ? { ...question, text } : question
      )
    );
  };

  const handleDialogClose = () => {
    setShowRubricDialog(false);
  };

  const handleReset = () => {
    questions.length = 1;
    questions[0].text = "";
    setQuestions([...questions]);
  };

  return (
    <>
      <Popup style={{ marginTop: "70px" }}>
        <div className="add-rubric-main">
          <div className="closeBox" onClick={() => setShowRubricDialog(false)}>
            <img src={closeIcon} alt="close-icon" />
          </div>
          <div className="title">
            {isEdit ? "Edit Rubric" : "Add New Rubric"}
          </div>
          <div className="sectionName">
            <input
              type="text"
              value={sectionName}
              placeholder="Title..."
              onChange={(e) => setSectionName(e.target.value)}
              maxLength={255}
              required
            ></input>
            <div className="add-button">
              <button onClick={handleAddButton}>
                <img src={addIcon} alt="add-icon"></img>
              </button>
            </div>
          </div>
          <div className="questions-container">
            {questions.map((question, index) => (
              <div key={question.id} className="question">
                <textarea
                  type="text"
                  value={question.text}
                  placeholder="Please type subheading here..."
                  onChange={(e) =>
                    handleQuestionChange(question.id, e.target.value)
                  }
                  required
                />
                {index !== 0 && (
                  <div className="delete">
                    <button onClick={() => handleDeleteButton(question.id)}>
                      <img src={deleteIcon} alt="delete-icon" />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="footer-buttons">
            <button
              className="save"
              disabled={isSaveButtonDisabled}
              style={{
                cursor: isSaveButtonDisabled ? "not-allowed" : "pointer",
              }}
              onClick={handleSubmit}
              title={
                isSaveButtonDisabled
                  ? "Please enter both subject name and question texts"
                  : ""
              }
            >
              {isEdit ? "Update" : "Save"}
            </button>
            <button className="reset" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
      </Popup>
      {showDialog && (
        <CommonDialog
          data={dialogData}
          buttonsList={buttonsList}
        ></CommonDialog>
      )}
    </>
  );
};

export default AddRubric;
