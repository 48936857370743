import {
  CANDIDATE_ROUND2_REPORT1,
  CANDIDATE_ROUND2_REPORT2,
  CANDIDATE_ROUND2_REPORT3,
  CANDIDATE_ROUND3_REPORT1,
  CANDIDATE_ROUND5_REPORT1,
  GET_ROUND1_REPORT,
  HANDLE_AVAILIBILITY_UPDATE,
  PERFORMANCE_SUMMARY_ROUND1,
} from "./URLS";
import { toast } from "react-toastify";
import axiosInstance from "./axiosInstance";

export const performanceSumaryRound1 = async (data) => {
  try {
    const endPoint = PERFORMANCE_SUMMARY_ROUND1;
    const Config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance
      .post(endPoint, data, Config)
      .then((res) => res?.data);
  } catch (err) {
    console.log("error in getDevReport==>", err);
    toast.error(err?.response?.data?.exception);
  }
};

export const allReportRound1 = async (data) => {
  try {
    const endPoint = GET_ROUND1_REPORT;
    const Config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance
      .post(endPoint, data, Config)
      .then((res) => res?.data);
  } catch (err) {
    console.log("error in getDevReport==>", err);
    toast.error(err.response.data.exception);
  }
};

export const performanceSumaryRound2 = async (data) => {
  try {
    const endPoint = CANDIDATE_ROUND2_REPORT1;
    const Config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance
      .post(endPoint, data, Config)
      .then((res) => res?.data);
  } catch (err) {
    console.log("error in getDevReport 1==>", err);
    toast.error(err?.response?.data?.exception);
  }
};

export const performanceSumaryRound21 = async (data) => {
  try {
    const endPoint = CANDIDATE_ROUND2_REPORT2;
    const Config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance
      .post(endPoint, data, Config)
      .then((res) => res?.data);
  } catch (err) {
    console.log("error in getDevReport 2==>", err);
    toast.error(err?.response?.data?.exception);
  }
};

export const performanceSumaryRound22 = async (data) => {
  try {
    const endPoint = CANDIDATE_ROUND2_REPORT3;
    const Config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance
      .post(endPoint, data, Config)
      .then((res) => res?.data);
  } catch (err) {
    console.log("error in getDevReport 3==>", err);
    toast.error(err?.response?.data?.exception);
  }
};

export const updateCandidateAvailability = async (data) => {
  try {
    const URL = HANDLE_AVAILIBILITY_UPDATE;
    const Config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    //   console.log(data);
    return await axiosInstance.post(URL, data, Config).then((res) => res.data);
  } catch (err) {
    toast.error(err.response.data.exception);
    console.log("error in updateCandidateAvailability==>", err);
    throw err;
  }
};

export const round3Details = async (data) => {
  try {
    const endPoint = CANDIDATE_ROUND3_REPORT1;
    const Config = {
      //   headers: {
      //     Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      //   },
    };
    return await axiosInstance
      .post(endPoint, data, Config)
      .then((res) => res?.data);
  } catch (err) {
    console.log("error in round3Details==>", err);
    toast.error(err?.response?.data?.exception);
  }
};

export const round5Details = async (data) => {
  try {
    const endPoint = CANDIDATE_ROUND5_REPORT1;
    const Config = {
      //   headers: {
      //     Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      //   },
    };
    return await axiosInstance
      .post(endPoint, data, Config)
      .then((res) => res?.data);
  } catch (err) {
    console.log("error in round3Details==>", err);
    toast.error(err?.response?.data?.exception);
  }
};
