import React, { useState, useEffect, useRef } from "react";
import { calculateTotalExperience } from "../../../../utils/dateUtils";
import CircleIcon from "@mui/icons-material/Circle";
import circleWithGreenDot from "../../../../assets/svgs/circle-with-green-dot.svg";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import UpdateButton from "../UpdateButton";
import { IconButton, Button,FormControl,FormHelperText ,Box, Typography} from "@mui/material";
import EducationAdd from "./EducationAdd";
import DeleteIcon from "../../../../assets/svgs/delete.svg"; // Import custom delete SVG

// Helper function to format date to "MM/YYYY"
const formatToMonthYear = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
  const year = date.getFullYear();
  return `${month}/${year}`;
};

// Helper function to convert "MM/YYYY" to "YYYY-MM" for input
const formatToInputValue = (dateString) => {
  if (!dateString) return "";
  const [month, year] = dateString.split("/");
  return `${year}-${month}`;
};

const EducationEdit = ({ workData, handleClickUpdate }) => {
  const [workExperiences, setWorkExperiences] = useState(workData);
  const [openWorkAdd, setOpenWorkAdd] = useState(false);
  const [errors, setErrors] = useState({});

  const validateFields = () => {
    const fieldErrors = {};
    workExperiences.forEach((work, index) => {
      fieldErrors[index] = {
        degree: !work.degree,
        institution: !work.institution,
        startDate: !work.startDate,
        endDate: !work.endDate,
        cgpa: !work.cgpa && !work.percentage,
      };
    });
    setErrors(fieldErrors);
    return !Object.values(fieldErrors).some((error) => Object.values(error).includes(true));
  };

  // Function to update fields based on index and field name
  const handleFieldChange = (index, field, value) => {
    const updatedExperiences = workExperiences.map((work, idx) => {
        if (idx === index) {
            // Update the specified field
            const updatedWork = { ...work, [field]: value };
            
            // If the field is "cgpa", also update "percentage" with the same value
            if (field === "cgpa") {
                updatedWork.percentage = value;
            }
            
            return updatedWork;
        }
        return work;
    });

    setWorkExperiences(updatedExperiences);
};


  // Function to delete an education entry
  const handleDeleteEducation = (index) => {
    const updatedExperiences = workExperiences.filter((_, idx) => idx !== index);
    setWorkExperiences(updatedExperiences);
  };

  const handleClickUpdateButton = () => {
    if (validateFields()) {
      handleClickUpdate("Education", workExperiences);
    }
  };

  const handleAddWorkSubmit = (newWork) => {
    if (Object.keys(newWork).length > 0) {
      setWorkExperiences([...workExperiences, newWork]);
    }
    setOpenWorkAdd(false);
  };

  const handleCloseWorkAdd = () => {
    setOpenWorkAdd(false);
  };

  return (
    <div className="work-experience-items">
      <EducationAdd open={openWorkAdd} onClose={handleCloseWorkAdd} handleSumbit={handleAddWorkSubmit} />
      <Button
        variant="outlined"
        size="small"
        onClick={() => setOpenWorkAdd(true)}
        style={{
          color: "#062B6E",
          fontFamily: "Poppins",
          fontSize: "13px",
          fontWeight: 500,
          letterSpacing: "0.2px",
          alignSelf: "end",
        }}
      >
        Add Education
      </Button>
      {workExperiences.map((work, index) => (
        <WorkExperienceItemEdit
          key={index}
          work={work}
          index={index}
          onFieldChange={handleFieldChange}
          onDelete={() => handleDeleteEducation(index)} // Pass delete function
          errors={errors[index] || {}} // Pass error object for each item
        />
      ))}
      <UpdateButton name="summary" handleClickUpdateButton={handleClickUpdateButton} />
    </div>
  );
};

const WorkExperienceItemEdit = ({ work, index, onFieldChange, onDelete,errors }) => {
  const handleStartDateChange = (value) => {
    if (!value) {
      onFieldChange(index, "startDate", "");
      return;
    }
    const formattedDate = formatToMonthYear(value);
    onFieldChange(index, "startDate", formattedDate);
  };

  const handleEndDateChange = (value) => {
    if (!value) {
      onFieldChange(index, "endDate", "");
      return;
    }
    const formattedDate = formatToMonthYear(value);
    onFieldChange(index, "endDate", formattedDate);
  };

  return (
    <>
    <div className="work-experience-item" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div className="item-container" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div className="designation-main" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div className="designation" style={{ display: "flex", alignItems: "center", gap: "12px" }}>
  <div className="circle-with-green-dot">
    <img src={circleWithGreenDot} alt="circle-with-green-dot" />
  </div>

  {/* Degree TextField */}
  <TextField
    value={work.degree || ""}
    onChange={(e) => onFieldChange(index, "degree", e.target.value)}
    variant="outlined"
    size="small"
    style={{ width: "220px", marginRight: "12px" }}
    error={errors.degree}
    // helperText={errors.degree ? "degree is required" : ""}
    InputLabelProps={{
      shrink: true,
      style: {
        color: "#212121",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 600,
        letterSpacing: "0.2px",
      },
    }}
  />

  <Stack direction="row" spacing={2} alignItems="center" gap="12px" marginLeft={2}>
    {/* Start Date Field with reserved space for error */}
    <Box sx={{ display: "flex", flexDirection: "column", width: "125px" }}>
      <TextField
        required
        label="Start Date"
        type="month"
        value={formatToInputValue(work.startDate)}
        onChange={(e) => handleStartDateChange(e.target.value)}
        InputLabelProps={{ shrink: true }}
        size="small"
        error={!!errors.startDate} // Show red outline if there's an error
      />
      
    </Box>

    <span>-</span>

    {/* End Date Field with reserved space for error */}
    <Box sx={{ display: "flex", flexDirection: "column", width: "125px" }}>
      <TextField
        required
        label="End Date"
        type="month"
        value={formatToInputValue(work.endDate)}
        onChange={(e) => handleEndDateChange(e.target.value)}
        InputLabelProps={{ shrink: true }}
        size="small"
        error={!!errors.endDate} // Show red outline if there's an error
      />
     
    </Box>
  </Stack>

  <IconButton onClick={onDelete} aria-label="delete" sx={{ padding: 0, marginLeft: "auto" }}>
    <img src={DeleteIcon} alt="delete" style={{ width: "25px", height: "25px" }} />
  </IconButton>
</div>

          <TextField
            value={work.institution || ""}
            onChange={(e) => onFieldChange(index, "institution", e.target.value)}
            variant="outlined"
            size="small"
            style={{ width: "200px", textAlign: "center" }}
            error={!!errors.institution}
            helperText={errors.institution ? "Institution is required" : ""}
          />
          <Box
            style={{
                display: "flex",
                alignItems: "center",
                gap: "8px", // Adds spacing between Typography and TextField
            }}
        >
            <Typography>Location:</Typography>
            <TextField
                value={work.location || ""}
                onChange={(e) => onFieldChange(index, "location", e.target.value)}
                variant="outlined"
                size="small"
                style={{ width: "200px", textAlign: "center" }}
                error={!!errors.institution}
                helperText={errors.institution ? "Institution is required" : ""}
            />
        </Box>
        <Box
            style={{
                display: "flex",
                alignItems: "center",
                gap: "8px", // Adds spacing between Typography and TextField
            }}
        >
           <Typography>CGPA :</Typography>
           <TextField
              value={work.cgpa || work.percentage}
              onChange={(e) => {onFieldChange(index, "cgpa", e.target.value);}}
              variant="outlined"
              size="small"
              style={{ width: "220px", marginRight: "12px" }}
              error={errors.cgpa}
           // helperText={errors.cgpa ? "CGPA or Percentage is required" : ""}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: "#212121",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: 600,
                  letterSpacing: "0.2px",
                },
              }}
            />
            </Box>
        </div>
      </div>
    </div>
   
    </>
  );
};

export default EducationEdit;
