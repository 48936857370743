import { Link, useNavigate } from "react-router-dom";
import "./UsersView.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useEffect, useState } from "react";
import { CustomDropDown } from "../common/CustomDropDown/CustomDropDown";
import { getAllTimeZones } from "../../APIs/getAllTimeZone";
import blueArrow from "../../Assessts/SVGs/blue-arrow.svg";
import { getCities, getCountries, getStates } from "../../APIs/getLocation";
import plusSign from "../../Assessts/SVGs/plus-sign.svg";
import { toast } from "react-toastify";
import {
  createNewUser,
  updateUserDetails,
} from "../../APIs/getAllUsersDetails";
import Popup from "../common/popUp/popUp";
import userIcon from "../../Assessts/SVGs/user-success.svg";
import crossIcon from "../../Assessts/SVGs/cross-icon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment-timezone";

export const UsersView = () => {
  const [isEditing, setIsEditing] = useState(
    sessionStorage.getItem("edit_user") || false
  );

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("edit_user");
      sessionStorage.removeItem("edit_user_details");
    };
  }, []);
  return (
    <div className="user-view">
      <GoToAllUsers />
      <TopText isEditing={isEditing} />
      <UserData isEditing={isEditing} />
    </div>
  );
};

const GoToAllUsers = () => {
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate(-1);
  };
  return (
    <div className="back-arrow">
      <div className="goto" onClick={handleBackButton}>
        <span className="arrow-main">
          <ArrowBackIosIcon />
        </span>
        <span>Users</span>
      </div>
    </div>
  );
};

const TopText = ({ isEditing }) => {
  return (
    <div className="add-text">{isEditing ? "Edit User" : "Add New User"}</div>
  );
};

const tempUserData = {
  firstName: "",
  lastName: "",
  userRole: "",
  email: "",
  contactNumber: "",
  timeZone: "",
  countryCode: "+91",
  userLocations: [
    {
      country: "",
      state: "",
      location: "",
    },
  ],
};

const UserData = ({ isEditing }) => {
  const [userDetails, setUserDetails] = useState({ ...tempUserData });
  const [viewSuccess, setViewSuccess] = useState(false);

  const handleChange = (name, value) => {
    // If the field is a phone number
    if (name === "contactNumber") {
      // Remove any non-numeric characters from the input value
      value = value.replace(/\D/g, "");

      // Limit the input to 10 digits
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
    }

    setUserDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (checkData(userDetails)) {
        // console.log("success");
        const data = { ...userDetails };
        const timeZoneString = data?.timeZone;
        const [timeZone, utcTimeDifference] = timeZoneString?.split(" ");

        data["timeZone"] = {
          timeZone: timeZone?.trim(), // "Asia/Kolkata"
          utcOffset: `${utcTimeDifference?.trim()}`, // "GMT+5:30"
        };
        let res;
        if (isEditing) {
          res = await updateUserDetails(data);
        } else {
          res = await createNewUser(data);
        }
        if (res) {
          // toast.success("User Added Successfully");
          setUserDetails({ ...tempUserData });
          setViewSuccess(true);
        }
      }
    } catch (err) {
      console.log("error in handleSubmit==>", err.data);
    }
  };

  const preFillUserData = () => {
    const data = JSON.parse(sessionStorage.getItem("edit_user_details"));
    if (data?.timeZone) {
      data.timeZone = `${data?.timeZone?.timeZone} ${data?.timeZone?.utcOffset}`;
    }
    setUserDetails({ ...data });
  };

  useEffect(() => {
    if (isEditing) {
      preFillUserData();
    }
  }, []);

  return (
    <>
      <div className="user-data-container">
        <UserRole
          userDetails={userDetails}
          handleChange={handleChange}
          isEditing={isEditing}
        />
        <UserName userDetails={userDetails} handleChange={handleChange} />
        <UserContact
          userDetails={userDetails}
          handleChange={handleChange}
          isEditing={isEditing}
        />
        <UserLocations
          userDetails={userDetails}
          setUserDetails={setUserDetails}
        />
        <div className="submit-button">
          <button onClick={() => handleSubmit()}>Submit</button>
        </div>
      </div>
      {viewSuccess && <SucessMessage isEditing={isEditing} />}
    </>
  );
};

const UserRole = ({ userDetails, handleChange, isEditing }) => {
  const [userRoleOptions, setUserRoleOptions] = useState([
    "CLSM",
    "CSM",
    "HR",
    "Expert",
    // "CANDIDATES",
  ]);

  return (
    <div className="userRole">
      <CustomDropDown
        options={userRoleOptions}
        label={"User Role"}
        handleChange={(val) => handleChange("userRole", val)}
        value={userDetails.userRole}
        placeholder={"Role..."}
        isDisabled={isEditing}
      />
    </div>
  );
};

const UserName = ({ userDetails, handleChange }) => {
  return (
    <div className="user-name">
      <div className="input-container">
        <div className="label">First Name</div>
        <div className="input-content">
          <input
            type="text"
            value={userDetails?.firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
            placeholder="First Name"
          />
        </div>
      </div>
      <div className="input-container">
        <div className="label">Last Name</div>
        <div className="input-content">
          <input
            type="text"
            value={userDetails?.lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
            placeholder="Last Name"
          />
        </div>
      </div>
    </div>
  );
};

const UserContact = ({ userDetails, handleChange, isEditing }) => {
  const [timeZoneList, setTimeZoneList] = useState([1, 23, 4]);
  const [countryCodeList, setCountryCodeList] = useState(["+91", "+1"]);

  const getFormattedTimeZones = () => {
    // Get all time zones from moment-timezone
    const timeZones = moment.tz.names();

    // Create a set to store unique time zone strings
    const uniqueTimeZones = new Set();

    timeZones.forEach((zone) => {
      // Get the current time in the timezone
      const format = moment.tz(zone).format("Z"); // e.g., "+05:30"

      // Format the string as `Asia/Kolkata GMT+5:30`
      const formattedTimeZone = `${zone} GMT${format}`;

      // Add to the set
      uniqueTimeZones.add(formattedTimeZone);
    });

    // Convert the Set to an array and return
    return [...uniqueTimeZones];
  };

  const getAllTimeZoneList = async () => {
    try {
      // const res = await getAllTimeZones();
      // if (res) {
      //   const temp = new Set(
      //     res.timeZones.map((val) => `${val?.timeZone} ${val?.utcFormat}`)
      //   );
      //   // console.log(temp);
      //   setTimeZoneList([...temp]);
      // }

      const temp = getFormattedTimeZones();
      setTimeZoneList([...temp]);
    } catch (err) {
      console.log("error in getAllTimeZoneList=>", err);
    }
  };

  useEffect(() => {
    // console.log("inside useeffect of getallTimeZoneList");

    getAllTimeZoneList();
  }, []);

  return (
    <div className="user-contact">
      <div className="input-container">
        <div className="label">Email ID</div>
        <div className="input-content">
          <input
            type="email"
            value={userDetails?.email}
            onChange={(e) => handleChange("email", e.target.value)}
            placeholder="Email ID"
            disabled={isEditing}
            style={{ cursor: isEditing ? "not-allowed" : "" }}
          />
        </div>
      </div>
      <div className="contact-number">
        <CustomDropDown
          options={countryCodeList}
          label={""}
          handleChange={(val) => handleChange("countryCode", val)}
          value={userDetails.countryCode}
          placeholder={""}
        />
        <div className="input-container">
          <div className="label">Contact Number</div>
          <div className="input-content">
            <input
              type="text"
              value={userDetails?.contactNumber}
              onChange={(e) => handleChange("contactNumber", e.target.value)}
              placeholder="XXXXXXXXXX"
            />
          </div>
        </div>
      </div>
      <div className="time-zone">
        <CustomDropDown
          options={timeZoneList}
          label={"Time Zone"}
          handleChange={(val) => handleChange("timeZone", val)}
          value={userDetails.timeZone}
          placeholder={"Time Zone..."}
        />
      </div>
    </div>
  );
};

const UserLocations = ({ userDetails, setUserDetails }) => {
  const [coutryList, setCountryList] = useState([]);

  const getAllCountryList = async () => {
    try {
      const res = await getCountries();
      if (res) {
        setCountryList([...res]);
      }
    } catch (err) {
      console.log("error in getAllCountryList=>", err);
    }
  };

  const AddMoreLocations = () => {
    setUserDetails((prev) => ({
      ...prev,
      userLocations: [
        ...prev.userLocations,
        {
          country: "",
          state: "",
          location: "",
        },
      ],
    }));
  };

  const handleDeleteRow = (index) => {
    setUserDetails((prev) => ({
      ...prev,
      userLocations: prev.userLocations.filter((_, i) => i !== index),
    }));
  };
  useState(() => {
    getAllCountryList();
  }, []);

  return (
    <div className="location-main">
      <div className="title">
        <div>Location Serviced</div>
        <div>
          <img src={blueArrow} alt="" />
        </div>
      </div>
      <div className="location-data">
        <div className="titles">
          <div>Country</div>
          <div>State</div>
          <div>City</div>
          <div></div>
        </div>

        {userDetails?.userLocations?.map((location, index) => (
          <div className="titles" key={index}>
            <UserCountries
              coutryList={coutryList}
              userLocationData={location}
              position={index}
              setUserDetails={setUserDetails}
            />
            <div className="delete-icon" onClick={() => handleDeleteRow(index)}>
              <DeleteIcon />
            </div>
          </div>
        ))}

        <div className="add-more" onClick={() => AddMoreLocations()}>
          <div>
            <img src={plusSign} alt="" />
          </div>
          <div>Add More</div>
        </div>
      </div>
    </div>
  );
};

const UserCountries = ({
  coutryList,
  userLocationData,
  position,
  setUserDetails,
}) => {
  const handleCountryChange = (position, val) => {
    setUserDetails((prev) => {
      const updatedUserLocation = [...prev.userLocations]; // Create a copy of userLocation array
      updatedUserLocation[position] = {
        ...updatedUserLocation[position],
        country: val,
        state: "",
        location: "",
      }; // Update the country property
      return {
        ...prev,
        userLocations: updatedUserLocation, // Update the userLocation array in state
      };
    });
  };

  return (
    <>
      <CustomDropDown
        options={coutryList}
        label={""}
        handleChange={(val) => handleCountryChange(position, val)}
        value={userLocationData.country}
        placeholder={"Select..."}
      />
      <UserStates
        userLocationData={userLocationData}
        position={position}
        setUserDetails={setUserDetails}
      />
    </>
  );
};

const UserStates = ({ userLocationData, position, setUserDetails }) => {
  const [stateList, setStateList] = useState([]);

  const handleStateChange = (position, val) => {
    setUserDetails((prev) => {
      const updatedUserLocation = [...prev.userLocations]; // Create a copy of userLocation array
      updatedUserLocation[position] = {
        ...updatedUserLocation[position],
        state: val,
        location: "",
      }; // Update the country property
      return {
        ...prev,
        userLocations: updatedUserLocation, // Update the userLocation array in state
      };
    });
  };

  const getStateList = async () => {
    try {
      const data = {
        countryName: userLocationData?.country,
      };

      const res = await getStates(data);
      if (res) {
        setStateList([...res]);
      } else {
        setStateList([]);
      }
    } catch (err) {
      setStateList([]);
      console.log("error in getStateList=>", err);
    }
  };

  useEffect(() => {
    // console.log("inside useeffect of getStateList");
    if (userLocationData.country) {
      getStateList();
    }
  }, [userLocationData.country]);
  return (
    <>
      <CustomDropDown
        options={stateList}
        label={""}
        handleChange={(val) => handleStateChange(position, val)}
        value={userLocationData.state}
        placeholder={"Select..."}
      />
      <UserCities
        userLocationData={userLocationData}
        position={position}
        setUserDetails={setUserDetails}
      />
    </>
  );
};

const UserCities = ({ userLocationData, position, setUserDetails }) => {
  const [cityList, setCityLis] = useState([]);

  const handleCityChange = (position, val) => {
    setUserDetails((prev) => {
      const updatedUserLocation = [...prev.userLocations]; // Create a copy of userLocation array
      updatedUserLocation[position] = {
        ...updatedUserLocation[position],
        location: val,
      }; // Update the country property
      return {
        ...prev,
        userLocations: updatedUserLocation, // Update the userLocation array in state
      };
    });
  };

  const getCityList = async () => {
    try {
      const data = {
        countryName: userLocationData?.country,
        stateName: userLocationData?.state,
      };

      const res = await getCities(data);
      if (res) {
        setCityLis([...res]);
      } else {
        setCityLis([]);
      }
    } catch (err) {
      setCityLis([]);
      console.log("error in getStateList=>", err);
    }
  };

  useEffect(() => {
    // console.log("inside useeffect of getcityList");

    if (userLocationData.state) {
      getCityList();
    }
  }, [userLocationData.state]);
  return (
    <CustomDropDown
      options={cityList}
      label={""}
      handleChange={(val) => handleCityChange(position, val)}
      value={userLocationData.location}
      placeholder={"Select..."}
    />
  );
};

const SucessMessage = ({ isEditing }) => {
  const navigate = useNavigate();
  return (
    <Popup>
      <div className="success-message">
        {/* <div className="cross">
          <img src={crossIcon} alt="" />
        </div> */}
        <div className="user">
          <img src={userIcon} alt="" />
        </div>
        <div className="desc">
          {isEditing
            ? "User Updated Succesfully"
            : " A new user has been created and an invitation has been sent."}
        </div>
        <div className="okay">
           <button onClick={() => navigate("/view-users?page=1")}>Okay</button>
        </div>
      </div>
    </Popup>
  );
};

const checkData = (userData) => {
  const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
  if (!userData?.userRole) {
    toast.error("Plase select user role!");
    return false;
  } else if (!userData?.email) {
    toast.error("Plase enter email id!");
    return false;
  }
  if (!emailRegex.test(userData.email)) {
    toast.error("Plase enter valid email id!");
    return false;
  } else if (userData?.contactNumber && userData?.contactNumber.length < 10) {
    toast.error("Plase enter valid contact number!");
    return false;
  }
  return true;
};
