import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ErrorIcon from "@mui/icons-material/Error";
import { Button } from "@mui/material";
import { SuccessPassword } from "../../Components/SuccessUpdate/SuccessUpDate";
import Popup from "../../Components/common/popUp/popUp";
import styles from "./ResetPAssword.module.css";
import { SET_NEW_PASSWORD } from "../../APIs/URLS";
import axios from "axios";

const ResetPasswordPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get("username");

  const [showSuccess, setShowSuccess] = useState(false);
  const [apiError, setApiError] = useState(null); // Add this line to your existing useState declarations
  const [redirectUrl, setRedirectUrl] = useState("");

  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });
  const [errors, setErrors] = useState({
    samePassword: false,
    passwordStrengthError: false,
  });
  const [passwordCriteria, setPasswordCriteria] = useState({
    hasNumber: false,
    hasUppercase: false,
    hasSpecialChar: false,
    hasLetter: false,
    hasLowercase: false,
    isMinLength: false,
  });

  const handlePasswordChange = (prop) => (event) => {
    const { value } = event.target;
    setPasswords({ ...passwords, [prop]: value });
    updatePasswordCriteria(value);
    if (apiError) {
      setApiError();
    }
  };

  const updatePasswordCriteria = (password) => {
    setPasswordCriteria({
      hasNumber: /\d/.test(password),
      hasUppercase: /[A-Z]/.test(password),
      hasSpecialChar: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password),
      hasLetter: /[A-Za-z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      isMinLength: password.length >= 8,
    });
  };

  const handleClickShowPassword = (prop) => {
    setPasswords({ ...passwords, [prop]: !passwords[prop] });
  };

  const handleConfirmPassword = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = passwords;
    let newErrors = {
      samePassword: password !== confirmPassword,
      passwordStrengthError: Object.values(passwordCriteria).includes(false),
    };

    setErrors(newErrors);

    if (!newErrors.samePassword && !newErrors.passwordStrengthError) {
      // Assume API call here to reset password
      const data = {
        email: username,
        password: password,
        //    "role":"HR"
      };
      const endpoint = SET_NEW_PASSWORD;
      const config = {
        // headers: {
        //   "Content-Type": "application/json",
        // },
      };
      try {
        const res = await axios.post(endpoint, data, config);
        if (res?.data) {
          setShowSuccess(true);
          setPasswords({
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,
          });
          setRedirectUrl(res?.data?.redirectUrl);
        }
      } catch (err) {
        // console.error("error in handleConfirmPassword==>", err);
        console.log(err);
        if (err?.response?.data?.exception)
          setApiError(err?.response?.data?.exception);
        else setApiError("Error in saving password");
        setRedirectUrl("");
      }
    }
  };

  const getIndicatorStyle = (condition) => ({
    color: condition ? "green" : "grey",
  });

  if (!username) {
    return <div>No username provided for password reset.</div>;
  }

  return (
    <>
      <div className={`${styles.rightSideMain} ${styles.childDiv}`}>
        <div className={styles.rightItem}>
          <div className={styles.title}>Reset Password</div>
          <form className={styles.formWrapper} onSubmit={handleConfirmPassword}>
            <div className={styles.passwordWrapper}>
              <TextField
                type={passwords.showPassword ? "text" : "password"}
                label="New Password"
                placeholder="New Password"
                fullWidth
                required
                value={passwords.password}
                onChange={handlePasswordChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("showPassword")}
                      >
                        {passwords.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ marginTop: 10 }}>
                <span style={getIndicatorStyle(passwordCriteria.hasNumber)}>
                  Contains a number ,
                </span>
                <span
                  style={{
                    ...getIndicatorStyle(passwordCriteria.hasUppercase),
                    marginLeft: 10,
                  }}
                >
                  Contains an uppercase letter ,
                </span>
                <span
                  style={{
                    ...getIndicatorStyle(passwordCriteria.hasSpecialChar),
                    marginLeft: 10,
                  }}
                >
                  Contains a special character ,
                </span>
                <span
                  style={{
                    ...getIndicatorStyle(passwordCriteria.hasLetter),
                    marginLeft: 10,
                  }}
                >
                  Contains a letter ,
                </span>
                <span
                  style={{
                    ...getIndicatorStyle(passwordCriteria.hasLowercase),
                    marginLeft: 10,
                  }}
                >
                  Contains a lowercase letter ,
                </span>
                <span
                  style={{
                    ...getIndicatorStyle(passwordCriteria.isMinLength),
                    marginLeft: 10,
                  }}
                >
                  Minimum 8 characters ,
                </span>
              </div>
            </div>
            <div>
              <TextField
                type={passwords.showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                placeholder="Confirm Password"
                fullWidth
                required
                value={passwords.confirmPassword}
                onChange={handlePasswordChange("confirmPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={() =>
                          handleClickShowPassword("showConfirmPassword")
                        }
                      >
                        {passwords.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {errors.samePassword && (
                <div
                  className={styles.otpError}
                  style={{ color: "red", marginTop: "10px" }}
                >
                  <ErrorIcon /> Password and Confirm Password should be the same
                </div>
              )}
              {errors.passwordStrengthError && (
                <div
                  className={styles.otpError}
                  style={{ color: "red", marginTop: "10px" }}
                >
                  <ErrorIcon /> Password does not meet criteria
                </div>
              )}
              {apiError && (
                <div
                  className={styles.otpError}
                  style={{ color: "red", marginTop: "10px" }}
                >
                  <ErrorIcon /> {apiError}
                </div>
              )}
            </div>
            <div style={{ width: "100%", marginTop: "20px" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Confirm
              </Button>
            </div>
          </form>
        </div>
      </div>

      {showSuccess && (
        <Popup>
          {" "}
          <SuccessPassword
            setShowSuccess={setShowSuccess}
            redirectUrl={redirectUrl}
          />
        </Popup>
      )}
    </>
  );
};

export default ResetPasswordPage;
