import { useEffect, useState } from "react";
import { getExpertList, getHRList } from "../APIs/interview";

const useFetchExpertList = (
  interviewScheduledDate,
  location,
  page,
  searchQuery,
  user
) => {
  const [expertList, setExpertList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchExpertList = async () => {
      const data = { interviewScheduledDate: "2024-07-03 03:30 PM", location };
      const res =
        user === "HR"
          ? await getHRList(data, page)
          : await getExpertList(data, page);
      if (Array.isArray(res?.content)) {
        let filteredList = res?.content;
        if (searchQuery.length > 2) {
          filteredList = res?.content.filter((hr) =>
            hr?.emailId?.toLowerCase().includes(searchQuery?.toLowerCase())
          );
        }
        setExpertList([...filteredList]);
        setTotalPages(res?.totalPages);
      }
    };
    fetchExpertList();
  }, [interviewScheduledDate, location, page, searchQuery, user]);

  return { expertList, totalPages };
};

export default useFetchExpertList;
