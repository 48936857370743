import axios from "axios";
import { ALL_USER_DETAILS, CREATE_USER, DELETE_USER, FILTERS_USERS_LIST, SEARCH_USER_BY_ID, UPDATE_USER_DATA } from "./URLS"
import { toast } from "react-toastify";
import axiosInstance from "./axiosInstance";

export const getAllUsersDetails = async (data, page) => {
    try {
        const URL = ALL_USER_DETAILS + `?pageNumber=${page}`;
        const Config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            }
        };
        return await axiosInstance.post(URL, data, Config).then(res => res?.data)
    } catch (err) {
        console.log("error in getAllUsersDetails==>", err)
        toast.error(err.response.data.exception)
    }
}


export const createNewUser = async (data) => {
    try {
        const URL = CREATE_USER;
        const Config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            }
        };
        return await axiosInstance.post(URL, data, Config).then(res => res?.data)
    } catch (err) {
        console.log("error in createNewUser==>", err)
        toast.error(err.response.data.exception)
    }
}


export const deleteTheUser = async (data) => {
    try {
        const endPoint = DELETE_USER;
        const config = {
            data: data, // Pass data within the config object
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            }
        };
        console.log("delete the user==>", data);
        return await axiosInstance.delete(endPoint, config).then(res => res.data); // Return the response data
    } catch (err) {
        console.log("error in deleteUser==>", err);
        if (err.response) {
            toast.error(err.response.data.exception); // Check if err.response exists before accessing err.response.data
        } else {
            toast.error('An error occurred while deleting the user.'); // Provide a generic error message if err.response is not available
        }
        throw err; // Re-throw the error if needed
    }
};



export const updateUserDetails = async (data) => {
    try {
        const URL = UPDATE_USER_DATA;
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            }
        };
        return await axiosInstance.put(URL, data, config).then(res => res.data);
    } catch (error) {
        console.error("Error in updateUserDetails:", error);
        if (error.response) {
            toast.error(error.response.data.exception);
        } else {
            toast.error('An error occurred while updating user details.');
        }
        throw error;
    }
};

export const searchUserById = async (data) => {
    try {
        const URL = SEARCH_USER_BY_ID;
        const config = {
            // headers: {
            //     Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            // }
        };
        return await axiosInstance.post(URL, data, config).then(res => res.data);
    } catch (error) {
        console.error("Error in searchUserById:", error);
        if (error.response) {
            toast.error(error.response.data.exception);
        } else {
            toast.error('An error occurred while searching user details.');
        }
        throw error;
    }
}

export const filterUsersList = async (data) => {
    try {
        const URL = FILTERS_USERS_LIST;
        const config = {
            // headers: {
            //     Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            // }
        };


        const formattedData = {
            ...data,
            adminEmail: sessionStorage.getItem('email'),
            creationDate: formatDate(data.creationDate)
        };

        return await axiosInstance.post(URL, formattedData, config).then(res => res.data);
    } catch (error) {
        console.error("Error in filterUsersList:", error);
        if (error.response) {
            toast.error(error.response.data.exception);
        } else {
            toast.error('An error occurred while searching user details.');
        }
        throw error;

    }
}




const formatDate = (dateString) => {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-In', options);
};