import axios from "axios";

export const createNewQuestion = async (data, url) => {
    try {
        const URL = url;
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        return await axios.post(URL, data, config).then(res => res?.data)
    } catch (err) {
        if(err?.response?.status === 400){
            return {error : 'File issue'}
        }
        if(err?.response?.status === 401){
            return {error : 'Server issue'}
        }
        return null;
    }
}