import flagSuccess from "../Assessts/SVGs/flag-approved.svg"
import flagRejected from "../Assessts/SVGs/flag-rejected.svg"
import uploadError from "../Assessts/SVGs/upload-error.svg"

export const flagInfo = {
    approved: {
        message1: "",
        message2: "The candidate’s flag has been approved.",
        icon: flagSuccess
    },
    rejected: {
        message1: "",
        message2: "The candidate’s flag has been removed",
        icon: flagRejected
    },
    error: {
        message1: "",
        message2: "There is some issue in the server. Please Try again after sometime!",
        icon: uploadError
    }
};