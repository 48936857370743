import React, { useState } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import styles from "../VettedSkilsEdit/VettedSkillsEdit.module.scss";
import UpdateButton from '../UpdateButton';
import SoftSkillAdd from './SoftSkillAdd';
import DeleteIcon from "../../../../assets/svgs/delete.svg"; // Import custom delete SVG

const LanguageEdit = ({ vettedSkills, handleClickUpdate }) => {
  const [skillsData, setSkillsData] = useState(vettedSkills);
  const [openVettedAdd, setOpenVettedAdd] = useState(false);

  // Handle input changes for skills and experience
  const handleInputChange = (index, field, value) => {
    const updatedSkills = skillsData.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setSkillsData(updatedSkills);
  };

  // Handle delete skill
  const handleDeleteSkill = (index) => {
    const updatedSkills = skillsData.filter((_, i) => i !== index);
    setSkillsData(updatedSkills);
  };

  // Handle update button click
  const handleClickUpdateButton = () => {
    handleClickUpdate('Soft Skills', skillsData);
  };

  const handleSubmitVettedAdd = (vettedArray) => {
    setSkillsData([...skillsData, ...vettedArray]);
    setOpenVettedAdd(false);
  };

  const handleCloseVettedAdd = () => {
    setOpenVettedAdd(false);
  };

  return (
    <div className={styles.vetted}>
      <SoftSkillAdd open={openVettedAdd} onClose={handleCloseVettedAdd} handleSubmit={handleSubmitVettedAdd}  />
      <TableContainer component={Paper} className={styles['table-container']}>
        <Table>
          <TableHead>
            <TableRow className={styles['table-header-row']}>
              <TableCell sx={{ padding: '0px' }} className={styles['table-header']}>
                Soft Skill
              </TableCell>
              <TableCell sx={{ padding: '0px' }} className={styles['table-header']}>
                Level
              </TableCell>
              <TableCell sx={{ padding: '5px' }} className={styles['table-header']} align="right">
                <Button
                  onClick={() => setOpenVettedAdd(true)}
                  variant="outlined"
                  sx={{
                    borderRadius: '4px',
                    border: '1px solid #062B6E',
                    color: '#062B6E',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '0.2px',
                    textTransform: 'none',
                  }}
                >
                  Add Soft Skill
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skillsData?.map((skillItem, index) => (
              <TableRow key={index} className={styles['table-row']}>
                <TableCell className={styles['table-cell']} sx={{ padding: '0px', paddingBottom: '10px' }}>
                  <TextField
                    value={skillItem?.softSkillName}
                    onChange={(e) => handleInputChange(index, 'softSkillName', e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px' }}
                  />
                </TableCell>
                <TableCell className={styles['table-cell']} sx={{ padding: '0px', paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
                  <Select
                    value={skillItem?.softSkillLevel || ''}
                    onChange={(e) => handleInputChange(index, 'softSkillLevel', e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px', marginRight: '8px' }} // Add space between Select and IconButton
                  >
                    <MenuItem value="Beginner">Beginner</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Expert">Expert</MenuItem>
                  </Select>
                  <IconButton aria-label="delete" onClick={() => handleDeleteSkill(index)} sx={{ padding: 0 }}>
                    <img src={DeleteIcon} alt="delete" style={{ width: '25px', height: '25px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Update Button */}
      <UpdateButton name="summary" handleClickUpdateButton={handleClickUpdateButton} />
    </div>
  );
};

export default LanguageEdit;
