import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";

export const SuccessPassword = ({ setShowSuccess, redirectUrl }) => {
  // Optional: Add a handler for the button click
  const handleButtonClick = () => {
    window.location.href = redirectUrl;
    setShowSuccess(false);
  };

  return (
    <div
      style={{
        padding: "50px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "fit-content",
        width: "400px",
        borderRadius: "10px",
        backgroundColor: "#f0f4f7",
      }}
    >
      <CheckCircleIcon
        style={{ fontSize: 60, color: green[500], marginBottom: 20 }}
      />
      <Typography variant="h4" component="h1">
        Success!
      </Typography>
      <Typography variant="subtitle1" style={{ color: "#666", marginTop: 10 }}>
        Your password has been successfully updated.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleButtonClick}
        style={{ marginTop: 20 }}
      >
        OK
      </Button>
    </div>
  );
};
