import { AllRoutes } from "./AllRoutes/AllRoutes";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Create a theme instance
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
});

function App() {
  return (
    <div >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AllRoutes />
      </ThemeProvider >
    </div>
  );
}

export default App;
