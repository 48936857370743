import React, { useState } from 'react';
import { IconButton,Button } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import UpdateButton from '../UpdateButton';
import InterestAdd from './InterestsAdd';

const InterestEdit = ({ skills: initialSkills,handleClickUpdate,headerName }) => {
  // State to manage skills
  const [skills, setSkills] = useState(initialSkills);
  const [openInterestAdd,setOpenInterestsAdd]=useState(false);

  // Remove a skill from the list
  const onRemove = (skillToRemove) => {
    const updatedSkills = skills.filter((skill) => skill !== skillToRemove);
    setSkills(updatedSkills);
  };

  // Handle update button click
  const handleClickUpdateButton = () => {
    handleClickUpdate(headerName, skills);
  };
const handleCloseInterestAdd=()=>{
    setOpenInterestsAdd(false)
}
const handleAddNewInterest=(newSkills)=>{
    setSkills([...skills,...newSkills]);
    setOpenInterestsAdd(false);
}
console.log(handleAddNewInterest)
return (
    <>
      {/* Wrapper for aligning the button to the right */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <InterestAdd 
          open={openInterestAdd} 
          onClose={handleCloseInterestAdd} 
          handleSumbit={handleAddNewInterest} 
        />
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpenInterestsAdd(true)}
          sx={{
            borderRadius: '4px',         // Border radius
            border: '1px solid #062B6E', // Border color
            color: '#062B6E',            // Text color
            fontFamily: 'Poppins',       // Font family
            fontSize: '13px',            // Font size
            fontWeight: 500,             // Font weight
            letterSpacing: '0.2px',      // Letter spacing
            textTransform: 'none',  
            marginBottom:'10px'     // Disable uppercase transformation
          }}
        >
          Add Interest
        </Button>
      </div>
  
      <div className="skills">
       
        {skills?.map((skill, index) => (
          <SkillItem 
            key={index} 
            skill={skill} 
            onRemove={onRemove} 
            handleSumbit={handleAddNewInterest} 
          />
        ))}
      </div>
  
      <UpdateButton name="summary" handleClickUpdateButton={handleClickUpdateButton} />
    </>
  );
  
};

const SkillItem = ({ skill, onRemove }) => {
  return (
    <div className="skill-item">
      <span className="skill">{skill}</span>

      {/* Cancel Icon */}
      <IconButton
        className="cancel-icon"
        size="small"
        onClick={() => onRemove(skill)}
        aria-label="remove skill"
      >
        <CancelRoundedIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

export default InterestEdit;
