import { Button } from '@mui/material';
import React, { useState } from 'react';

import VettedAdd from '../VettedSkilsEdit/VettedAdd';
import WorkExpAdd from '../WorkExpEdit/WorkExpAdd';
import SkillAdd from '../SkillAdd/SkillAdd';
import EducationAdd from '../Education/EducationAdd';
import CertificationAdd from "../CertificationEdit/CertificationAdd";
import ReccomandationAdd from '../Reccomandation/ReccomandationAdd';
import SoftSkillAdd from '../SoftSkills/SoftSkillAdd';
import LanguageAdd from '../Language/LanguageAdd';
import InterestAdd from '../Interests/InterestsAdd';
import AchievementsAdd from '../Achievements/AchievementsAdd';

const NoDataScreen = ({ header="", handleClickUpdate,hasAchievements,hasData,handleAddCertAndAchiev }) => {
  const [openForm, setOpenForm] = useState({
    "Vetted Technical Skills": false,
    "Work Experience & Project Highlights": false,
    "Skills & Technologies": false,
    "Education": false,
    "Certification & Achievements": false,
    "Recommendations/Testimonials": false,
    "Soft Skills": false,
    "Languages": false,
    "Interests": false,
  });
  const [openCertificate,setOpenCertificate]=useState(false);
  const [openAchievements,setOpenAchievements]=useState(false)
  // Open form based on the header
  const handleOpenForm = () => {
    setOpenCertificate(true)
  };

  // Close form based on the header
  const handleCloseCertificateAdd = () => {
   setOpenCertificate(false)
   setOpenAchievements(false)
  };

  const handleAddWorkSubmit = (newData) => {
    

const passHeader=openCertificate?"certifications":"achievements";

handleAddCertAndAchiev(passHeader,newData)
    
   // handleClickUpdate(header, newData);
   handleCloseCertificateAdd(); // Close the form after submission
  };

 

  return (
    <>
      {/* Render the correct form based on the header */}
      <CertificationAdd open={openCertificate} onClose={handleCloseCertificateAdd} handleSumbit={handleAddWorkSubmit} />
      <AchievementsAdd open={openAchievements} onClose={handleCloseCertificateAdd}  handleSumbit={handleAddWorkSubmit} />

      { !hasData && <div style={styles.container}>
      
        { !hasData && <Button style={styles.addButton} variant='outlined' onClick={handleOpenForm}>
          Add Certificate
        </Button>}
        <div style={styles.noDataText}>

  No data to display
  
</div>
      </div>}
      { !hasAchievements && <div style={styles.container}>
       { !hasAchievements && <Button style={styles.addButton} variant='outlined' onClick={()=>{setOpenAchievements(true)}}>
          Add Achievement
        </Button>}
       
        <div style={styles.noDataText}>

        No data to display
</div>
      </div>}
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40vh',
    position: 'relative'
  },
  addButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '8px 16px',
    color: '#062B6E',
    border: '1px solid #062B6E',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: 'Poppins',
    textTransform: 'none',
  },
  noDataText: {
    color: '#93B9FF',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
  }
};

export default NoDataScreen;
