import { useEffect, useState } from "react";
import "./UsersList.scss";
import {
  deleteTheUser,
  filterUsersList,
  getAllUsersDetails,
  searchUserById,
} from "../../APIs/getAllUsersDetails";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import Popup from "../../Components/common/popUp/popUp";
// import crossIcon from "../../Assessts/SVGs/cross-icon.svg";
// import { toast } from "react-toastify";
import { TablePages } from "../../Components/common/Pagination_Data/Pagination_Data";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
} from "@mui/material";
import { Alert } from "@mui/material";
import { getCountries } from "../../APIs/getLocation";
import { useUtils } from "../../hooks/useUtils";
const headersList = [
  "Email",
  "User Name",
  "Creation Date",
  "User Role",
  "Location",
  "Status",
];

const tableDummyData = [];

export const UsersList = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [page, setPage] = useState(parseInt(queryParams?.get("page")) || 1);

  const [tableHeaders, setTableHeaders] = useState([...headersList]);
  const [tableData, setTableData] = useState([...tableDummyData]);
  const [name, setName] = useState("");
  const [viewDeleteUser, setViewDeleteUser] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState({});
  const [viewFilters, setViewFilters] = useState(false);
  const [filterError, setFiltersError] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const { debounce } = useUtils();

  const handleClose = () => {
    setViewFilters(false);
  };

  const hasNonEmptyValue = (data) => {
    return Object.values(data).some((value) => value !== "");
  };

  const handleSearchDev = async (data) => {
    setViewFilters(false);
    try {
      if (hasNonEmptyValue(data)) {
        // If any key has a non-empty value, use filterDevList
        const res = await filterUsersList(data);
        setTableData([...res]);
      } else {
        // If no key is present in data, use getAllDevList
        getAllUsers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllUsers = async () => {
    setFiltersError(false);
    try {
      const data = {
        name: sessionStorage.getItem("email"),
      };
      const res = await getAllUsersDetails(data, page - 1);

      if (res) {
        setTableData([...res?.content]);
        setTotalPages(res?.totalPages);
      }
    } catch (err) {
      console.log("error in getAllUsers=>", err);
    }
  };

  const deleteUser = async () => {
    try {
      const data = selectedUserToDelete;
      const res = await deleteTheUser(data);

      if (res) {
        setViewDeleteUser(false);
        getAllUsers();
      }
    } catch (err) {
      // toast.error("Something went wrong!");
      setSelectedUserToDelete({});
      setViewDeleteUser(false);
      console.log("error in deleteUser=>", err);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    navigate(`/view-users?page=${value}`);
    // You can perform any other actions here, such as fetching data for the new page.
  };

  const searchUserByName = debounce(async (value) => {
    try {
      // API call to search user by name
      const data = {
        adminEmail: sessionStorage.getItem("email"),
        searchEmail: value,
      };
      const res = await searchUserById(data);
      if (res) setTableData([...res]);
      else setFiltersError(true);
    } catch (error) {
      console.error("Error searching user by name:", error);
      setFiltersError(true);
    }
  }, 500);

  const handleNameChange = (value) => {
    setName(value);
    setFiltersError(false);
    if (value === "") {
      // If name is empty, fetch all users
      getAllUsers();
    } else {
      // If name is not empty, debounce the search API call
      searchUserByName(value);
    }
  };

  const getAllLocationList = async () => {
    const res = await getCountries();
    setLocationOptions([...res]);
  };

  useEffect(() => {
    getAllUsers();
  }, [page]);

  useEffect(() => {
    getAllLocationList();
  }, []);

  return (
    <>
      <div className="view-client">
        <div className="title">
          <div>Users</div>
          <div>
            <button onClick={() => navigate("/create-user")}>
              <AddIcon /> New User
            </button>
          </div>
        </div>
        <div className="searchAndFilter">
          <SearchClient
            name={name}
            handleNameChange={handleNameChange}
            filterError={filterError}
          />

          <button className="filersButton" onClick={() => setViewFilters(true)}>
            Filters <FilterListIcon />
          </button>
        </div>
        <DisplayTableData
          tableHeaders={tableHeaders}
          tableData={tableData}
          setViewDeleteUser={setViewDeleteUser}
          setSelectedUserToDelete={setSelectedUserToDelete}
        />
      </div>
      <TablePages
        page={page}
        handlePageChange={handlePageChange}
        totalPages={totalPages}
      />
      <FilterDialog
        open={viewFilters}
        handleClose={handleClose}
        handleSearchDev={handleSearchDev}
        locationOptions={locationOptions}
      />

      {viewDeleteUser && (
        <DeleteUser
          setViewDeleteUser={setViewDeleteUser}
          setSelectedUserToDelete={setSelectedUserToDelete}
          deleteUser={deleteUser}
        />
      )}
    </>
  );
};

const SearchClient = ({ name, handleNameChange, filterError }) => {
  return (
    <div className="searchMain">
      <TextField
        sx={{ ml: 0, flex: 1, width: 400 }}
        label="Search by email ID"
        variant="outlined"
        value={name}
        onChange={(e) => handleNameChange(e.target.value)}
        InputLabelProps={{
          shrink: !!name,
        }}
      />
      {filterError && <Alert severity="error">No User Found!</Alert>}
    </div>
  );
};

const DisplayTableData = ({
  tableHeaders,
  tableData,
  setViewDeleteUser,
  setSelectedUserToDelete,
}) => {
  const navigate = useNavigate();

  const handleEdit = (user) => {
    // console.log(user);
    sessionStorage.setItem("edit_user_details", JSON.stringify(user));
    sessionStorage.setItem("edit_user", true);
    navigate("/edit-users");
  };

  return (
    <div className="table-container">
      <table className="user-table">
        <thead>
          <tr>
            {tableHeaders?.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {tableData?.map((user, index) => (
            <tr key={index}>
              <td>{user?.email || "--"}</td>
              <td>
                {user?.firstName || "--"} {user?.lastName || "--"}
              </td>
              <td>{user?.profileCreationDate || "--"}</td>
              <td>{user?.userRole || "--"}</td>
              <td>{user?.userLocations?.[0]?.country || "--"}</td>
              <td
                className={
                  user?.profileStatus?.toLowerCase() === "active"
                    ? "active"
                    : user?.profileStatus?.toLowerCase() === "inactive"
                    ? "inactive"
                    : ""
                }
              >
                {user?.profileStatus || "--"}
              </td>
              <td className="details">
                <button>View Details</button>
              </td>
              <td className="edit">
                <button onClick={() => handleEdit(user)}>
                  <EditIcon />
                </button>
              </td>
              <td className="delete">
                <button
                  onClick={() => {
                    setSelectedUserToDelete({
                      email: user.email,
                      userRole: user.userRole,
                    });
                    setViewDeleteUser(true);
                  }}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const DeleteUser = ({
  setViewDeleteUser,
  setSelectedUserToDelete,
  deleteUser,
}) => {
  // const navigate = useNavigate();
  return (
    <Popup>
      <div className="delete-user">
        {/* <div className="cross" onClick={() => setViewDeleteUser(false)}>
          <img src={crossIcon} alt="" />
        </div> */}
        <div className="user" style={{ color: "#E31414" }}>
          <DeleteIcon />
        </div>
        <div className="desc">Are you sure you want to delete this user?</div>
        <div className="okay">
          <button
            onClick={() => {
              setSelectedUserToDelete({});
              setViewDeleteUser(false);
            }}
          >
            Cancel
          </button>
          <button onClick={() => deleteUser()}>Delete</button>
        </div>
      </div>
    </Popup>
  );
};

const FilterDialog = ({
  open,
  handleClose,
  handleSearchDev,
  locationOptions,
}) => {
  const [filters, setFilters] = useState({
    creationDate: null,
    location: null,
    userRole: null,
    status: null,
  });

  const handleChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            position: "absolute",
            top: 0,
            right: 0,
            margin: 2,
            width: 400,
          },
        }}
      >
        <DialogContent>
          <TextField
            type="date"
            label="Creation Date"
            name="creationDate"
            value={filters.creationDate}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            select
            label="Location"
            name="location"
            value={filters.location}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="dense"
          >
            <MenuItem value="">Select location</MenuItem>
            {locationOptions.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
            {/* Add more MenuItems as needed */}
          </TextField>

          <TextField
            select
            label="User Role"
            name="userRole"
            value={filters.userRole}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="dense"
          >
            <MenuItem value="">Select User role</MenuItem>
            <MenuItem value="CLSM">CLSM</MenuItem>
            <MenuItem value="CSM">CSM</MenuItem>
            <MenuItem value="HR">HR</MenuItem>
            <MenuItem value="Expert">Expert</MenuItem>
            {/* Add more MenuItems as needed */}
          </TextField>

          <RadioGroup
            name="status"
            value={filters.status}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="Active"
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value="Inactive"
              control={<Radio />}
              label="Inactive"
            />
          </RadioGroup>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() =>
              setFilters({
                creationDate: null,
                availableDate: null,
                location: null,
                userRole: null,
                status: null,
              })
            }
          >
            RESET
          </Button>
          <Button onClick={() => handleSearchDev(filters)}>SAVE</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
