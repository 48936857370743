import React, { useEffect, useState } from "react";
import "./Rubric.scss";
import RubricsCard from "../../Components/RubricsCard/RubricsCard";
import addRubric from "../../Assessts/SVGs/add-rubric.svg";
import AddRubric from "../../Components/AddRubric/AddRubric";
import { getExpertRubricList, getHRRubricList } from "../../APIs/rubric";
import { toast } from "react-toastify";

const Rubric = () => {
  const [user, setUser] = useState("HR");
  return (
    <div className="rubric-main">
      <div className="main-title">Rubric</div>
      <div className="buttons">
        <button
          className={`user hr ${user === "HR" ? "active" : ""}`}
          onClick={() => setUser("HR")}
        >
          HR
        </button>
        <button
          className={`user expert ${user === "Expert" ? "active" : ""}`}
          onClick={() => setUser("Expert")}
        >
          Expert
        </button>
      </div>
      <RubricContainer user={user} />
    </div>
  );
};

const RubricContainer = ({ user }) => {
  const [competencies, setCompetencies] = useState([]);
  const [showRubricDialog, setShowRubricDialog] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [oldSectionName, setOldSectionName] = useState("");
  const [questions, setQuestions] = useState([{ id: "", text: "" }]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    fetchRubricList();
  }, [showRubricDialog, user]);

  const fetchRubricList = async () => {
    const res =
      user === "HR" ? await getHRRubricList() : await getExpertRubricList();
    if (!res) {
      toast.error("There is some issue in the server. Please try again later!");
    }
    if (Array.isArray(res)) {
      setCompetencies([...res]);
    }
  };

  return (
    <div className="rubric-container">
      <RubricsCard
        competencies={competencies}
        setShowRubricDialog={setShowRubricDialog}
        setSectionName={setSectionName}
        setQuestions={setQuestions}
        refreshRubricList={fetchRubricList}
        setIsEdit={setIsEdit}
        setOldSectionName={setOldSectionName}
        user={user}
      />
      <AddRubricBox
        showRubricDialog={showRubricDialog}
        setShowRubricDialog={setShowRubricDialog}
      />
      {showRubricDialog && (
        <AddRubric
          setShowRubricDialog={setShowRubricDialog}
          sectionName={sectionName}
          questions={questions}
          setSectionName={setSectionName}
          setQuestions={setQuestions}
          isEdit={isEdit}
          oldSectionName={oldSectionName}
          user={user}
        />
      )}
    </div>
  );
};

const AddRubricBox = ({ setShowRubricDialog }) => {
  const handleAddRubric = () => {
    setShowRubricDialog(true);
  };
  return (
    <div className="add-rubric-container">
      <div className="add-rubric-icon">
        <img src={addRubric} alt="add-rubric" />
      </div>
      <div className="rubric-title">Want to create new rubric?</div>
      <div>
        <button onClick={handleAddRubric}>Add a New Rubric</button>
      </div>
    </div>
  );
};

export default Rubric;
