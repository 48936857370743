import { toast } from "react-toastify";
import { getCandidateResume } from "../APIs/interview";

export const handleDownLoadResume = async (id) => {
  try {
    const data = {
      id: id,
    };
    const res = await getCandidateResume(data);
    const downloadLink = document.createElement("a");
    downloadLink.href = res;
    downloadLink.setAttribute("download", "resume.pdf");
    downloadLink.click();
  } catch (err) {
    console.log("handleDownload error ==>", err);
    toast.err("Error downloading resume");
  }
};
