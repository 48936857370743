import React, { useRef, useState } from "react";
import "./RubricsCard.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import editIcon from "../../Assessts/SVGs/edit.svg";
import deleteIcon from "../../Assessts/SVGs/delete.svg";
import deleteSuccessIcon from "../../Assessts/SVGs/delete-success.svg";
import { CommonDialog } from "../common/Dialog/CommonDialog";
import {
  deleteExpertRubricQuestion,
  deleteHRRubricQuestion,
} from "../../APIs/rubric";

export const RubricsCard = ({
  competencies,
  setShowRubricDialog,
  setSectionName,
  setQuestions,
  refreshRubricList,
  setIsEdit,
  setOldSectionName,
  user,
}) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deletedSectionNames = useRef([]);
  const [dialogData, setDialogData] = useState({
    message1: "Delete Rubric",
    message2: "Are you sure you want to delete this rubric?",
  });
  const handleDialogClose = () => {
    setShowDeleteDialog(false);
    setDialogData({
      message1: "Delete Rubric",
      message2: "Are you sure you want to delete this rubric?",
    });
    setButtonsList({
      Cancel: {
        name: "Cancel",
        action: handleDialogClose,
      },
      Close: {
        name: "Close",
        action: handleDialogClose,
      },
      Delete: {
        name: "Delete",
        action: handleDeleteSection,
      },
    });
    refreshRubricList();
  };
  const handleDeleteSection = async () => {
    const data = { sectionNames: deletedSectionNames.current };
    const res =
      user === "HR"
        ? await deleteHRRubricQuestion(data)
        : await deleteExpertRubricQuestion(data);
    if (res) {
      setButtonsList({
        Close: {
          name: "Close",
          action: handleDialogClose,
        },
        Okay: {
          name: "Okay",
          action: handleDialogClose,
        },
      });
      setDialogData({
        message2: "A rubric has been deleted successfully!",
        icon: deleteSuccessIcon,
      });
    }
  };
  const [buttonsList, setButtonsList] = useState({
    Cancel: {
      name: "Cancel",
      action: handleDialogClose,
    },
    Close: {
      name: "Close",
      action: handleDialogClose,
    },
    Delete: {
      name: "Delete",
      action: handleDeleteSection,
    },
  });

  const handleTileClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const formatText = (text) => {
    return text.replace(/\n/g, "<br />");
  };

  const handleEditButton = (e, competency) => {
    e.stopPropagation();
    setShowRubricDialog(true);
    setSectionName(competency?.title);
    setOldSectionName(competency?.title);
    const questionList = competency?.description.map((question, id) => {
      return { id, text: question };
    });
    setQuestions([...questionList]);
    setIsEdit(true);
  };

  const handleDeleteButton = (e, competency) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
    deletedSectionNames.current = [competency?.title];
  };

  return (
    <div className="competency-card">
      {competencies.map((competency, index) => (
        <div key={index} className="competency-tile">
          <div
            className="competency-header"
            onClick={() => handleTileClick(index)}
          >
            <div className="competency-title">
              <span
                className={`arrow ${expandedIndex === index ? "open" : ""}`}
              >
                <KeyboardArrowRightIcon />
              </span>
              <span>{competency?.title}</span>
            </div>
            <div className="action-buttons">
              <img
                onClick={(e) => handleDeleteButton(e, competency)}
                src={deleteIcon}
                alt="delete-icon"
              ></img>
              <img
                onClick={(e) => handleEditButton(e, competency)}
                src={editIcon}
                alt="edit-icon"
              ></img>
            </div>
          </div>
          {expandedIndex === index && (
            <div className="competency-description">
              <div>
                {competency?.description?.map((item, index) => (
                  <div key={index} className="each-desc">
                    <span className="index">{index + 1}.</span>
                    <span className="desc">
                      {" "}
                      <p
                        dangerouslySetInnerHTML={{ __html: formatText(item) }}
                      ></p>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
      {showDeleteDialog && (
        <CommonDialog
          buttonsList={buttonsList}
          data={dialogData}
        ></CommonDialog>
      )}
    </div>
  );
};

export default RubricsCard;
