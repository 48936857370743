import axios from "axios";
import { CANDIDATE_PROGRESS } from "../APIs/URLS";


export const getUserProgress = async (email) => {
  try {
    const endpoint = CANDIDATE_PROGRESS;
    const data = {
      id: email,
    };
    //axios post call
    const res = await axios
      .post(endpoint, data)
      .then((response) => response.data);
    return res;
  } catch (err) {
    console.log("getUserProgress error==>", err);
    return Promise.reject(err);
  }
};




