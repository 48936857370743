import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import "./Interview.scss";
import { Alert, TextField } from "@mui/material";
import { TablePages } from "../../Components/common/Pagination_Data/Pagination_Data";
import redFlag from "../../Assessts/SVGs/red-flag.svg";
import grayFlag from "../../Assessts/SVGs/gray-flag.svg";
import { useInterviewRequest } from "../../hooks/useInterviewRequest";
import { useUtils } from "../../hooks/useUtils";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "../../Assessts/SVGs/Close.svg";
import { CustomDropDown } from "../../Components/common/CustomDropDown/CustomDropDown";
import { getFilterHRInterviewRequest } from "../../APIs/interview";
const commonHRHeadersList = [
  "Role",
  "Candidate Name",
  "Email ID",
  "Contact Number",
  "Interview Date",
  "Location",
];
const commonExpertHeadersList = [
  "Role",
  "Candidate Name",
  "Skills",
  "Email ID",
  "Contact Number",
  "Interview Date",
  "Location",
];
export const pendingHRHeadersList = [...commonHRHeadersList, "Status"];
export const pendingExpertHeadersList = [...commonExpertHeadersList, "Status"];
export const assignedHRHeadersList = [...pendingHRHeadersList, "Assigned HR"];
export const declinedHRHeadersList = [...commonHRHeadersList, "Assigned HR"];
export const assignedExpertHeadersList = [
  ...pendingExpertHeadersList,
  "Assigned Expert",
];
export const declinedExpertHeadersList = [
  ...commonExpertHeadersList,
  "Assigned Expert",
];
const countriesData = ["India", "United States", "Canada"];
const pendingStatusData = ["Pending", "Expired"];
const assignedStatusData = ["Scheduled", "Completed"];
const flagData = ["Approve", "Pending", "No Flag"];

export const Interview = () => {
  const {
    active,
    tableHeaders,
    tableData,
    page,
    handleRequestButton,
    handlePageChange,
    setSearchQuery,
    totalPages,
    user,
    setUser,
    setTableData,
  } = useInterviewRequest("Pending");
  const [viewFilters, setViewFilters] = useState(false);
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("");
  const [flag, setFlag] = useState("");

  const { debounce } = useUtils();

  const getInterviewRequestData = async () => {
    try {
      setViewFilters(false);
      let queryParams = {};
      if (date) {
        queryParams["interviewScheduledDate"] = date
          .split("-")
          .reverse()
          .join("-");
      }
      if (location) {
        queryParams["location"] = location;
      }
      if (status) {
        queryParams["interviewStatus"] = status;
      }
      if (flag) {
        queryParams["flaggedStatus"] = flag;
      }
      const res = await getFilterHRInterviewRequest(queryParams);
      console.log(res);
      // if (res) {
      //   setTableData([...res?.data]);
      // }
    } catch (err) {
      console.log("getInterviewRequestData error ==>", err);
      toast.error(err.message);
    }
  };

  const handleReset = async () => {
    setDate("");
    setLocation("");
    setStatus("");
    setFlag("");
  };

  const handleSearch = debounce((query) => {
    if (query.length > 2) {
      setSearchQuery(query);
    } else if (query.length === 0) {
      setSearchQuery("");
    }
  }, 500);

  return (
    <div className="interview-container">
      <div className="title">Interview</div>
      <div className="assign-buttons">
        <button
          className={`assign hr ${user === "HR" ? "active" : ""}`}
          onClick={() => setUser("HR")}
        >
          Assign HR
        </button>
        <button
          className={`assign expert ${user === "Expert" ? "active" : ""}`}
          onClick={() => setUser("Expert")}
        >
          Assign Expert
        </button>
      </div>
      <div className="search-and-filter">
        <SearchInterview handleSearch={handleSearch}></SearchInterview>
        <button className="filterButton" onClick={() => setViewFilters(true)}>
          Filters <FilterListIcon />
        </button>
      </div>
      <div className="interview-request-buttons">
        <button
          className={`${active === "Pending" ? "active" : ""}`}
          onClick={() =>
            handleRequestButton(
              "Pending",
              user === "HR" ? pendingHRHeadersList : pendingExpertHeadersList
            )
          }
        >
          {user} Assignment Pending
        </button>
        <button
          className={`${active === "Assigned" ? "active" : ""}`}
          onClick={() =>
            handleRequestButton(
              "Assigned",
              user === "HR" ? assignedHRHeadersList : assignedExpertHeadersList
            )
          }
        >
          {user} Assigned
        </button>
        <button
          className={`${active === "Declined" ? "active" : ""}`}
          onClick={() =>
            handleRequestButton(
              "Declined",
              user === "HR" ? declinedHRHeadersList : declinedExpertHeadersList
            )
          }
        >
          Declined Interview Request
        </button>
      </div>
      <RequestTable
        tableHeaders={tableHeaders}
        tableData={tableData}
        active={active}
        user={user}
      ></RequestTable>
      <TablePages
        page={page}
        handlePageChange={handlePageChange}
        totalPages={totalPages}
      />
      {viewFilters && (
        <FiltersOptions
          date={date}
          setDate={setDate}
          location={location}
          setLocation={setLocation}
          setViewFilters={setViewFilters}
          status={status}
          setStatus={setStatus}
          flag={flag}
          setFlag={setFlag}
          handleReset={handleReset}
          user={user}
          getInterviewRequestData={getInterviewRequestData}
          active={active}
        />
      )}
    </div>
  );
};

const RequestTable = ({ tableHeaders, tableData, active, user }) => {
  const navigate = useNavigate();
  return (
    <div className="table-container">
      <table className="user-table">
        <thead>
          <tr>
            {tableHeaders?.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((request, index) => (
            <tr key={index}>
              <td>{request?.role || "--"}</td>
              <td
                style={{ textDecorationLine: "underline", cursor: "pointer" }}
                onClick={() => {
                  navigate("/candidate-details", {
                    state: { ...request, active, user },
                  });
                }}
              >
                {request?.candidateName || "--"}{" "}
                {request?.isFlag === "admin" ? (
                  <img className="flag" src={redFlag}></img>
                ) : request?.isFlag === "hr" ? (
                  <img className="flag" src={grayFlag}></img>
                ) : (
                  <></>
                )}
              </td>
              {user === "Expert" && (
                <td>{request?.allSkillsOfCandidate?.join(", ") || "--"}</td>
              )}
              <td>{request?.email || "--"}</td>
              <td>{request?.contactNumber || "--"}</td>
              <td>{request?.interviewScheduledDate || "--"}</td>
              <td>{request?.location || "--"}</td>
              {active === "Declined" ? (
                <td>
                  {user === "HR"
                    ? request?.hrName
                    : request?.expertName || "--"}
                </td>
              ) : (
                <td>{request?.status || "--"}</td>
              )}
              {active === "Pending" ? (
                <td>
                  <button
                    disabled={request?.status !== "Pending" ? true : false}
                    className={`assign-hr ${
                      request?.status === "Pending" ? "" : "expired"
                    }`}
                    onClick={() => {
                      navigate(
                        `${user === "HR" ? "/assign-hr" : "/assign-expert"}`,
                        { state: { ...request, user } }
                      );
                    }}
                  >
                    Assign {user}
                  </button>
                </td>
              ) : active === "Assigned" ? (
                <td>
                  {user === "HR"
                    ? request?.hrName
                    : request?.expertName || "--"}
                </td>
              ) : (
                <td>
                  <button
                    className="assign-hr"
                    onClick={() => {
                      navigate(
                        `${user === "HR" ? "/assign-hr" : "/assign-expert"}`,
                        { state: { ...request, user } }
                      );
                    }}
                  >
                    Reassign {user}
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const SearchInterview = ({ name, handleSearch, filterError }) => {
  return (
    <div className="searchFilterMain">
      <div className="search">
        {/* <SearchIcon></SearchIcon> */}
        <TextField
          sx={{ ml: 0, flex: 1, width: 455, height: 44 }}
          placeholder="Search by email ID"
          variant="outlined"
          value={name}
          onChange={(e) => handleSearch(e.target.value)}
          InputLabelProps={{
            shrink: !!name,
          }}
        />
      </div>
      {filterError && <Alert severity="error">No User Found!</Alert>}
    </div>
  );
};

const FiltersOptions = ({
  date,
  setDate,
  location,
  setLocation,
  status,
  setStatus,
  setViewFilters,
  handleReset,
  getInterviewRequestData,
  flag,
  setFlag,
  user,
  active,
}) => {
  const handleBoxClick = (e) => {
    if (e.target.id === "mainBox") {
      setViewFilters(false);
    }
  };

  return (
    <div
      className="interview-filter"
      id="mainBox"
      onClick={(e) => handleBoxClick(e)}
    >
      <div className="popupMainWrapper">
        <div className="filter-title">
          <span>Filters</span>
          <div onClick={() => setViewFilters(false)}>
            <img src={CloseIcon} alt="close-icon" />
          </div>
        </div>
        <div className="date">
          <div>Date</div>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="location">
          <div>Location</div>
          <CustomDropDown
            options={countriesData}
            handleChange={(data) => setLocation(data)}
            value={location}
          />
        </div>
        {active !== "Declined" && (
          <div className="status">
            <div>Interview Status</div>
            <CustomDropDown
              options={
                active === "Pending"
                  ? pendingStatusData
                  : active === "Assigned"
                  ? assignedStatusData
                  : ""
              }
              handleChange={(data) => setStatus(data)}
              value={status}
            />
          </div>
        )}
        {user === "HR" && (
          <div className="flag">
            <div>Flag</div>
            <CustomDropDown
              options={flagData}
              handleChange={(data) => setFlag(data)}
              value={flag}
            />
          </div>
        )}
        <div className="buttons">
          <div onClick={() => handleReset()}>RESET</div>
          <div onClick={() => getInterviewRequestData()}>SAVE</div>
        </div>
      </div>
    </div>
  );
};
