import styles from "./Round3Report.module.scss";
import GradeIcon from "@mui/icons-material/Grade";

export const Round3Report = ({ r3Details }) => {
  return (
    <>
      {r3Details && (
        <div className={styles.r3container}>
          <Summary data={r3Details?.summaryOfComments} />
          <TotalScore
            rating={r3Details?.totalScore}
            comments={r3Details?.comments}
          />
          {r3Details?.sections?.map((item, index) => (
            <EachCompetency key={index} data={item} />
          ))}
        </div>
      )}
    </>
  );
};

const Summary = ({ data }) => {
  // console.log("r3 summary=>", data);
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.title}>Round 3 : </div>
        <div className={styles.title}>Summary</div>
      </div>
      <div className={styles.row}>{data}</div>
    </div>
  );
};

const TotalScore = ({ rating, comments }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.title}>Total Score : </div>
        <div className={`${styles.flex} ${styles.colorBlue}`}>
          <div>{rating}</div>
          <GradeIcon />
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.title} ${styles.title2}`}>
          Overall Comment :
        </div>
        <div className={styles.comments}>{comments}</div>
      </div>
    </div>
  );
};

const EachCompetency = ({ data }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.title}>{data?.section} : </div>
        <div>
          {Array(5)
            .fill(0)
            ?.map((item, index) => (
              <GradeIcon
                key={index}
                style={{
                  color: index + 1 <= data?.rating ? "#062B6E" : "#e0e0e0",
                }}
              />
            ))}
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.title} ${styles.title2}`}>
          HR Observation :
        </div>
        <div className={styles.comments}>{data?.comments}</div>
      </div>
    </div>
  );
};
