export const SkillsData = [
  {
    name: "Development",
    subfield: [
      {
        name: "Frontend",
        dev: [
          {
            section: "Frontend",
            header: "Languages and Framework for Frontend",
            subheader:
              "Add upto 2 languages and upto 2 Framework for each language",
            languages: [
              {
                languages: "JavaScript",
                framework: ["Angular JS", "React JS", "Next JS", "JQuery"],
              },
            ],
          },
        ],
      },
      {
        name: "Backend",
        dev: [
          {
            section: "Backend",
            header: "Languages and Framework for Backend",
            subheader:
              "Add upto 2 languages and upto 2 Framework for each language",
            languages: [
              {
                languages: "Java",
                framework: ["Spring", "Hibernate", "Struts"],
              },
              // {
              //   languages: "ASP",
              //   framework: ["Entity Framework", ".Net framework", "WCF", "WPF"],
              // },
              // {
              //   languages: "PHP",
              //   framework: ["Laravel", "Zend", "Flask", "Cake PHP"],
              // },
              {
                languages: "Python",
                framework: ["DJango", "Flask", "Web2py"],
              },
            ],
          },
          {
            section: "Database",
            header: "Languages for Database",
            subheader:
              "Add upto 2 database and how much expertise you have in each",
            languages: [
              // { languages: "Oracle" },
              { languages: "MySQL" },
              { languages: "MongoDB" },
              // { languages: "SQL Server" },
            ],
          },
        ],
      },
      {
        name: "Full Stack",
        dev: [
          {
            section: "Frontend",
            header: "Languages and Framework for Frontend",
            subheader:
              "Add upto 3 languages and upto 3 Framework for each language",
            languages: [
              {
                languages: "JavaScript",
                framework: ["Angular JS", "React JS", "Next JS", "JQuery"],
              },
            ],
          },
          {
            section: "Backend",
            header: "Languages and Framework for Backend",
            subheader:
              "Add upto 3 languages and upto 3 Framework for each language",
            languages: [
              {
                languages: "Java",
                framework: ["Spring", "Hibernate", "Struts"],
              },
              // {
              //   languages: "ASP",
              //   framework: ["Entity Framework", ".Net framework", "WCF", "WPF"],
              // },
              // {
              //   languages: "PHP",
              //   framework: ["Laravel", "Zend", "Flask", "Cake PHP"],
              // },
              {
                languages: "Python",
                framework: ["DJango", "Flask", "Web2py"],
              },
            ],
          },
          {
            section: "Database",
            header: "Languages for Database",
            subheader:
              "Add upto 3 database and how much expertise you have in each",
            languages: [
              // { languages: "Oracle" },
              { languages: "MySQL" },
              { languages: "MongoDB" },
              // { languages: "SQL Server" },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Data Science",
    subfield: [
      {
        name: "Data Science Engineer",
        dev: [
          {
            section: "Data Science",
            header: "Language and Framework for Data Science Engineer",
            subheader:
              "Add upto 2 languages and upto 2 Framework for each language",
            languages: [
              {
                languages: "Python",
                framework: ["DJango", "Flask"],
              },
            ],
          },
          {
            section: "Database",
            header: "Languages for Database",
            subheader:
              "Add upto 2 database and how much expertise you have in each",
            languages: [{ languages: "MySQL" }],
          },
        ],
      },
    ],
  },
];
