import "./ProgressBar.scss";

export const ProgressBar = ({ progress = 20 }) => {
  return (
    <div className="progress-bar-main">
      <div className="progress-bar">
        <div className="progress-fill" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="rating">{progress}%</div>
    </div>
  );
};
