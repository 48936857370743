import "./CustomDropDown.scss";
import { Select, MenuItem } from "@mui/material";

export const CustomDropDown = ({
  label,
  options,
  handleChange,
  value,
  placeholder,
  isDisabled = false,
}) => {
  return (
    <div className="drop-down-container">
      <label className="drop-down-label">{label}</label>
      <Select
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        // displayEmpty
        fullWidth
        className={`select-value ${value === "" ? "empty-value" : ""}`}
        disabled={isDisabled}
        style={{ cursor: isDisabled ? "not-allowed" : "" }}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
