import { useEffect, useState } from "react";
import "./CandidateDetailsHeader.scss";
import accountIcon from "../../Assessts/SVGs/account-setting.svg";
import professionalDetails from "../../Assessts/SVGs/professional-details.svg";
import accountIconGray from "../../Assessts/SVGs/account-setting-gray.svg";
import professionalDetailsGray from "../../Assessts/SVGs/professional-details-gray.svg";
import { Link } from "react-router-dom";
import { getUserProgress } from "../../Common/flowAfterLogin";

export const CandidateDetailsHeader = ({ indexActive, email }) => {
  const [titles, setTitles] = useState([
    {
      title: "Personal Details",
      icon: accountIcon, // Use the imported SVG directly in the state
      icon2: accountIconGray,
      link: "/details/personal",
    },
    {
      title: "Experience & Technical Skills",
      icon: professionalDetails, // Use the imported SVG directly in the state
      icon2: professionalDetailsGray,
      link: "/details/professional",
      disAble: true,
    },
  ]);

  const checkForDetails = async () => {
    try {
      const res = await getUserProgress(email);
      const data = res?.progress;
      if (data.includes("Personal Details")) {
        setTitles([
          {
            title: "Personal Details",
            icon: accountIcon, // Use the imported SVG directly in the state
            icon2: accountIconGray,
            link: `/details/personal?user=${email}`,
          },
          {
            title: "Experience & Technical Skills",
            icon: professionalDetails, // Use the imported SVG directly in the state
            icon2: professionalDetailsGray,
            link: `/details/professional?user=${email}`,
            disAble: false,
          },
        ]);
        return;
      }
    } catch (err) {
      console.log("checkForDetails error==>", err);
    }
  };

  useEffect(() => {
    checkForDetails();
  }, []);

  return (
    <div className="details-header">
      {titles?.map((item, index) => (
        <Link
          className={indexActive === index ? "active" : ""}
          key={index}
          to={item?.disAble ? "" : item?.link}
          style={{ cursor: item?.disAble ? "not-allowed" : "" }}
        >
          <img
            src={indexActive === index ? item?.icon : item?.icon2}
            alt="icon"
          />
          <span>{item?.title}</span>
        </Link>
      ))}
    </div>
  );
};
