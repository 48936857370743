import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, TextField, IconButton, Stack, Button } from '@mui/material';
import { calculateTotalExperience } from "../../../../utils/dateUtils";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from '@mui/icons-material/Close';
import UpdateButton from '../UpdateButton';
import SkillAdd from '../SkillAdd/SkillAdd';

const formatToInputValue = (dateString) => {
    if (!dateString) return "";
    const [month, year] = dateString.split("/");
    return `${year}-${month}`;
};

const formatToMonthYear = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${year}`;
};

const WorkExpAdd = ({ open, onClose, handleSubmit }) => {
    const [work, setWork] = useState({startDate:'',endDate:'', projects: [{ techStack: [],responsibilities:[] }] });
    const [openSkill, setOpenSkill] = useState(false);
    const [currentProjectIndex, setCurrentProjectIndex] = useState(null);
    const [errors, setErrors] = useState({});

    const validateFields = () => {
      let fieldErrors = {};
     
        fieldErrors = {
            location: !work.location,
            company: !work.company,
          startDate: !work.startDate,
          position: !work.position,
          endDate: !work.endDate,
         
        };
     
      setErrors(fieldErrors);
      return !Object.values([fieldErrors]).some((error) => Object.values(error).includes(true));
    };

    const onFieldChange = (field, value) => {
        setWork((prevWork) => ({
            ...prevWork,
            [field]: value,
        }));
    };

    const onProjectFieldChange = (projIndex, field, value) => {
        setWork((prevWork) => {
            const updatedProjects = [...prevWork.projects];
            updatedProjects[projIndex] = { ...updatedProjects[projIndex], [field]: value };
            return { ...prevWork, projects: updatedProjects };
        });
    };

    const handleAddProject = () => {
        setWork((prevWork) => ({
            ...prevWork,
            projects: [...prevWork.projects, { projectName: '', projectSummary: '', responsibilities: [], techStack: [] }],
        }));
    };

    const handleHighlightsChange = (projIndex, value) => {
        const highlightsArray = value
            .split("\n")
            .map(line => line.replace(/^•\s*/, "").trim())
            .filter(line => line !== "");

        setWork((prevWork) => {
            const updatedProjects = [...prevWork.projects];
            updatedProjects[projIndex].responsibilities = highlightsArray;
            return { ...prevWork, projects: updatedProjects };
        });
    };

    const handleAddBulletPoint = (projIndex) => {
        setWork((prevWork) => {
            const updatedProjects = [...prevWork.projects];
            updatedProjects[projIndex].responsibilities = [
                ...(updatedProjects[projIndex].responsibilities || []),
                "" // Add an empty bullet point
            ];
            return { ...prevWork, projects: updatedProjects };
        });
    };

    const handleStartDateChange = (value) => {
        onFieldChange("startDate", formatToMonthYear(value));
    };

    const handleEndDateChange = (value) => {
        onFieldChange("endDate", formatToMonthYear(value));
    };

    const handleRemoveSkill = (projIndex, skillIdx) => {
        setWork((prevWork) => {
            const updatedProjects = [...prevWork.projects];
            updatedProjects[projIndex].techStack = updatedProjects[projIndex].techStack.filter((_, idx) => idx !== skillIdx);
            return { ...prevWork, projects: updatedProjects };
        });
    };

    const handleSkillAddSubmit = (techStackArray) => {
        const validTechStack = techStackArray.filter((tech) => tech.trim() !== '');
        if (validTechStack.length > 0 && currentProjectIndex !== null && currentProjectIndex < work.projects.length) {
            setWork((prevWork) => {
                const updatedProjects = [...prevWork.projects];
                updatedProjects[currentProjectIndex].techStack = [
                    ...(updatedProjects[currentProjectIndex].techStack || []),
                    ...validTechStack,
                ];
                return { ...prevWork, projects: updatedProjects };
            });
        }
        setOpenSkill(false); // Close skill dialog after submission
    };

    const handleOpenSkillDialog = (projIndex) => {
        setCurrentProjectIndex(projIndex);
        setOpenSkill(true);
    };

    const handleClickUpdate = () => {
        if(validateFields()){
                    handleSubmit(work);
                    setWork({startDate:'',endDate:'', projects: [{ techStack: [],responsibilities:[] }] })
        }
       

    };

    const handleCloseSkill = () => {
        setOpenSkill(false);
    };

    return (
        <Dialog open={open} maxWidth="md" sx={{ padding: '20px' }}>
            <SkillAdd open={openSkill} onClose={handleCloseSkill} handleSumbit={handleSkillAddSubmit} />

            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: 0,
                }}
            >
                <span>Add Work Experience</span>
                <IconButton onClick={onClose} sx={{ color: '#062B6E' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <div className="work-experience-item" style={{ display: "flex", flexDirection: "column", gap: "20px", padding: "20px" }}>
                <div className="item-container" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <div className="designation-main" style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
                        <div className="designation" style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                            <TextField
                                value={work?.position || ""}
                                onChange={(e) => onFieldChange("position", e.target.value)}
                                variant="outlined"
                                placeholder="Add Position"
                                size="small"
                                style={{ width: "220px", marginRight: "12px" }}
                                error={!!errors?.position}
                            />
                            <CircleIcon style={{ color: "green", fontSize: "10px" }} />
                            <Stack direction="row" spacing={2} alignItems="center" gap="12px" marginLeft={2}>
                                <TextField
                                    label="Start Date"
                                    type="month"
                                    value={formatToInputValue(work.startDate)}
                                    onChange={(e) => handleStartDateChange(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    style={{ width: "125px", marginRight: "12px" }}
                                    error={!!errors?.startDate}
                                />
                                <TextField
                                    label="End Date"
                                    type="month"
                                    value={formatToInputValue(work.endDate)}
                                    onChange={(e) => handleEndDateChange(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    style={{ width: "125px", marginRight: "12px" }}
                                    error={!!errors?.endDate}
                                />
                                <span>
                                    {work.startDate && work.endDate
                                        ? `(${calculateTotalExperience(work.startDate, work.endDate)})`
                                        : ""}
                                </span>
                            </Stack>
                        </div>

                        <TextField
                            value={work.company || ""}
                            placeholder="Add Company"
                            onChange={(e) => onFieldChange("company", e.target.value)}
                            variant="outlined"
                            size="small"
                            style={{ width: "200px", textAlign: "center", marginTop: "8px" }}
                            error={!!errors?.company}
                        />

                        <div style={{ display: "flex", alignItems: "center", gap: "8px", marginTop: "8px" }}>
                            <span style={{ color: "#4F4F4F", fontFamily: "Poppins", fontSize: "14px", fontWeight: 500 }}>
                                Location:
                            </span>
                            <TextField
                                value={work.location || ""}
                                placeholder="Add Location"
                                onChange={(e) => onFieldChange("location", e.target.value)}
                                variant="outlined"
                                size="small"
                                style={{ width: "200px" }}
                                error={!!errors?.location}
                            />
                        </div>
                    </div>

                    {work.projects.map((project, projIndex) => (
                        <div key={projIndex} className="project-responsibilities-container" style={{ padding: "10px", borderBottom: "1px solid #e0e0e0", marginBottom: "10px" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span>Project {projIndex + 1}</span>
                                {projIndex === work.projects.length - 1 && (
                                    <Button onClick={handleAddProject} variant="outlined" size="small" style={{ color: "#062B6E", border: "1px solid #062B6E",margin:'4px' }}>
                                        Add Project
                                    </Button>
                                )}
                            </div>

                            <TextField
                                placeholder="Add Name of Project"
                                value={project.projectName || ""}
                                onChange={(e) => onProjectFieldChange(projIndex, "projectName", e.target.value)}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />

                            <TextField
                                placeholder="Project Description"
                                value={project.projectSummary || ""}
                                onChange={(e) => onProjectFieldChange(projIndex, "projectSummary", e.target.value)}
                                multiline
                                rows={4}
                                variant="outlined"
                                size="small"
                                fullWidth
                                style={{ marginTop: "8px" }}
                            />

                            <TextField
                                placeholder=". Type and Add Responsibilities"
                                value={(project.responsibilities || []).map((highlight) => `• ${highlight}`).join("\n")}
                                onChange={(e) => handleHighlightsChange(projIndex, e.target.value)}
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                size="small"
                                style={{ marginTop: "8px" }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleAddBulletPoint(projIndex);
                                    }
                                }}
                            />

                            <div className="tech-stacks" style={{ marginTop: "10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                                    <span>Technologies Stack:</span>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleOpenSkillDialog(projIndex)}
                                        style={{ color: "#062B6E", fontFamily: "Poppins", fontSize: "14px", fontWeight: 500, border: "1px solid #062B6E" }}
                                    >
                                        Add Technology
                                    </Button>
                                </div>

                                <div className="tech-stack-list" style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                                    {project.techStack.map((tech, idx) => (
                                        <div key={idx} style={{ position: "relative", display: "inline-flex", alignItems: "center", padding: "7px 10px", background: "rgba(201, 215, 240, 0.6)", borderRadius: "4px", margin: "5px" }}>
                                            <span style={{ color: "#062b6e", fontSize: "14px", fontWeight: "500" }}>{tech}</span>
                                            <IconButton
                                                size="small"
                                                style={{ position: "absolute", top: "-15px", right: "-15px", zIndex: 1 }}
                                                onClick={() => handleRemoveSkill(projIndex, idx)}
                                            >
                                                <CancelRoundedIcon fontSize="small" style={{ color: "#062b6e" }} />
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <UpdateButton handleClickUpdateButton={handleClickUpdate} />
            </div>
        </Dialog>
    );
};

export default WorkExpAdd;
