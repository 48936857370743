import { Link } from "react-router-dom";
import "./GotoDevelopers.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export const GotoDevelopers = () => {
  return (
    <div className="back-arrow">
      <Link to={"/view-developers?page=1"} className="goto">
        <span className="arrow-main">
          <ArrowBackIosIcon />
        </span>
        <span>Developers</span>
      </Link>
    </div>
  );
};

// const GoToAllUsers = () => {
//   return (
//     <div className="back-arrow">
//       <Link to={"/view-users"} className="goto">
//         <span className="arrow-main">
//           <ArrowBackIosIcon />
//         </span>
//         <span>Users</span>
//       </Link>
//     </div>
//   );
// };
