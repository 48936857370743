import axios from "axios";
import { ADMIN_LOGIN } from "./URLS";
import { toast } from "react-toastify";

export const loginAdmin = async (data) => {
    try {
        const URL = ADMIN_LOGIN;
        const Config = {};
        return await axios.post(URL, data, Config).then(res => res)
    } catch (err) {
        toast.error(err?.response?.data?.exception)
        console.log("error in login==>", err)
    }
}