import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,Select,MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseIcon from '@mui/icons-material/Close';

const LanguageAdd = ({ open, onClose, handleSubmit }) => {
  const [vettedSkills, setVettedSkills] = useState([{ languageName: '', languageLevel: '' }]); // Initial state with one empty object

  // Function to handle adding new vetted skill field
  const handleAddMore = () => {
    setVettedSkills([...vettedSkills, { languageName: '', languageLevel: '' }]); // Adds an empty object to the vettedSkills array
  };

  // Function to handle input change for skill and experience fields
  const handleInputChange = (index, field, value) => {
    const updatedVettedSkills = [...vettedSkills];
    updatedVettedSkills[index][field] = value;
    setVettedSkills(updatedVettedSkills);
  };

  // Function to handle removing a vetted skill field
  const handleRemoveSkill = (index) => {
    const updatedVettedSkills = vettedSkills.filter((_, idx) => idx !== index);
    setVettedSkills(updatedVettedSkills);
  };

  // Function to handle saving vetted skills
  const handleSave = () => {
    // Filter out objects with empty fields
    const validVettedSkills = vettedSkills.filter(
      (item) => item.languageName.trim() !== '' && item.languageLevel.trim() !== ''
    );
    console.log('Saved Vetted Skills:', validVettedSkills); // Save or process the vettedSkills array
    handleSubmit(validVettedSkills);
    setVettedSkills([{ languageName: '', languageLevel: '' }]);
    onClose(); // Close the dialog after saving
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {/* Close Icon */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '8px',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            color: '#062B6E',
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      {/* Title and Add More Button */}
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 0,
        }}
      >
        <span>Add Language</span>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddMore}
          sx={{
            textTransform: 'none',
            color: '#062B6E',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '0.2px',
            borderRadius: '4px',
            border: '1px solid #062B6E',
          }}
        >
          Add More
        </Button>
      </DialogTitle>

      {/* Content with vetted skill fields */}
      <DialogContent dividers={false}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
          {vettedSkills.map((vettedSkill, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginBottom: '8px',
                width: '100%', // Set each field to full width within the row
              }}
            >
              <TextField
                value={vettedSkill.languageName}
                onChange={(e) => handleInputChange(index, 'languageName', e.target.value)}
                variant="outlined"
                size="small"
                placeholder="Language"
                style={{ flex: 1 }} // Make the field flexible within the row
                InputProps={{
                  style: {
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                  },
                }}
              />
               <Select
                    value={vettedSkill?.languageLevel || ''}
                    onChange={(e) => handleInputChange(index, 'languageLevel', e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px' }}
                  >
                    <MenuItem value="Fluent">Fluent</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Advanced">Advanced</MenuItem>
                  </Select>
              <IconButton
                size="small"
                onClick={() => handleRemoveSkill(index)}
                aria-label="remove skill"
                sx={{ color: '#062B6E' }}
                disabled={!vettedSkill.languageName.trim() && !vettedSkill.languageLevel.trim()} // Enable button only if fields are not empty
              >
                <CancelRoundedIcon />
              </IconButton>
            </div>
          ))}
        </div>
      </DialogContent>

      {/* Save Button */}
      <DialogActions
        sx={{
          justifyContent: 'flex-start', // Aligns the button to the left
        }}
      >
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{
            backgroundColor: '#062B6E',
            color: '#FFF',
            marginLeft:'16px',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 500,
            textTransform: 'none',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#062B6E',
            },
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageAdd;
