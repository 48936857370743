import React, { useState } from 'react';
import { Dialog, DialogTitle, TextField, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UpdateButton from '../UpdateButton';

const AchievementsAdd = ({ open, onClose, handleSumbit }) => {
  const [achievementText, setAchievementText] = useState(""); // Text field input

  // Handle the Enter key and bullet points
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      // Add bullet point at the beginning of each new line
      if (achievementText.trim() !== "") {
        setAchievementText((prevText) => prevText + `\n• `);
      }
    }
  };

  // Handle the update button click
  const handleClickUpdate = () => {
    const achievementsArray = achievementText
      .split('\n')
      .map((line) => line.replace(/^•\s*/, "").trim())
      .filter((line) => line !== "");

    handleSumbit(achievementsArray); // Submit achievements as an array of strings
    setAchievementText(""); // Clear input after submit
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth sx={{ padding: '20px' }}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 0,
        }}
      >
        <span>Add Achievements</span>
        <IconButton onClick={onClose} sx={{ color: '#062B6E' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
        <TextField
          placeholder="Type achievement and press Enter"
          value={achievementText || '• '}
          onChange={(e) => setAchievementText(e.target.value)}
          onKeyDown={handleKeyDown}
          variant="outlined"
          multiline
          fullWidth
          rows={6} // Increased height for more text space
          InputProps={{
            style: {
              fontFamily: "Poppins",
              fontSize: "14px",
              lineHeight: "normal",
              letterSpacing: "0.2px",
            },
          }}
        />

        <UpdateButton handleClickUpdateButton={handleClickUpdate} />
      </div>
    </Dialog>
  );
};

export default AchievementsAdd;
