import React, { useState } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import styles from "../VettedSkilsEdit/VettedSkillsEdit.module.scss";
import UpdateButton from '../UpdateButton';
import LanguageAdd from './LanguageAdd';
import DeleteIcon from "../../../../assets/svgs/delete.svg"; // Import custom delete SVG

const LanguageEdit = ({ vettedSkills, handleClickUpdate }) => {
  const [skillsData, setSkillsData] = useState(vettedSkills);
  const [openVettedAdd, setOpenVettedAdd] = useState(false);

  // Handle input changes for skills and experience
  const handleInputChange = (index, field, value) => {
    const updatedSkills = skillsData.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setSkillsData(updatedSkills);
  };

  // Handle delete skill
  const handleDeleteSkill = (index) => {
    const updatedSkills = skillsData.filter((_, i) => i !== index);
    setSkillsData(updatedSkills);
  };

  // Handle update button click
  const handleClickUpdateButton = () => {
    handleClickUpdate('Languages', skillsData);
  };

  const handleSubmitVettedAdd = (vettedArray) => {
    setSkillsData([...skillsData, ...vettedArray]);
    setOpenVettedAdd(false);
  };

  const handleCloseVettedAdd = () => {
    setOpenVettedAdd(false);
  };

  return (
    <div className={styles.vetted}>
      <LanguageAdd open={openVettedAdd} onClose={handleCloseVettedAdd} handleSubmit={handleSubmitVettedAdd} />
      <TableContainer component={Paper} className={styles['table-container']}>
        <Table>
          <TableHead>
            <TableRow className={styles['table-header-row']}>
              <TableCell sx={{ padding: '0px' }} className={styles['table-header']}>
                Language
              </TableCell>
              <TableCell sx={{ padding: '0px' }} className={styles['table-header']}>
                Level
              </TableCell>
              <TableCell sx={{ padding: '5px' }} className={styles['table-header']} align="right">
                <Button
                  onClick={() => setOpenVettedAdd(true)}
                  variant="outlined"
                  sx={{
                    borderRadius: '4px',         // Border radius
                    border: '1px solid #062B6E', // Border color
                    color: '#062B6E',            // Text color
                    fontFamily: 'Poppins',       // Font family
                    fontSize: '14px',            // Font size
                    fontWeight: 500,             // Font weight
                    letterSpacing: '0.2px',      // Letter spacing
                    textTransform: 'none',       // Disable text transformation
                  }}
                >
                  Add Language
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skillsData?.map((skillItem, index) => (
              <TableRow key={index} className={styles['table-row']}>
                <TableCell className={styles['table-cell']} sx={{ padding: '0px', paddingBottom: '10px' }}>
                  <TextField
                    value={skillItem?.languageName}
                    onChange={(e) => handleInputChange(index, 'languageName', e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px' }}
                  />
                </TableCell>
                <TableCell className={styles['table-cell']} sx={{ padding: '0px', paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
                  <Select
                    value={skillItem?.languageLevel || ''}
                    onChange={(e) => handleInputChange(index, 'languageLevel', e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px', marginRight: '8px' }} // Adds space between Select and Delete icon
                  >
                    <MenuItem value="Fluent">Fluent</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Advanced">Advanced</MenuItem>
                  </Select>
                  <IconButton aria-label="delete" onClick={() => handleDeleteSkill(index)} sx={{ padding: 0 }}>
                    <img src={DeleteIcon} alt="delete" style={{ width: '25px', height: '25px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Update Button */}
      <UpdateButton name="summary" handleClickUpdateButton={handleClickUpdateButton} />
    </div>
  );
};

export default LanguageEdit;
