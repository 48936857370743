import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { candidateProfile } from "../../APIs/candidateProfile";
import Avatar from "@mui/material/Avatar";
import "./CandidateProfile.scss";

export const CandidateProfile = ({ email, handleClose }) => {
  const [candidate, setCandidate] = useState({});
  const getCandidateProfile = async () => {
    const data = { name: email };
    const res = await candidateProfile(data);
    setCandidate({ ...res });
  };

  useEffect(() => {
    getCandidateProfile();
  }, []);
  return (
    <div
      style={{ position: "relative", minHeight: "100px" }}
      className="candidateProfileWrapper"
    >
      <IconButton
        onClick={handleClose}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <CloseIcon />
      </IconButton>
      <div className="title">Candidate Profile</div>
      <div className="basic">
        <div className="avatar">
          {/* dummy avatar in mui */}
          <Avatar
            sx={{
              width: 80,
              height: 80,
              backgroundColor: "gray",
            }}
          />
          <div className="Available">
            <span></span> Available
          </div>
          <div className="availability">{candidate?.availability}</div>
        </div>
        <div className="nameAndDetails">
          <div>{candidate?.candidateName}</div>
          <div>
            Email ID : <span className="email">{candidate?.email}</span>
          </div>
          <div>
            Mobile : <span>{candidate?.mobile}</span>
          </div>
          <div>
            Location : <span>{candidate?.location}</span>
          </div>
          <div>
            Total Experience : <span>{candidate?.totalYearsOfExperience}</span>
          </div>
        </div>
      </div>
      <div className="assessments">
        <div>Assessment Results</div>
        <div className="allRatings">
          <div>Overall</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.overallScore}</span>
          </div>
          <div>Round 1: Technical Fundamentals Test/Quiz (TFT/Q)</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.round1Score}</span>
          </div>
          <div>Round 2: Coding Challenge</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.round2Score}</span>
          </div>

          <div>Round 3: Interpersonal Skills Discussion</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.round3Score}</span>
          </div>

          <div>Round 4: Practical Assignment</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.round4Score}</span>
          </div>

          <div>Round 5: Expert Discussion</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.round5Score}</span>
          </div>
        </div>
      </div>
      <div className="allSkills">
        <div>SKILLS</div>
        <div>
          {candidate?.skills?.map((item, index) => (
            <div key={index} className="skillsDetails">
              {item?.languages?.map((subItem, subIndex) => (
                <div key={subIndex} className="eachItem">
                  <div className="languageAndProf">
                    <div>{subItem?.language}</div>
                    <div>{subItem?.proficiency}</div>
                  </div>

                  <div>
                    {subItem?.frameworks?.map((frameworks, frameIndexes) => (
                      <div key={frameIndexes} className="frameworks">
                        <div>{frameworks?.framework}</div>
                        <div>{frameworks?.proficiency}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
