import { useLocation, useNavigate } from "react-router-dom";
import "./Referrals.scss";
import { useEffect, useState } from "react";
import { TablePages } from "../../Components/common/Pagination_Data/Pagination_Data";

import FilterListIcon from "@mui/icons-material/FilterList";
import { getReferralsList } from "../../APIs/referralsList";
import { TextField } from "@mui/material";

const headersList = [
  "Email ID",
  "User Name",
  "Referral Date",
  "Referred Job ID",
  "User Role",
  "Contact Number",
  "Referred by",
];

export const Referrals = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [page, setPage] = useState(parseInt(queryParams?.get("page")) || 1);

  const [tableData, setTableData] = useState([]);
  const [name, setName] = useState("");
  const [viewFilters, setViewFilters] = useState(false);

  const handlePageChange = (event, value) => {
    setPage(value);
    navigate(`/referrals?page=${value}`);
    // You can perform any other actions here, such as fetching data for the new page.
  };

  const getAllDevList = async () => {
    try {
      const data = {
        // name: sessionStorage.getItem("email"),
        page: page - 1,
        size: 10,
      };
      const res = await getReferralsList(data);
      setTableData([...res]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDevList();
  }, [page]);

  return (
    <div className="referrals-main">
      <div className="title">
        <div>Referrals</div>
      </div>
      <div className="searchAndFilter">
        <SearchDevelopers name={name} setName={setName} />

        <button className="filersButton" onClick={() => setViewFilters(true)}>
          Filters <FilterListIcon />
        </button>
      </div>
      <DisplayTableData tableHeaders={headersList} tableData={tableData} />
      <TablePages page={page} handlePageChange={handlePageChange} />
    </div>
  );
};

const SearchDevelopers = ({ name, setName }) => {
  return (
    <div className="searchMain">
      <TextField
        sx={{ ml: 0, flex: 1, width: 400 }}
        label="Search by email ID"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        InputLabelProps={{
          shrink: !!name,
        }}
      />
    </div>
  );
};

const DisplayTableData = ({ tableHeaders, tableData }) => {
  return (
    <div className="table-container">
      <table className="user-table">
        <thead>
          <tr>
            {tableHeaders?.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((user, index) => (
            <tr key={index}>
              <td>{user?.email || "--"}</td>
              <td>{user?.candidateName || "--"}</td>
              <td>{user?.referredDate || "--"}</td>
              <td>{user?.referredJobId || "--"}</td>
              <td>{user?.role || "--"}</td>
              <td>{user?.mobile || "--"}</td>
              <td>{user?.referrerEmailId || "--"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
