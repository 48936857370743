import React, { useEffect, useRef, useState } from "react";
import "./AssignHR.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Alert, TextField } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TablePages } from "../../Components/common/Pagination_Data/Pagination_Data";
import { getHRAttendeeList, postHRMeetingInvite } from "../../APIs/interview";
import Popup from "../../Components/common/popUp/popUp";
import person from "../../Assessts/SVGs/person.svg";
import close from "../../Assessts/SVGs/Close.svg";
import {
  getTodayDateAsMinDate,
  getTodayTimeAsMinTime,
} from "../../Common/minDate";
import { CommonDialog } from "../../Components/common/Dialog/CommonDialog";
import success from "../../Assessts/SVGs/success.svg";
import { GlobalLoader } from "../../Components/common/GlobalLoader/GlobalLoader";
import { BackButton } from "../../Components/common/BackButton/BackButton";
import useFetchHRList from "../../hooks/useFetchHRList";
import {
  formatDateTime,
  formatTimeSlots,
  formatTimeTo12Hour,
  formatTimeWithoutPeriod,
} from "../../utils/dateUtils";
import { searchUserById } from "../../APIs/getAllUsersDetails";
import meetingError from "../../Assessts/SVGs/meeting-error.svg";
import moment from "moment-timezone";

export const AssignHR = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(loc.search);
  const [page, setPage] = useState(parseInt(queryParams?.get("page")) || 1);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    interviewScheduledDate,
    location,
    candidateName,
    role,
    email,
    contactNumber,
    user,
  } = loc.state || {};
  const headersList = [
    `${user} Name`,
    "Email ID",
    "Contact Number",
    "Location Serviced",
    "Available Time Slots",
  ];
  const { hrList, totalPages } = useFetchHRList(
    interviewScheduledDate,
    location,
    page,
    searchQuery,
    user
  );

  const handleBackButton = () => {
    navigate("/interview");
  };

  const handlePageChange = (e, value) => {
    setPage(value);
    navigate(`/assign-hr?pageNumber=${value}`, { state: loc.state });
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };
  return (
    <div className="assign-hr-main">
      <BackButton
        name={candidateName}
        handleBackButton={handleBackButton}
      ></BackButton>
      <div>
        <div className="title">Assign {user}</div>
        <div className="candidate-detail-card">
          <div className="detail-header">Candidate's Details:</div>
          <div className="detail-body">
            <div className="detail-info">
              <div>Candidate Name</div>
              <div>{candidateName}</div>
            </div>
            <div className="detail-info">
              <div>Job Title</div>
              <div>{role}</div>
            </div>
            <div className="detail-info">
              <div>Email ID</div>
              <div>{email}</div>
            </div>
            <div className="detail-info">
              <div>Contact Number</div>
              <div>{contactNumber}</div>
            </div>
            <div className="detail-info">
              <div>Interview Scheduled Date</div>
              <div>{formatDateTime(interviewScheduledDate)}</div>
            </div>
            <div className="detail-info">
              <div>Location</div>
              <div>{location}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="search-and-filter">
        <SearchHR handleSearch={handleSearch}></SearchHR>
        {/* <button className="filterButton" onClick={() => {}}>
          Filters <FilterListIcon />
        </button> */}
      </div>
      <HRTable
        tableHeaders={headersList}
        tableData={hrList}
        interviewScheduledDate={interviewScheduledDate}
        candidateData={{ name: candidateName, email, user: "developer" }}
      ></HRTable>
      <TablePages
        page={page}
        handlePageChange={handlePageChange}
        totalPages={totalPages}
      />
    </div>
  );
};

const SearchHR = ({ name, handleSearch, filterError }) => {
  return (
    <div className="searchFilterMain">
      <div className="search">
        {/* <SearchIcon></SearchIcon> */}
        <TextField
          sx={{ ml: 0, flex: 1, width: 455, height: 44 }}
          placeholder="Search by email ID"
          variant="outlined"
          value={name}
          onChange={(e) => handleSearch(e.target.value)}
          InputLabelProps={{
            shrink: !!name,
          }}
        />
      </div>
      {filterError && <Alert severity="error">No HR Found!</Alert>}
    </div>
  );
};

const HRTable = ({
  tableHeaders,
  tableData,
  interviewScheduledDate,
  candidateData,
}) => {
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [hrData, setHRData] = useState({});
  const navigate = useNavigate();

  const handleCloseButton = () => {
    setShowInvitePopup(false);
  };

  const handleInviteButton = (data) => {
    setShowInvitePopup(true);
    setHRData({ name: data?.hrName, email: data?.emailId, user: "hr" });
  };

  const getHRInfo = async (email) => {
    const data = {
      adminEmail: sessionStorage.getItem("email"),
      searchEmail: email,
    };
    const user = await searchUserById(data);
    sessionStorage.setItem("edit_user_details", JSON.stringify(user[0]));
    sessionStorage.setItem("edit_user", true);
  };

  const handleClick = async (email) => {
    await getHRInfo(email);
    navigate("/edit-users");
  };

  return (
    <>
      <div className="table-container">
        <table className="hr-table">
          <thead>
            <tr>
              {tableHeaders?.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((data, index) => (
              <tr key={index}>
                <td
                  onClick={() => handleClick(data?.emailId)}
                  style={{ textDecorationLine: "underline", cursor: "pointer" }}
                >
                  {data?.hrName || "--"}
                </td>
                <td>{data?.emailId || "--"}</td>
                <td>{data?.contactNumber || "--"}</td>
                <td>{data?.locationServiced || "--"}</td>
                <td>{formatTimeSlots(data?.availableTimeSlots) || "--"}</td>
                <td>
                  <button
                    className="invite"
                    onClick={() => handleInviteButton(data)}
                  >
                    Invite
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showInvitePopup && (
        <MeetingInvite
          handleCloseButton={handleCloseButton}
          interviewScheduledDate={interviewScheduledDate}
          candidateData={candidateData}
          hrData={hrData}
        ></MeetingInvite>
      )}
    </>
  );
};

const MeetingInvite = ({
  handleCloseButton,
  interviewScheduledDate,
  candidateData,
  hrData,
}) => {
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [minDate, setMinDate] = useState();
  const [minTime, setMinTime] = useState();
  const [attendees, setAttendees] = useState([]);
  const [hrList, setHRList] = useState([]);
  const [meetingNote, setMeetingNote] = useState("");
  const [timeZone, setTimeZone] = useState("Asia/Kolkata");
  const [showDialog, setShowDialog] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [timeZoneOptions, setTimeZoneOptions] = useState([
    "Asia/Kolkata",
    "America/New_York",
  ]);
  const searchAttendeeRef = useRef();
  const navigate = useNavigate();

  const adminData = {
    name: "Zeero Admin",
    email: "admin@zeero.us",
    user: "admin",
  };
  const [dialogData, setDialogData] = useState({});

  const dialogDatas = {
    success: {
      message1: "",
      message2: "Zeero meeting invite has been sent successfully.",
      icon: success,
    },
    conflict: {
      message1: "",
      message2: "The time slot is already booked for another candidate.",
      icon: meetingError,
    },
  };

  const getFormattedTimeZones = () => {
    // Get all time zones from moment-timezone
    const timeZones = moment.tz.names();

    // Create a set to store unique time zone strings
    const uniqueTimeZones = new Set();

    timeZones.forEach((zone) => {
      // Get the current time in the timezone
      // const format = moment.tz(zone).format("Z"); // e.g., "+05:30"

      // Format the string as `Asia/Kolkata GMT+5:30`
      // const formattedTimeZone = `${zone} GMT${format}`;
      const formattedTimeZone = `${zone}`;

      // Add to the set
      uniqueTimeZones.add(formattedTimeZone);
    });

    // Convert the Set to an array and return
    // return [...uniqueTimeZones];

    setTimeZoneOptions([...uniqueTimeZones]);
  };

  useEffect(() => {
    getFormattedTimeZones();
    setInitialData();
  }, []);

  const setInitialData = () => {
    const initialDate = new Date(interviewScheduledDate);
    const formattedEndTime = formatTimeWithoutPeriod(
      new Date(initialDate.getTime() + 45 * 60000)
    );
    setDate(initialDate.toISOString().split("T")[0]);
    setStartTime(formatTimeWithoutPeriod(initialDate));
    setEndTime(formattedEndTime);
    setMinDate(getTodayDateAsMinDate());
    setMinTime(getTodayTimeAsMinTime());
    setAttendees([hrData, candidateData, adminData]);
  };

  const fetchHRAttendeeList = async (query) => {
    return await getHRAttendeeList(query);
  };

  const handleAttendeeSearch = async (query) => {
    try {
      if (query.length < 3) {
        setHRList([]);
        return;
      }

      const res = await fetchHRAttendeeList(query);
      if (Array.isArray(res)) {
        setHRList([...res]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveAttendee = (email) => {
    const finalAttendee = attendees.filter(
      (attendee) => attendee?.email !== email
    );
    setAttendees([...finalAttendee]);
  };

  const handleAttendeeSearchListClick = (hr) => {
    if (hr) {
      const hrData = { name: hr?.fullName, email: hr?.hrEmail, user: "hr" };
      const isHRAlreadyPresent = attendees.find(
        (attendee) => attendee.email === hrData.email
      );
      if (!isHRAlreadyPresent) {
        setAttendees([hrData, ...attendees]);
      }
      setHRList([]);
      searchAttendeeRef.current.value = "";
    }
  };

  const handleSubmitButton = async () => {
    setShowLoader(true);

    const data = {
      startTime: formatTimeTo12Hour(startTime),
      endTime: formatTimeTo12Hour(endTime),
      date: date.split("-").reverse().join("-"),
      timeZone,
      meetingNote,
      emailIds: attendees,
    };
    const res = await postHRMeetingInvite(data);
    setShowLoader(false);
    setShowDialog(true);
    if (res === "Already Booked") {
      setDialogData(dialogDatas.conflict);
    } else {
      setDialogData(dialogDatas.success);
    }
  };

  const handleTimeZoneChange = (e) => {
    setTimeZone(e.target.value);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
    if (
      dialogData.message2 === "Zeero meeting invite has been sent successfully."
    ) {
      navigate("/interview");
    }
  };

  return (
    <Popup>
      <div className="meeting-invite-main">
        <div className="modal-header">
          <div>Zeero Meeting Invite</div>
          <img
            className="close-button"
            src={close}
            onClick={handleCloseButton}
          ></img>
        </div>
        <div className="modal-body">
          <div className="date-time">
            <div className="scheduled-date">
              <label>Scheduled Date</label>
              <input
                type="date"
                id="scheduled-date"
                value={date}
                min={minDate}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="scheduled-time">
              <label>Time Slot</label>
              <div className="time-slot">
                <input
                  type="time"
                  id="start-time"
                  value={startTime}
                  min={minTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
                <span>to</span>
                <input
                  type="time"
                  id="end-time"
                  value={endTime}
                  min={minTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="attendees-main">
            <div>
              <label>Attendees</label>
              <div className="attendees">
                <input
                  type="text"
                  ref={searchAttendeeRef}
                  id="attendees"
                  placeholder="Add attendee"
                  onChange={(e) => handleAttendeeSearch(e.target.value)}
                />
                <img src={person}></img>
                {hrList.length > 0 ? (
                  <div className="attendees-search-list">
                    {hrList.map((hr) => {
                      return (
                        <div
                          key={hr?.hrEmail}
                          className="attendee-info"
                          onClick={() => handleAttendeeSearchListClick(hr)}
                        >
                          <div className="attendee-name">{hr?.fullName}</div>
                          <div className="attendee-email">{hr?.hrEmail}</div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="timezone">
              {/* TODO :- have to use customdropdown component*/}
              {/* <label>Time Zone</label> */}
              {/* <CustomDropDown
                            label="Time Zone"
                            options={timeZoneOptions}
                            handleChange={() => handleChange()}
                            value={timeZone}
                        /> */}
              <label className="timezone-label">Time Zone</label>
              <select
                className="timezone-select-value"
                value={timeZone}
                onChange={handleTimeZoneChange}
              >
                {timeZoneOptions.map((timezone, idx) => (
                  <option key={idx} value={timezone}>
                    {timezone}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="existing-attendees">
            {attendees.map((attendee) => (
              <span key={attendee?.email} className="attendee">
                {attendee?.name}{" "}
                {attendee.user === "hr" ? (
                  <img
                    src={close}
                    onClick={() => handleRemoveAttendee(attendee?.email)}
                  ></img>
                ) : (
                  <></>
                )}
              </span>
            ))}
          </div>
          <div className="meeting-note">
            <label>Meeting note</label>
            <textarea
              id="meeting-note"
              placeholder="Message..."
              onChange={(e) => setMeetingNote(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="modal-footer">
          <button className="cancel-button" onClick={handleCloseButton}>
            Cancel
          </button>
          <button className="send-button" onClick={handleSubmitButton}>
            Send
          </button>
        </div>
        {showDialog && (
          <CommonDialog
            buttonsList={{
              Okay: {
                name: "Okay",
                action: handleDialogClose,
              },
              Close: {
                name: "Close",
                action: handleDialogClose,
              },
            }}
            data={dialogData}
          ></CommonDialog>
        )}
        {showLoader && <GlobalLoader></GlobalLoader>}
      </div>
    </Popup>
  );
};
