
import React from 'react'
import { Button } from '@mui/material'

const UpdateButton = ({handleClickUpdateButton}) => {

  return (
    <Button
      variant="contained"
      sx={{
        marginTop: '20px',
        width: '109px',
        height: '48px',
        backgroundColor: '#062B6E',
        borderRadius: '8px',
        flexShrink: 0,
        '&:hover': {
          backgroundColor: '#041E52', // Optional: change background on hover
        },
      }}
      onClick={()=>{
        handleClickUpdateButton()
      }}
    >
      Update
    </Button>
  )
}

export default UpdateButton