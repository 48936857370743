import axios from "axios";
import { toast } from "react-toastify";
import { ALL_DEV_LIST, FILTERS_DEV_LIST, SEARCH_DEV_BY_ID } from "./URLS";
import axiosInstance from "./axiosInstance";

export const getAllDevListData = async (data) => {
  try {
    const endPoint =
      ALL_DEV_LIST + `?pageNumber=${data?.page}&pageSize=${data?.size}`;
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };

    return await axiosInstance.get(endPoint, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getAllDevListData:", err);
    if (err.response) {
      toast.error(err.response.data.exception);
    } else {
      toast.error("An error occurred while searching dev details.");
    }
    throw err;
  }
};

export const searchDevById = async (data) => {
  try {
    const endPoint = SEARCH_DEV_BY_ID;
    const config = {
      // headers: {
      //     Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
      // }
    };
    return await axios.post(endPoint, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in searchDevById", err);
    if (err.response) {
      toast.error(err.response.data.exception);
    } else {
      toast.error("An error occurred while searching dev details.");
    }
    throw err;
  }
};

export const filterDevList = async (data, pageData, sendPageData = true) => {
  try {
    let endPoint = FILTERS_DEV_LIST;
    if (sendPageData) {
      endPoint =
        FILTERS_DEV_LIST +
        `?pageNumber=${pageData?.page}&pageSize=${pageData?.size}`;
    }
    const config = {
      // headers: {
      //     Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
      // }
    };

    const formattedData = {
      ...data,
      creationDate: formatDate(data.creationDate),
    };

    return await axios
      .post(endPoint, formattedData, config)
      .then((res) => res?.data);
  } catch (err) {
    console.error("Error in filterDevList", err);
    if (err.response) {
      toast.error(err.response.data.exception);
    } else {
      toast.error("An error occurred while searching dev details.");
    }
    throw err;
  }
};

const formatDate = (dateString) => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  const options = { day: "numeric", month: "short", year: "numeric" };
  let formattedDate = date.toLocaleDateString("en-In", options);

  const day = date.getDate();
  if (day < 10) {
    formattedDate = formattedDate.replace(day, `0${day}`);
  }

  return formattedDate;
};
