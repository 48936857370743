import axios from "axios";
import {
  ADD_EXPERT_RUBRIC_QUESTION,
  ADD_HR_RUBRIC_QUESTION,
  DELETE_EXPERT_RUBRIC_QUESTION,
  DELETE_HR_RUBRIC_QUESTION,
  EXPERT_RUBRIC_QUESTIONS,
  HR_RUBRIC_QUESTIONS,
  UPDATE_EXPERT_RUBRIC_QUESTION,
  UPDATE_HR_RUBRIC_QUESTION,
} from "./URLS";
import axiosInstance from "./axiosInstance";

export const getHRRubricList = async () => {
  try {
    const URL = HR_RUBRIC_QUESTIONS;
    return await axiosInstance.get(URL).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getHRRubricList:", err);
    return null;
  }
};

export const postHRRubricQuestion = async (data) => {
  try {
    const URL = ADD_HR_RUBRIC_QUESTION;
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in postHRRubricQuestion:", err);
    return null;
  }
};

export const deleteHRRubricQuestion = async (data) => {
  try {
    const URL = DELETE_HR_RUBRIC_QUESTION;
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in deleteHRRubricQuestion:", err);
    return null;
  }
};

export const updateHRRubricQuestion = async (data) => {
  try {
    const URL = UPDATE_HR_RUBRIC_QUESTION;
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.put(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in updateHRRubricQuestion:", err);
    return null;
  }
};

export const getExpertRubricList = async () => {
  try {
    const URL = EXPERT_RUBRIC_QUESTIONS;
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.get(URL, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getExpertRubricList:", err);
    return null;
  }
};

export const postExpertRubricQuestion = async (data) => {
  try {
    const URL = ADD_EXPERT_RUBRIC_QUESTION;
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in postExpertRubricQuestion:", err);
    return null;
  }
};

export const deleteExpertRubricQuestion = async (data) => {
  try {
    const URL = DELETE_EXPERT_RUBRIC_QUESTION;
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in deleteExpertRubricQuestion:", err);
    return null;
  }
};

export const updateExpertRubricQuestion = async (data) => {
  try {
    const URL = UPDATE_EXPERT_RUBRIC_QUESTION;
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.put(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in updateExpertRubricQuestion:", err);
    return null;
  }
};
