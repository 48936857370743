import { Button } from '@mui/material';
import React, { useState } from 'react';

import VettedAdd from '../VettedSkilsEdit/VettedAdd';
import WorkExpAdd from '../WorkExpEdit/WorkExpAdd';
import SkillAdd from '../SkillAdd/SkillAdd';
import EducationAdd from '../Education/EducationAdd';
import CertificationAdd from "../CertificationEdit/CertificationAdd";
import ReccomandationAdd from '../Reccomandation/ReccomandationAdd';
import SoftSkillAdd from '../SoftSkills/SoftSkillAdd';
import LanguageAdd from '../Language/LanguageAdd';
import InterestAdd from '../Interests/InterestsAdd';

const NoDataScreen = ({ header, handleClickUpdate }) => {
  const [openForm, setOpenForm] = useState({
    "Vetted Technical Skills": false,
    "Work Experience & Project Highlights": false,
    "Skills & Technologies": false,
    "Education": false,
    "Certification & Achievements": false,
    "Recommendations/Testimonials": false,
    "Soft Skills": false,
    "Languages": false,
    "Interests": false,
  });

  // Open form based on the header
  const handleOpenForm = () => {
    setOpenForm((prev) => ({
      ...prev,
      [header]: true, // Set the relevant form to true based on header
    }));
  };

  // Close form based on the header
  const handleCloseWorkAdd = () => {
    setOpenForm((prev) => ({
      ...prev,
      [header]: false, // Set the relevant form to false based on header
    }));
  };

  const handleAddWorkSubmit = (newData) => {
    if(header==="Vetted Technical Skills"){
        const validSkillsData = newData.filter((item) => item.skill);
    if (validSkillsData.length > 0) {
      handleClickUpdate('Vetted Technical Skills', validSkillsData,true);
    }
    }

    if(header==="Work Experience & Project Highlights"){
        handleClickUpdate("Work Experience & Project Highlights", [newData],true);
    }

    if(header==="Skills & Technologies"){
        if (newData && newData.length > 0) {
            // Transform each string into an object with the format { name: "<string>" }
            const formattedSkills = newData.map((skill) => ({ name: skill }));
        
            // Update the skills state with the new skills
            handleClickUpdate("Skills & Technologies", formattedSkills,true);
          }
    }
    
    if(header==="Education"){
        handleClickUpdate("Education", [newData],true);
    }

    if(header==="Recommendations/Testimonials"){
        handleClickUpdate("Recommendations/Testimonials", [newData],true);
    }

    if(header==="Soft Skills"){
        handleClickUpdate("Soft Skills", newData,true);
    }

    if(header==="Languages"){
        handleClickUpdate("Languages", newData,true);
    }

    if(header==="Interests"){
        handleClickUpdate("Interests", newData,true);
    }




    
   // handleClickUpdate(header, newData);
    handleCloseWorkAdd(); // Close the form after submission
  };

  return (
    <>
      {/* Render the correct form based on the header */}
      {header === "Vetted Technical Skills" && (
        <VettedAdd open={openForm[header]} onClose={handleCloseWorkAdd} handleSubmit={handleAddWorkSubmit} />
      )}
      {header === "Work Experience & Project Highlights" && (
        <WorkExpAdd open={openForm[header]} onClose={handleCloseWorkAdd} handleSubmit={handleAddWorkSubmit} />
      )}
      {header === "Skills & Technologies" && (
        <SkillAdd open={openForm[header]} onClose={handleCloseWorkAdd} handleSubmit={handleAddWorkSubmit} />
      )}
      {header === "Education" && (
        <EducationAdd open={openForm[header]} onClose={handleCloseWorkAdd} handleSumbit={handleAddWorkSubmit} />
      )}
      {header === "Certification & Achievements" && (
        <CertificationAdd open={openForm[header]} onClose={handleCloseWorkAdd} handleSubmit={handleAddWorkSubmit} />
      )}
      {header === "Recommendations/Testimonials" && (
        <ReccomandationAdd open={openForm[header]} onClose={handleCloseWorkAdd} handleSubmit={handleAddWorkSubmit} />
      )}
      {header === "Soft Skills" && (
        <SoftSkillAdd open={openForm[header]} onClose={handleCloseWorkAdd} handleSubmit={handleAddWorkSubmit} />
      )}
      {header === "Languages" && (
        <LanguageAdd open={openForm[header]} onClose={handleCloseWorkAdd} handleSubmit={handleAddWorkSubmit} />
      )}
      {header === "Interests" && (
        <InterestAdd open={openForm[header]} onClose={handleCloseWorkAdd} handleSumbit={handleAddWorkSubmit} />
      )}

      <div style={styles.container}>
        <Button style={styles.addButton} variant='outlined' onClick={handleOpenForm}>
          Add {header}
        </Button>
        <div style={styles.noDataText}>No data to display</div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40vh',
    position: 'relative'
  },
  addButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '8px 16px',
    color: '#062B6E',
    border: '1px solid #062B6E',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: 'Poppins',
    textTransform: 'none',
  },
  noDataText: {
    color: '#93B9FF',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
  }
};

export default NoDataScreen;
