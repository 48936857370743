
import axios from "axios";
import { ACTION_FLAG_USER, FLAG_USER, VIEW_SCREEN_RECORDING, VIEW_VIDEO_RECORDING } from "./URLS";
import axiosInstance from "./axiosInstance";

export const getScreenRecordingList = async (data) => {
    const URL = VIEW_SCREEN_RECORDING;
    let Config = {};
    return await axiosInstance.post(URL, data, Config)
};


export const getScreenRecording = async (data) => {
    const URL = VIEW_SCREEN_RECORDING;
    const Config = {
        responseType: 'blob' // Ensure response type is blob to handle video files
    };
    return await axiosInstance.post(URL, data, Config);
};


export const getVideoRecordingList = async (data) => {
    const URL = VIEW_VIDEO_RECORDING;
    let Config = {};
    return await axiosInstance.post(URL, data, Config)
};


export const getVideoRecording = async (data) => {
    const URL = VIEW_VIDEO_RECORDING;
    const Config = {
        responseType: 'blob' // Ensure response type is blob to handle video files
    };
    return await axiosInstance.post(URL, data, Config);
};

export const actionFlagUser = async (data) => {
    try {
        const URL = ACTION_FLAG_USER;
        let Config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            }
        };
        return await axiosInstance.post(URL, data, Config)
    } catch (err) {
        console.log(err);
        return null;
    }
}