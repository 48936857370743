import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Avatar, IconButton } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";

// import Badge from "@mui/material/Badge";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useState } from "react";
import "./CandidateHeader.scss";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [icon, setIcon] = useState(
    sessionStorage.profiledata != null &&
      JSON.parse(sessionStorage.profiledata).firstName
      ? JSON.parse(sessionStorage.profiledata).firstName[0] +
          JSON.parse(sessionStorage.profiledata).lastName[0]
      : null
  );

  const dropDownContent = [
    {
      title: "Logout",
      function: () => onClickLogout(),
    },
  ];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function onClickLogout() {
    sessionStorage.clear();
    navigate("/");
  }

  return (
    <>
      <Box
        sx={{ flexGrow: 1 }}
        style={{ marginLeft: "170px", width: "calc(100% - 165px)" }}
      >
        <AppBar position="static" style={{ background: "#fff" }}>
          <Toolbar>
            <div style={{ marginLeft: "3vw" }}>
              <a href="/">
                <img
                  // src={"/images/icons/wissda-logo.png"}
                  src={"/images/icons/zeero-logo.svg"}
                  className="wissdalogo"
                />
              </a>{" "}
            </div>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>

            {auth && (
              <>
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  sx={{
                    ml: "30px",
                    "@media (max-width: 600px)": {
                      ml: "10px", // Adjust the left margin for smaller screens
                    },
                    "@media (max-width: 400px)": {
                      ml: "5px", // Further adjust the left margin for even smaller screens
                    },
                  }}
                >
                  {/* <AccountCircle /> */}
                  {icon != null ? (
                    <Avatar sx={{ bgcolor: "#3f51b5" }}>
                      {icon}{" "}
                      {/* Replace 'A' with the letter you want to display */}
                    </Avatar>
                  ) : (
                    <AccountCircle style={{ color: "grey" }} />
                  )}
                </IconButton>
                <Menu
                  sx={{ mt: "25px" }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {dropDownContent.map((item) => (
                    <MenuItem
                      key={item.title}
                      onClick={item.function}
                    >{`${item.title}`}</MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export { Header };
