import axios from "axios";
import {
  HR_LIST,
  HR_INTERVIEW_REQUEST,
  HR_ATTENDEE,
  SCHEDULE_HR_INTERVIEW,
  CANDIDATE_REPORT,
  DOWNLOAD_RESUME,
  EXPERT_INTERVIEW_REQUEST,
  EXPERT_LIST,
  EXPERT_ATTENDEE,
  SCHEDULE_EXPERT_INTERVIEW,
  PROJECT_QUESTION,
  PROJECT_SUBMISSION,
  HR_FILTER_INTERVIEW_REQUEST,
} from "./URLS";
import axiosInstance from "./axiosInstance";

export const getHRInterviewRequest = async (data, page) => {
  try {
    const URL = HR_INTERVIEW_REQUEST + `?pageNumber=${page - 1}`;

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getHRInterviewRequest:", err);
    return null;
  }
};

export const getFilterHRInterviewRequest = async (queryParams) => {
  try {
    const URL = HR_FILTER_INTERVIEW_REQUEST ;

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
      params: queryParams
    };
    return await axiosInstance.get(URL, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getHRInterviewRequest:", err);
    return null;
  }
};

export const getExpertInterviewRequest = async (data, page) => {
  try {
    const URL = EXPERT_INTERVIEW_REQUEST + `?pageNumber=${page - 1}`;

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getExpertInterviewRequest:", err);
    return null;
  }
};

export const getHRList = async (data, page) => {
  try {
    const URL = HR_LIST + `?pageNumber=${page - 1}`;

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getHRRequest:", err);
    return null;
  }
};

export const getExpertList = async (data, page) => {
  try {
    const URL = EXPERT_LIST + `?pageNumber=${page - 1}`;
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getHRRequest:", err);
    return null;
  }
};

export const getHRAttendeeList = async (query) => {
  try {
    const URL = HR_ATTENDEE + `?searchEmail=${query}`;

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.get(URL, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getHRAttendeeList:", err);
    return null;
  }
};

export const getExpertAttendeeList = async (query) => {
  try {
    const URL = EXPERT_ATTENDEE + `?searchEmail=${query}`;

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.get(URL, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getExpertAttendeeList:", err);
    return null;
  }
};

export const postHRMeetingInvite = async (data) => {
  try {
    const URL = SCHEDULE_HR_INTERVIEW;

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in postHRMeetingInvite:", err);
    if (err.response.status === 409) {
      return "Already Booked";
    }
    return null;
  }
};

export const postExpertMeetingInvite = async (data) => {
  try {
    const URL = SCHEDULE_EXPERT_INTERVIEW;

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in postExpertMeetingInvite:", err);
    if (err.response.data.includes("60 minutes")) {
      return "60 Minutes";
    }
    if (err.response.status === 409) {
      return "Already Booked";
    }
    return null;
  }
};

export const getCandidateReport = async (meet_id) => {
  try {
    const URL = CANDIDATE_REPORT + `/${meet_id}`;
    const data = {};
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Add the authorization token to the request headers
      },
    };
    return await axiosInstance.post(URL, data, config).then((res) => res?.data);
  } catch (err) {
    console.error("Error in getCandidateReport:", err);
    return null;
  }
};

export const getCandidateResume = async (data) => {
  const URL = DOWNLOAD_RESUME;

  const config = {
    url: URL,
    method: "POST",
    responseType: "blob", // Specify the response type as 'blob'
    data: data, // Include the request body data
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  return await axiosInstance(config)
    .then((response) => {
      // Create a URL for the file blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      return url;
    })
    .catch((err) => console.log(err));
};

export const getProjectQuestion = async (data) => {
  const URL = PROJECT_QUESTION;

  const config = {
    url: URL,
    method: "POST",
    responseType: "blob", // Specify the response type as 'blob'
    data: data, // Include the request body data
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  return await axiosInstance(config)
    .then((response) => {
      // Create a URL for the file blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      return url;
    })
    .catch((err) => console.log(err));
};

export const getProjectSubmission = async (data) => {
  const URL = PROJECT_SUBMISSION;

  const config = {
    url: URL,
    method: "POST",
    responseType: "blob", // Specify the response type as 'blob'
    data: data, // Include the request body data
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  return await axiosInstance(config)
    .then((response) => {
      // Create a URL for the file blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      return url;
    })
    .catch((err) => console.log(err));
};
