import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./CandidateDetails.scss";
import pdfIcon from "../../Assessts/SVGs/pdfIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  getCandidateReport,
  getCandidateResume,
  getProjectQuestion,
  getProjectSubmission,
} from "../../APIs/interview";
import { handleDownLoadResume } from "../../Common/resumeDownload";
import redFlag from "../../Assessts/SVGs/red-flag.svg";
import grayFlag from "../../Assessts/SVGs/gray-flag.svg";
import downloadIcon from "../../Assessts/SVGs/download.svg";
import { BackButton } from "../../Components/common/BackButton/BackButton";
import { formatDate } from "../../utils/dateUtils";

const CONSTANT_VALUES = {
  TITLE1: "Candidate Details",
  SUBTITLE1: "Personal Details",
  SUBTITLE2: "Technical Skills",
  NAME: "Name",
  EMAIL: "Email",
  MOBILE_NUMBER: "Mobile Number",
  WORK_EXPERIENCE: "Work Experience",
  LANGUAGE_AND_FRAMEWORK_SELECTED: "Language/Framework selected",
  VIEW_REPORT: "View Report",
  VIEW_VIDEO: "View Video",
  DOWNLOAD_ASSIGNMENT_DETAILS: "Download Assignment Details",
  DOWNLOAD_SUBMITTED_ASSIGNMENT: "Download Submitted Assignment",
  ROUND1: "Technical Fundamentals Test",
  ROUND2: "Coding Challenge",
  ROUND3: "HR Assessment",
  ROUND4: "Practical Assignment",
};

export const CandidateDetails = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const [candidateProfile, setCandidateProfile] = useState({});
  const {
    candidateName,
    interviewScheduledDate,
    status,
    active,
    meetingId,
    round1FlaggingStatus = {},
    isFlag,
    round2FlaggingStatus = {},
    user,
  } = loc.state || {};

  useEffect(() => {
    const fetchCandidateProfile = async () => {
      const res = await getCandidateReport(meetingId);
      setCandidateProfile({ ...res });
    };

    fetchCandidateProfile();
  }, [meetingId]);

  const handleBackButton = () => {
    navigate("/interview");
  };

  return (
    <div className="candidate-details-container">
      <BackButton
        name={candidateName}
        handleBackButton={handleBackButton}
      ></BackButton>
      <CandidateInfo
        candidateName={candidateName}
        isFlag={isFlag}
        interviewScheduledDate={interviewScheduledDate}
        round1FlaggingStatus={round1FlaggingStatus}
        round2FlaggingStatus={round2FlaggingStatus}
        active={active}
        status={status}
        user={user}
      />
      <CandidateDetailInfo candidateProfile={candidateProfile} />
      <ReportCardContainer
        candidateProfile={candidateProfile}
        round1FlaggingStatus={round1FlaggingStatus}
        round2FlaggingStatus={round2FlaggingStatus}
        isFlag={isFlag}
        candidateName={candidateName}
        user={user}
      />
    </div>
  );
};

const CandidateInfo = ({
  candidateName,
  isFlag,
  interviewScheduledDate,
  round1FlaggingStatus,
  round2FlaggingStatus,
  active,
  status,
  user,
}) => {
  const navigate = useNavigate();
  const loc = useLocation();
  return (
    <div className="details">
      <div className="title">Details</div>
      <div className="content">
        <div className="info">
          <div className="heading">
            <h5 className="title">{candidateName}</h5>
            {isFlag && (
              <span
                className="flag-user"
                style={{
                  color:
                    isFlag === "admin"
                      ? "#D24D2C"
                      : isFlag === "hr"
                      ? "#868383"
                      : "",
                }}
              >
                <img
                  style={{ width: "24px", height: "24px" }}
                  src={isFlag === "admin" ? redFlag : grayFlag}
                  alt="flag"
                />
                Flag User
              </span>
            )}
          </div>
          <div className="info-item">
            <span className="icon">
              <CalendarMonthIcon />
            </span>
            <span className="text">
              {formatDate(interviewScheduledDate.split(" ")[0])}
            </span>
          </div>
          <div className="info-item">
            <span className="icon">
              <AccessTimeIcon />
            </span>
            <span className="text">
              {/* TODO
              Clean code
            */}
              {interviewScheduledDate.split(" ")[1] +
                " " +
                interviewScheduledDate.split(" ")[2]}{" "}
              (45 minutes)
            </span>
          </div>
          <div className="info-item">
            <span className="icon" style={{ color: "red" }}>
              <InfoOutlinedIcon />
            </span>
            <span className="text2">
              {/* TODO
              Make it Dynamic
            */}
              All times are in (UTC+5:30) Chennai
            </span>
          </div>
          {isFlag && (
            <div className="flag-reason">
              <span
                className="title"
                style={{
                  color: isFlag === "admin" ? "#D24D2C" : "#868383",
                }}
              >
                Flag Reasoning :{" "}
              </span>
              {round1FlaggingStatus?.userFlagged && (
                <div className="reason">
                  <span className="round">Round1 : </span>
                  <span>{round1FlaggingStatus?.flaggedComments}</span>
                </div>
              )}
              {round2FlaggingStatus?.userFlagged && (
                <div className="reason">
                  <span className="round">Round2 : </span>
                  <span>{round2FlaggingStatus?.flaggedComments}</span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="actions">
          {active === "Pending" ? (
            <button
              disabled={status !== "Pending"}
              className={`assign-hr ${status === "Pending" ? "" : "expired"}`}
              onClick={() => {
                navigate("/assign-hr", { state: { ...loc.state } });
              }}
            >
              Assign {user}
            </button>
          ) : active === "Assigned" ? (
            <></>
          ) : (
            <button
              className="assign-hr"
              onClick={() => {
                navigate("/assign-hr", { state: { ...loc.state } });
              }}
            >
              Reassign {user}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const CandidateDetailInfo = ({ candidateProfile }) => {
  const [selectedLAnguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    const handleSelectedLanguages = () => {
      const checkedItems =
        candidateProfile?.developerSkills?.skillsdev
          ?.flatMap((skilldev) => skilldev.languages)
          ?.filter((language) => language.checked)
          ?.map((language) => {
            const frameworks = language?.frameworks
              ?.filter((f) => f?.checked)
              ?.map((f) => f?.framework);
            return frameworks?.length > 0
              ? `${language?.language} | ${frameworks?.join(" | ")}`
              : language?.language;
          }) || [];
      setSelectedLanguages([...checkedItems]);
    };

    handleSelectedLanguages();
  }, [candidateProfile]);

  // const handleDownLoadResume = async () => {
  //   try {
  //     const data = {
  //       id: candidateProfile?.developerResponse?.id,
  //     };
  //     const res = await getCandidateResume(data);
  //     const downloadLink = document.createElement("a");
  //     downloadLink.href = res;
  //     downloadLink.setAttribute("download", "resume.pdf");
  //     downloadLink.click();
  //   } catch (err) {
  //     console.log("handleDownload error ==>", err);
  //   }
  // };

  return (
    <div className="candidate-details">
      <div className="title">{CONSTANT_VALUES?.TITLE1}</div>
      <div className="profile-card">
        <div className="personal-details">
          <h2>{CONSTANT_VALUES?.SUBTITLE1}</h2>
          <p>
            <span> {CONSTANT_VALUES?.NAME} : </span>{" "}
            {candidateProfile?.developerResponse?.firstName}{" "}
            {candidateProfile?.developerResponse?.lastName}
          </p>
          <p>
            <span>{CONSTANT_VALUES?.EMAIL} :</span>
            {candidateProfile?.developerResponse?.id}
          </p>
          <p>
            <span>{CONSTANT_VALUES?.MOBILE_NUMBER} :</span>
            {candidateProfile?.developerResponse?.mobile}
          </p>
          <p>
            <span>{CONSTANT_VALUES?.WORK_EXPERIENCE} :</span>
            {candidateProfile?.developerResponse?.experienceInYears}
          </p>
        </div>
        <div className="technical-skills">
          <h2>{CONSTANT_VALUES?.SUBTITLE2}</h2>
          <p>
            <span>{CONSTANT_VALUES?.LANGUAGE_AND_FRAMEWORK_SELECTED} :</span>
            {selectedLAnguages?.length > 0 ? (
              selectedLAnguages.map((item, index) => (
                <span key={index}>
                  {item}
                  {index < selectedLAnguages?.length - 1 ? " | " : ""}
                </span>
              ))
            ) : (
              <span></span>
            )}
          </p>
          <div
            className="resume"
            onClick={() =>
              handleDownLoadResume(candidateProfile?.developerResponse?.id)
            }
          >
            <span className="resume-icon">
              <img src={pdfIcon} alt="pdf" />
            </span>
            <p className="resume-link">
              {candidateProfile?.developerResponse?.firstName.toLowerCase() +
                "_" +
                candidateProfile?.developerResponse?.lastName.toLowerCase()}
              _resume.pdf
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportCardContainer = ({
  candidateProfile,
  round1FlaggingStatus,
  round2FlaggingStatus,
  isFlag,
  candidateName,
  user,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleViewRecordingClick = (round) => {
    const flaggedInfo =
      round === "round1" ? round1FlaggingStatus : round2FlaggingStatus;
    navigate(
      `/dev-recording/screen?user=${candidateProfile?.developerResponse?.id}&round=${round}`,
      { state: { ...location.state, flaggedInfo, isFlag, candidateName } }
    );
  };

  const handleViewReportClick = (round) => {
    sessionStorage.setItem(
      "view_report_user",
      candidateProfile?.developerResponse?.id
    );
    navigate(`/dev-report?round=${round}`);
  };

  const handleDownloadProjectQuestion = async () => {
    try {
      const data = {
        name: candidateProfile?.developerResponse?.id,
      };
      const res = await getProjectQuestion(data);
      const downloadLink = document.createElement("a");
      downloadLink.href = res;
      downloadLink.setAttribute("download", "question.pdf");
      downloadLink.click();
    } catch (err) {
      console.log("handleDownloadProjectQuestion error ==>", err);
    }
  };

  const handleDownloadProjectSubmission = async () => {
    try {
      const data = {
        name: candidateProfile?.developerResponse?.id,
      };
      const res = await getProjectSubmission(data);
      const downloadLink = document.createElement("a");
      downloadLink.href = res;
      downloadLink.setAttribute("download", "submission.pdf");
      downloadLink.click();
    } catch (err) {
      console.log("handleDownloadProjectSubmission error ==>", err);
    }
  };
  return (
    <div className="report-card-container">
      <div className="title">Previous Round Performance</div>
      <div className="report-card">
        <RoundInfo
          title={CONSTANT_VALUES?.ROUND1}
          score={candidateProfile?.assessmentScoreRating?.round1Rating.toFixed(
            2
          )}
          handleViewReportClick={() => handleViewReportClick("Round 1")}
          handleViewRecordingClick={() => handleViewRecordingClick("round1")}
          round={"Round 1"}
        />
        <RoundInfo
          title={CONSTANT_VALUES?.ROUND2}
          score={candidateProfile?.assessmentScoreRating?.round2Rating.toFixed(
            2
          )}
          handleViewReportClick={() => handleViewReportClick("Round 2")}
          handleViewRecordingClick={() => handleViewRecordingClick("round2")}
          round={"Round 2"}
        />
        {user === "Expert" && (
          <RoundInfo
            title={CONSTANT_VALUES?.ROUND3}
            score={candidateProfile?.assessmentScoreRating?.round3Rating.toFixed(
              2
            )}
            round={"Round 3"}
          />
        )}
        {user === "Expert" && (
          <RoundInfo
            title={CONSTANT_VALUES?.ROUND4}
            round={"Round 4"}
            handleAssignmentDetails={handleDownloadProjectQuestion}
            handleSubmittedAssignment={handleDownloadProjectSubmission}
            direction="column"
          />
        )}
      </div>
    </div>
  );
};

const RoundInfo = ({
  title,
  score,
  handleViewRecordingClick,
  handleViewReportClick,
  handleAssignmentDetails,
  handleSubmittedAssignment,
  round,
  direction = "row",
}) => {
  return (
    <div
      className="round"
      style={{
        flexDirection: direction === "column" ? "column" : "",
        alignItems: direction === "column" ? "" : "center",
      }}
    >
      <div className="round-details">
        <h3>
          <span> {round}: </span> {title}
        </h3>
        {score && (
          <p className="score">
            <strong>{score}</strong>
            /5
          </p>
        )}
      </div>
      {handleViewReportClick && (
        <div className="round-actions-column">
          <button className="button" onClick={handleViewReportClick}>
            <VisibilityOutlinedIcon /> {CONSTANT_VALUES?.VIEW_REPORT}
          </button>
          <button className="button" onClick={handleViewRecordingClick}>
            <VisibilityOutlinedIcon />
            {CONSTANT_VALUES?.VIEW_VIDEO}
          </button>
        </div>
      )}
      {handleAssignmentDetails && (
        <div className="round-actions-row">
          <button className="button" onClick={handleAssignmentDetails}>
            <img src={downloadIcon} alt="download-icon"></img>{" "}
            {CONSTANT_VALUES?.DOWNLOAD_ASSIGNMENT_DETAILS}
          </button>
          <button className="button" onClick={handleSubmittedAssignment}>
            <img src={downloadIcon} alt="download-icon"></img>{" "}
            {CONSTANT_VALUES?.DOWNLOAD_SUBMITTED_ASSIGNMENT}
          </button>
        </div>
      )}
    </div>
  );
};
