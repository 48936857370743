import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";

const LoadingOverlay = ({ loading, zIndex }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex:
          zIndex !== undefined ? zIndex : (theme) => theme.zIndex.drawer + 1,
      }}
      open={loading}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress color="inherit" />
        <Box mt={2}>Loading...</Box>
      </Box>
    </Backdrop>
  );
};

export default LoadingOverlay;
