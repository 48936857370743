import React, { useState, useEffect, useRef } from "react";
import { TextField, Button ,IconButton, Stack} from "@mui/material";
import UpdateButton from "../UpdateButton";
import ReccomandationAdd from "./ReccomandationAdd";
import DeleteIcon from "../../../../assets/svgs/delete.svg"; // Import custom delete SVG

const ReccomandationsEdit = ({ workData, handleClickUpdate }) => {
  const [workExperiences, setWorkExperiences] = useState(workData);
  const [openReccomdationAdd, setOpenReccomdationAdd] = useState(false);


  // Function to update fields based on index and field name
  const handleFieldChange = (index, field, value) => {
    const updatedExperiences = workExperiences.map((work, idx) =>
      idx === index ? { ...work, [field]: value } : work
    );
    setWorkExperiences(updatedExperiences);
  };

  const handleClickUpdateButton = () => {
    handleClickUpdate("Recommendations/Testimonials", workExperiences);
  };

  const handleAddReccomdation = (newWork) => {
    // Check if newWork is not an empty object
    if (Object.keys(newWork).length > 0) {
      setWorkExperiences([...workExperiences, newWork]);
    }
    setOpenReccomdationAdd(false)
  };
  
  const handleCloseReccomdationAdd=()=>{
    setOpenReccomdationAdd(false);
  }

  const handleDeleteReccomandation = (index) => {
    const updatedExperiences = [...workExperiences];
    updatedExperiences.splice(index, 1);
    setWorkExperiences(updatedExperiences);
  };

  return (
    <div>
      {/* Add Work Experience Button */}
      <div style={{ display: "flex", justifyContent: "flex-end", }}>
      <ReccomandationAdd open={openReccomdationAdd} onClose={handleCloseReccomdationAdd} handleSumbit={handleAddReccomdation}/>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpenReccomdationAdd(true)}
          style={{
            color: "#062B6E",
            fontFamily: "Poppins",
            fontSize: "13px",
            fontWeight: 500,
            letterSpacing: "0.2px",
            borderRadius: "4px",
            border: "1px solid #062B6E",
            marginBottom: "16px",
            textTransform: "none",
          }}
        >
          Add Reccomandation
        </Button>
      </div>

      {workExperiences.map((work, index) => (
        <WorkExperienceItemEdit
          key={index}
          work={work}
          index={index}
          onFieldChange={handleFieldChange}
          onDelete={()=>handleDeleteReccomandation(index)}
        />
      ))}
      <UpdateButton name="summary" handleClickUpdateButton={handleClickUpdateButton} />
    </div>
  );
};

const WorkExperienceItemEdit = ({ work, index, onFieldChange,onDelete }) => {
  const textFieldRef = useRef(null);

  // Scroll to the bottom when content changes
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
    }
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px",marginTop:'16px' }}>
      {/* Role Field */}
      <Stack sx={{display:'flex',alignContent:'center',justifyContent:'space-between',flexDirection:'row'}}>
      <TextField
        placeholder="Role"
        value={work.recommenderRole || ""}
        onChange={(e) => onFieldChange(index, "recommenderRole", e.target.value)}
        variant="outlined"
        size="small"
        sx={{
          width: "300px", // Reduced width
          input: {
            color: "#212121",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: "0.2px",
            
          },
        }}
      />
       <IconButton  aria-label="delete" sx={{ padding: 0 }} onClick={onDelete}>
                    <img src={DeleteIcon} alt="delete" style={{ width: '25px', height: '25px' }} />
                  </IconButton>
         </Stack>

      {/* Name Field */}
      <TextField
        placeholder="Name"
        value={work.recommenderName || ""}
        onChange={(e) => onFieldChange(index, "recommenderName", e.target.value)}
        variant="outlined"
        size="small"
        sx={{
          width: "300px", // Reduced width
          input: {
            color: "#4f4f4f",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: "0.2px",
          },
        }}
      />

      {/* Note Field */}
      <TextField
        placeholder="Note"
        value={work.recommendationNote || ""}
        onChange={(e) => onFieldChange(index, "recommendationNote", e.target.value)}
        multiline
        rows={4}
        variant="outlined"
        size="small"
        fullWidth
        sx={{
          marginTop: "8px",
          input: {
            color: "#4F4F4F",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "23px",
            letterSpacing: "0.2px",
          },
        }}
      />
    </div>
  );
};

export default ReccomandationsEdit;
