import { UsersView } from "../../Components/UsersView/UsersView";
import "./NewUser.scss";

export const NewUser = () => {
  return (
    <div>
      <UsersView />
    </div>
  );
};
