import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getExpertInterviewRequest,
  getHRInterviewRequest,
} from "../APIs/interview";
import { toast } from "react-toastify";
import {
  assignedExpertHeadersList,
  assignedHRHeadersList,
  declinedExpertHeadersList,
  declinedHRHeadersList,
  pendingExpertHeadersList,
  pendingHRHeadersList,
} from "../Pages/Interview/Interview";

export const useInterviewRequest = (initialStatus) => {
  const [active, setActive] = useState(initialStatus);
  const [tableHeaders, setTableHeaders] = useState([...pendingHRHeadersList]);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [totalPages, setTotalPages] = useState(0);
  const [user, setUser] = useState("HR");
  const queryParams = new URLSearchParams(location.search);
  const [page, setPage] = useState(
    parseInt(queryParams.get("pageNumber")) || 1
  );
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchRequestData = async () => {
      let data =
        user === "HR"
          ? { hrMeetingStatus: active }
          : { expertMeetingStatus: active };
      if (searchQuery.length > 2) {
        data = { ...data, candidateSearchId: searchQuery };
      }
      const res =
        user === "HR"
          ? await getHRInterviewRequest(data, page)
          : await getExpertInterviewRequest(data, page);
      if (Array.isArray(res?.content)) {
        const updatedData = res?.content.map((d) => {
          if (d?.round1FlaggingStatus || d?.round2FlaggingStatus) {
            if (
              d?.round1FlaggingStatus?.flaggedStatus === "Approved" ||
              d?.round2FlaggingStatus?.flaggedStatus === "Approved"
            ) {
              return { ...d, isFlag: "admin" };
            }
            if (
              d?.round1FlaggingStatus?.flaggedStatus === "Pending" ||
              d?.round2FlaggingStatus.flaggedStatus === "Pending"
            ) {
              return { ...d, isFlag: "hr" };
            }
          }
          return { ...d, isFlag: null };
        });
        setTableData(updatedData);
        setTotalPages(res?.totalPages);
      } else {
        toast.error("Server is down. Please try after some time");
      }
    };

    fetchRequestData();
  }, [active, page, searchQuery, user]);

  useEffect(() => {
    if (user === "Expert") {
      if (active === "Assigned") {
        setTableHeaders(assignedExpertHeadersList);
      }
      if (active === "Declined") {
        setTableHeaders(declinedExpertHeadersList);
      }
      if (active === "Pending") {
        setTableHeaders(pendingExpertHeadersList);
      }
    } else {
      if (active === "Assigned") {
        setTableHeaders(assignedHRHeadersList);
      }
      if (active === "Declined") {
        setTableHeaders(declinedHRHeadersList);
      }
      if (active === "Pending") {
        setTableHeaders(pendingHRHeadersList);
      }
    }
  }, [user]);

  const handleRequestButton = (request, headersList) => {
    setActive(request);
    setPage(1);
    setTableHeaders([...headersList]);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    navigate(`/interview?pageNumber=${value}`);
  };

  return {
    active,
    tableHeaders,
    tableData,
    page,
    handleRequestButton,
    handlePageChange,
    setSearchQuery,
    totalPages,
    user,
    setUser,
    setPage,
    setTableData
  };
};
