import fileErrorDefault from "../Assessts/SVGs/upload-error.svg";
import fileSizeError from "../Assessts/SVGs/fileErrors/fileSize.svg";
import fileFormatError from "../Assessts/SVGs/fileErrors/fileFormat.svg";
import uploadError from "../Assessts/SVGs/fileErrors/uploadError.svg"

export const fileUploadErrors = {
  fileSize: {
    message1: "Upload Error!",
    message2: "File's on a size diet - trim it to 5 MB.",
    icon: fileSizeError,
  },
  fileFormat: {
    message1: "Upload Error!",
    message2: "File's in the wrong outfit—only pdf file is allowed.",
    icon: fileFormatError,
  },
  fileCount: {
    message1: "Upload Error!",
    message2: "Maximum 3 files are allowed",
    icon: fileErrorDefault,
  },
  fileEmpty: {
    message1: "Upload Error!",
    message2: "Uploaded file is empty.",
    icon: fileErrorDefault,
  },
  excelFileFormat : {
    message1: "Upload Error!",
    message2: "Please upload file in the correct format.",
    icon: uploadError
  },
  excelFileSize: {
    message1: "Upload Error!",
    message2: "Please upload file upto 10 MB.",
    icon: uploadError,
  },
  incorrectFileInput :{
    message1: "Upload Error!",
    message2: "There is an issue with the file. Please check again.",
    icon: uploadError
  },
  serverIssue:{
    message1: "Upload Error!",
    message2: "There is an issue with the server. Please try again later.",
    icon: uploadError
  },
  mismatchFile:{
    message1:"Upload Error!",
    message2:"Incorrect file name, please check again.",
    icon: uploadError
  }
};

export const fileUploadErrorsProject = {
  fileSize: {
    message1: "Upload Error!",
    message2: "File's on a size diet - trim it to 10 MB.",
    icon: fileSizeError,
  },
  fileFormat: {
    message1: "Upload Error!",
    message2: "File's in the wrong outfit—only zip file is allowed.",
    icon: fileFormatError,
  },
  fileCount: {
    message1: "Upload Error!",
    message2: "Maximum 3 files are allowed",
    icon: fileErrorDefault,
  },
  fileEmpty: {
    message1: "Upload Error!",
    message2: "Uploaded file is empty",
    icon: fileErrorDefault,
  },
};
