// import { Layout } from "../../Components/Common/Layout/Layout";
import { BackButton } from "../../../Components/common/BackButton/BackButton";
import "./CandidateInfo.scss";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { useNavigate ,useLocation} from "react-router-dom";
import { Accordion } from "../../../Components/common/Accordion/Accordion";
import CircleIcon from "@mui/icons-material/Circle";
import Rating from "@mui/material/Rating";
import bulb from "../../../assets/svgs/bulb.svg";
import { ProgressBar } from "../../../Components/common/ProgressBar/ProgressBar";
import circleWithGreenDot from "../../../assets/svgs/circle-with-green-dot.svg";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useEffect, useState,useRef } from "react";
// import { getCandidateCardInfo ,getCandidateAllInfo, getCandidateAllInfoMask} from "../../Common/candidate";
import { formatDateWithYearAndMonth,calculateTotalExperience,formatDateWithYearAndMonthExp } from "../../../utils/dateUtils";
// import CandidateCardListWithBlur from "../../Components/ClientPage/CandidateCard/CandidateCardListWithBlur/CandidateCardListWithBlur";
import { Button, TextField , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Stack} from "@mui/material";
import AddIcon from '@mui/icons-material/CheckCircleOutline'; 
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import clientImage from "../../../assets/svgs/CandidateInfoIllustration.svg"
import EditIcon from '@mui/icons-material/Edit'; 
import UpdateButton from "./UpdateButton";
import VettedSkillsEdit from "./VettedSkilsEdit/VettedSkillsEdit";
import SkillsAndTechEdit from "./SkillsAndTechEdit/SkillsAndTechEdit"
import WorkExperienceEdit from "./WorkExpEdit/WorkExpEdit";
import styles from './VettedSkilsEdit/VettedSkillsEdit.module.scss';
import EducationEdit from "./Education/EducationEdit";
import InterestEdit from "./Interests/InterestEdit";
import LanguageEdit from "./Language/LanguageEdit";
import SoftSkillsEdit from "./SoftSkills/SoftSkillsEdit"
import ReccomandationsEdit from "./Reccomandation/ReccomandationsEdit";
import { getCandidateAllInfo,getCandidateInfo,saveCandidateInfo } from "../../../APIs/candidateProfile";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
// import { Header } from "../../Components/Header/Header";

import CertificationEdit from "./CertificationEdit/CertificationEdit";
import NoDataScreenCertification from "../CandidateInfo/NoDataScreen/NoDataScreenCertfication"
const initialEditClickedState = [
  { "Vetted Technical Skills": false },
  { "Work Experience & Project Highlights": false },
  { "Skills & Technologies": false },
  { "Education": false },
  { "Certification & Achievements": false },
  { "Recommendations/Testimonials": false },
  { "Soft Skills": false },
  { "Languages": false },
  { "Interests": false },
];
const CandidateInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userPaidStatus,setUserPaidStatus]=useState(false)
  const [openSnackbar,setSnackbarOpen]=useState(false)
  const handleBackButton = () => {
    navigate("/dashboard");
  };
  const [candidateEmail, setCandidateEmail] = useState();
  const [candidateCardInfo, setCandidateCardIndo] = useState({});
  const [candidateInformation, setCandidateInformation] = useState({});
  const [shortlisted,setShortlisted]=useState(false);

  // spaace purchase user or not
  useEffect(()=>{
    const apicall=async()=>{
      const obj={
        id:localStorage.getItem("email")
      }
      //  const status=await  checkUserPaidStatus(obj);
      //  if(!status){
      //   fetchCandidateCardInfoMask()
      //  }else{
      //   fetchCandidateCardInfo();
      //  }
       fetchCandidateCardInfo();
      
      // setShortlisted(location.state.shortlist);
      //  setUserPaidStatus(status);
    }
    apicall()
    
    },[])

  
  const fetchCandidateCardInfo = async () => {
    const data = {
      name: location.state.email,
      email:localStorage.getItem("email")
    };
   const res= await getCandidateInfo(data);
   const response=await getCandidateAllInfo(data)
    if (res) {
      setCandidateCardIndo(res);
    }
    if(response){
      setCandidateInformation(response)
    }
    // setCandidateInformation(
    //   {
    //     email:'pivipo.usomif@gotgel.org',
    //     "workExperience": [
    //       {
    //         "company": "XYZ Corporation",
    //         "location": "New York",
    //         "position": "Backend Developer",
    //         "startDate": "03/2024",
    //         "endDate": "present",
    //         "projects": [
    //           {
    //             "projectName": "New Project",
    //             "projectSummary": "Developed a new feature.",
    //             "responsibilities": [
    //               "Developed and maintained dynamic web applications using JavaScript, Next.js, and AngularJS",
    //               "Implemented responsive design to ensure applications work seamlessly on different devices",
    //               "Designed user-friendly interfaces with a focus on performance and usability",
    //               "Collaborated with backend developers to integrate APIs and enhance web functionality",
    //               "Optimized frontend code, improving load times by 20%"
    //             ],
    //           "techStack": [
    //           "JavaScript",
    //           "Next.js",
    //           "AngularJS",
    //           "HTML/CSS",
    //           "TypeScript"
    //             ]
    //           },
    //           {
    //             "projectName": "New Project with Single Flow",
    //             "projectSummary": "Developed a new feature with some modifications.",
    //             "responsibilities": [
    //               "Integrated payment APIs",
    //               "Enhanced database performance"
    //             ],
    //           "techStack": [
    //           "JavaScript",
    //           "Next.js",
    //           "AngularJS",
    //           "HTML/CSS",
    //           "TypeScript"
    //            ]
    //           }
    //         ]
       
    //       },
    //       {
    //         "company": "ABC Corporation",
    //         "location": "New York",
    //         "position": "Backend Developer",
    //         "startDate": "03/2024",
    //         "endDate": "present",
    //         "projects": [
    //           {
    //             "projectName": "New Project with Single Flow with updation",
    //             "projectSummary": "Developed a new feature.",
    //             "responsibilities": [
    //               "Developed and maintained dynamic web applications using JavaScript, Next.js, and AngularJS",
    //               "Implemented responsive design to ensure applications work seamlessly on different devices"
    //             ],
    //           "techStack": [
    //           "JavaScript",
    //           "Next.js",
    //           "AngularJS"
    //            ]
    //           }
    //         ]
       
    //       },
    //       {
    //         "company": "ABC Corporation",
    //         "location": "New York",
    //         "position": "Backend Developer",
    //         "startDate": "03/2024",
    //         "endDate": "present",
    //         "projects": [
    //           {
    //             "projectName": "New Project with Single Flow with updation",
    //             "projectSummary": "Developed a new feature.",
    //             "responsibilities": [
    //               "Developed and maintained dynamic web applications using JavaScript, Next.js, and AngularJS",
    //               "Implemented responsive design to ensure applications work seamlessly on different devices"
    //             ],
    //           "techStack": [
    //           "JavaScript",
    //           "Next.js",
    //           "AngularJS"
    //         ]
    //           }
    //         ]
    //       }
    //     ],
    //     "projectHighlights": [
    //       {
    //         "projectName": "AI-based Search System",
    //         "duration": "06/2021 - 12/2021",
    //         "responsibilities": [
    //           "Implemented AI algorithms to enhance search functionality",
    //           "Developed the front-end interface using ReactJS"
    //         ],
    //         "techStack": ["Java", "Spring Boot", "ReactJS"]
    //       }
    //     ],
    //     "skillsAndTechnologies": [
    //       {
    //         "name": "Next.js"
    //       },
    //       {
    //         "name": "AngularJS"
    //       },
    //       {
    //         "name": "HTML/CSS"
    //       },
    //       {
    //         "name": "JavaScript"
    //       },
    //       {
    //         "name": "TypeScript"
    //       },
    //       {
    //         "name": "Git"
    //       },
    //       {
    //         "name": "Jenkins"
    //       },
    //       {
    //         "name": "Docker"
    //       }
    //     ],
    //     "education": [
    //       {
    //         "degree": "B-Tech in Information Technology",
    //         "institution": "YRS University",
    //         "startDate": "07/2018",
    //         "endDate": "07/2022",
    //         "location": "Delhi, India",
    //         "CGPA": "8.3",
    //         "percentage": "96%"
    //       },
    //       {
    //         "degree": "Bachelor of Science in Computer Science",
    //         "institution": "ABC University",
    //         "startDate": "09/2015",
    //         "endDate": "06/2019",
    //         "location": "New York",
    //         "CGPA": "3.8",
    //         "percentage": "90%"
    //       }
    //     ],
    //     "certificationAndAchievements": [
    //       {
    //         "certifications": [
    //           {
    //             "name": "Certified Kubernetes Administrator",
    //             "organization": "CNCG",
    //             "startDate": "2023-03-01",
    //             "endDate": "2025-03-01",
    //             "location": "Online"
    //           },
    //           {
    //             "name": "AWS Certified Solutions Architect",
    //             "organization": "Amazon",
    //             "startDate": "2022-01-01",
    //             "endDate": "2024-01-01",
    //             "location": "Online"
    //           }
    //         ],
    //         "achievements": [
    //           "Spearheaded the development of a reusable component library, reducing frontend development time by 25%",
    //           "Achieved top performance in optimizing application load times across multiple platforms"
    //         ]
    //       }
    //     ],
    //     "recommendations": [
    //       {
    //         "recommenderRole": "Team Lead",
    //         "recommenderName": "John Doe",
    //         "recommendationNote": "Highly proactive and results-driven."
    //       }
    //     ],
    //     "softSkills": [
    //       {
    //         "softSkillName": "Problem Solving",
    //         "softSkillLevel": "Expert"
    //       }
    //     ],
    //     "languages": [
    //       {
    //         "languageName": "English",
    //         "languageLevel": "Fluent"
    //       },
    //       {
    //         "languageName": "French",
    //         "languageLevel": "Advanced"
    //       },
    //       {
    //         "languageName": "Hindi",
    //         "languageLevel": "Intermediate"
    //       }
    //     ],
    //     "interests": [
    //       "Photography",
    //       "Cooking"
    //     ],
    //     "industryDomain": "Technology",
    //     "executiveSummary": "Test Swati is an experienced Frontend Developer with a solid educational background, holding a B-Tech in Information Technology from ABC Engineering College, where they achieved a commendable CGPA of 8.3. Although specific experience durations and company details are not provided, Swati has demonstrated proficiency in a range of frontend technologies and tools including Next.js, AngularJS, HTML/CSS, JavaScript, TypeScript, Git, Jenkins, and Docker. In their role as a Frontend Developer, Swati has developed and maintained dynamic web applications using JavaScript, Next.js, and AngularJS. They have a keen eye for responsive design, ensuring seamless application performance across various devices. Swati is adept at creating user-friendly interfaces with a strong focus on performance and usability, as evidenced by their successful optimization of frontend code that improved load times by 20%. Additionally, they have collaborated effectively with backend developers to integrate APIs and enhance web functionality. Swati’s technical acumen and collaborative approach make them a valuable asset in the development of high-performance, user-centric web applications.",
    //     "developerName": "Test Swati",
    //     "role": "Frontend Developer",
    //     "vettedTechnicalSkills": [
    //       {
    //         "skill": "JavaScript",
    //         "experience": "5 years"
    //       },
    //       {
    //         "skill": "Spring Boot",
    //         "experience": "3 years"
    //       },
    //       {
    //         "skill": "Java",
    //         "experience": "5 years"
    //       }
    //     ],
       
    //   }

    // )
  };

  const fetchCandidateCardInfoMask = async () => {
    const data = {
      name: location.state.email,
      email:localStorage.getItem("email")
    };
    // const res= await getCandidateCardInfo(data);
    // const response=await getCandidateAllInfoMask(data)
    // if (res) {
    //   setCandidateCardIndo(res);
    // }
    // if(response){
    //   setCandidateInformation(response);
    // }
  };



  useEffect(() => {
    // fetchCandidateCardInfo();
    // setShortlisted(location.state.shortlist);
  }, []);

  const hasSaved = useRef(false); // Tracks if save has been triggered at least once

  const nameKeyMap = new Map();
   // Initialize the Map outside the function
   nameKeyMap.set("Work Experience & Project Highlights","workExperience")
   nameKeyMap.set("executiveSummary","executiveSummary")
   nameKeyMap.set("Skills & Technologies","skillsAndTechnologies")
   nameKeyMap.set("Recommendations/Testimonials","recommendations");
   nameKeyMap.set("Certification & Achievements","certificationAndAchievements");

  const handleHitSave = (name, content) => {
    // Check if the camelCase key for this name already exists in the Map
    let camelCaseName;
    if (nameKeyMap.has(name)) {
      camelCaseName = nameKeyMap.get(name);
    } else {
      // Convert name to camelCase if it's not in the Map
      camelCaseName = name
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
          index === 0 ? word : word.toUpperCase()
        )
        .replace(/\s+/g, "");
  
      // Store the original name and camelCase key in the Map
      nameKeyMap.set(name, camelCaseName);
    }
  
    // Update the state with the camelCase name as the key
    setCandidateInformation((prevInfo) => ({
      ...prevInfo,
      [camelCaseName]: content
    }));
  
    
    hasSaved.current = true; // Set the flag to true once save is triggered
  };
  
  
  // useEffect to call saveCandidateInfo only after handleHitSave has been called
  useEffect(() => {
    if (hasSaved.current) {
      const apicall=async()=>{
        console.log("Updated Key:", candidateInformation);
      const res=await saveCandidateInfo(candidateInformation);
      hasSaved.current = false; // Reset flag after saving
      setCandidateInformation(res)
      setSnackbarOpen(true);
      }
      apicall()

    }
  }, [candidateInformation]);
  const handleSnackbarClose = (event, reason) => {
    // Prevent closing if clicked outside
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false); // Close the Snackbar
  };

  return (
    // <Layout>
    <>
      
      <div className="candidate-info-main">
       
       <Snackbar
        open={openSnackbar}
        autoHideDuration={4000} // 4 seconds
        onClose={handleSnackbarClose}
        message="Details Updated Successfully"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position at top-right
        ContentProps={{
          sx: {
            backgroundColor: 'rgba(6, 43, 110, 1)', // Custom background color
            color: '#ffffff', // Text color
          },
        }}
      />
        <BackButton
          handleBackButton={handleBackButton}
        />
        <div className="candidate-info-body">
          <CandidateInfoVerticalCard candidateCardInfo={candidateCardInfo} shortlisted={shortlisted} />
          <CandidateDetailInfo  candidateInformation={candidateInformation} userPaidStatus={userPaidStatus} handleHitSave={handleHitSave}/>
        </div>
      </div>
      </>
    // </Layout>
  );
};

const CandidateInfoVerticalCard = ({ candidateCardInfo ,shortlisted}) => {
  const {
    name,
    skill,
    state,
    country,
    experienceInYears,
    industryDomain,
    availability,
    preferredJobType,
    allSkills,
  } = candidateCardInfo;

  const maskName = (fullName) => {
    // Split the name into parts (assumes "First Last" format)
   
    const nameParts = fullName?.split(' ') || "Candidate";
  
    if (nameParts?.length < 2) {
      return fullName; // If there's no space (i.e., single name), return the full name unmasked
    }
  
    const firstName = nameParts[0];
    const lastName = nameParts[1];
  
    // Mask first name, show first letter, then asterisks
    const maskedFirstName = `${firstName[0]}${'*'.repeat(firstName.length - 1)}`;
  
    // Mask last name, show first letter, then asterisks
    const maskedLastName = `${lastName[0]}${'*'.repeat(lastName.length - 1)}`;
  
    // Combine the masked parts
    return `${maskedFirstName} ${maskedLastName}`;
  };

 
  return (
    <div className="candidate-info-left">
      <img
        className="candidate-image"
        // src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        src={clientImage}
        alt="candidate-image"
      ></img>
      <div className="name">{name}</div>
      <div className="role">{skill}</div>
      <div className="location">
        <LocationOnOutlinedIcon style={{ color: "#0B52D4" }} />
        <span>
          {state}, {country}
        </span>
      </div>
      <div className="experience">
        <WorkOutlineOutlinedIcon style={{ color: "#0B52D4" }} />
        <span>{experienceInYears}</span>
      </div>
      <div className="domain">
        <BusinessOutlinedIcon style={{ color: "#0B52D4" }} />
        <span>{industryDomain}</span>
      </div>
      <div className="availability">
        <span>Availability: </span>
        <span>{availability}</span>
      </div>
      {/* <div className="job-type">
        <span>Preferred Job Type: </span>
        <span>{preferredJobType}</span>
      </div> */}
      <div className="technologies">
        {allSkills
          ?.map((skill) => skill.skillName)
          ?.map((skill) => (
            <span>{skill}</span>
          ))}
      </div>
      {/* <button className="hire-button">Hire Archie</button> */}
     
    </div>
  );
};

const CandidateDetailInfo = ({candidateInformation,userPaidStatus,handleHitSave}) => {
  const [editClicked,setEditClicked]=useState(initialEditClickedState)
  const handleClickUpdate = (name, content, flag) => {
    handleHitSave(name, content);
    
    // Only call handleEditClicked if flag is not true (undefined or false)
    if (!flag) {
      handleEditClicked(name);
    }
  };

  const handleAddCertAndAchiev=(header,data)=>{
    if(header==="certifications"){
      const content= [{ certifications: [data], achievements: candidateInformation?.certificationAndAchievements[0]?.achievements || [] }]
      handleHitSave("Certification & Achievements",content);
    }else{
      const content= [{ certifications: candidateInformation?.certificationAndAchievements[0]?.certifications || [] , achievements: data}]
      handleHitSave("Certification & Achievements",content);
    }


    //handleHitSave(name, content);
  }
  
  const handleEditClicked = (name) => {
    // Map through the array and toggle the specified field
    const updatedEditClicked = editClicked?.map((item) => {
      // Find the object that contains the target key
      if (item.hasOwnProperty(name)) {
        return { [name]: !item[name] }; // Toggle the boolean value
      }
      return item; // Return unchanged item if not matched
    });
    console.log(updatedEditClicked)
    setEditClicked(updatedEditClicked);
  };
  const accordionItems = [
    {
      header: "Vetted Technical Skills",
      Body: () => <VettedTechnicalSkills vettedSkills={candidateInformation?.vettedTechnicalSkills || []} handleClickUpdate={handleClickUpdate} />,
      isEmpty: () => !candidateInformation?.vettedTechnicalSkills?.length,
      BodyEdit:() =><VettedSkillsEdit vettedSkills={candidateInformation?.vettedTechnicalSkills || []} handleClickUpdate={handleClickUpdate} />
    },
    {
      header: "Work Experience & Project Highlights",
      Body: () => (
        <WorkExperience workData={candidateInformation?.workExperience || []} />
      ),
      isEmpty: () => !candidateInformation?.workExperience?.length,
      BodyEdit: () => (
        <WorkExperienceEdit workData={candidateInformation?.workExperience || []} handleClickUpdate={handleClickUpdate} />
      )
    },
    {
      header: "Skills & Technologies",
      Body: () => (
        <Skills skills={candidateInformation?.skillsAndTechnologies || []} />
      ),
      isEmpty: () => !candidateInformation?.skillsAndTechnologies?.length,
      BodyEdit: () => (
        <SkillsAndTechEdit skills={candidateInformation?.skillsAndTechnologies || []}  handleClickUpdate={handleClickUpdate} headerName="Skills & Technologies"/>
      ),
    },
    {
      header: "Education",
      Body: () => <Education education={candidateInformation?.education || []} />,
      isEmpty: () => !candidateInformation?.education?.length,
      BodyEdit: () => (
        <EducationEdit workData={candidateInformation?.education || []}  handleClickUpdate={handleClickUpdate}/>
      ),
    },
    {
      header: "Certification & Achievements",
      Body: () => <Certifications  data={candidateInformation?.certificationAndAchievements?.[0]?.certifications || []} achievements={candidateInformation?.certificationAndAchievements[0]?.achievements || []} handleAddCertAndAchiev={handleAddCertAndAchiev}/>,
     // isEmpty: () => !candidateInformation?.certificationAndAchievements?.[0]?.certifications?.length, // No condition for this one, always display
     isEmpty:()=>false,
      //isEmptyAchievements:()=> !candidateInformation?.certificationAndAchievements?.[0]?.achievements?.length,
      BodyEdit: () => (
        <CertificationEdit workData={candidateInformation?.certificationAndAchievements[0]?.certifications || []}  handleClickUpdate={handleClickUpdate} achievements={candidateInformation?.certificationAndAchievements[0]?.achievements || []}/>
      ),
    },
    {
      header: "Recommendations/Testimonials",
      Body: () => <Recommendations data={candidateInformation?.recommendations || []} />,
      isEmpty: () => !candidateInformation?.recommendations?.length, // No condition for this one, always display
      BodyEdit: () => (
        <ReccomandationsEdit workData={candidateInformation?.recommendations || []} handleClickUpdate={handleClickUpdate} />
      )
    },
    {
      header: "Soft Skills",
      Body: () => <SoftSkills data={candidateInformation?.softSkills || []}/>,
      isEmpty: () => !candidateInformation?.softSkills?.length, // No condition for this one, always display
      BodyEdit: () => (
        <SoftSkillsEdit vettedSkills={candidateInformation?.softSkills || []}  handleClickUpdate={handleClickUpdate} headerName="Soft Skills"/>
      ),
    },
    {
      header: "Languages",
      Body: () => <Languages data={candidateInformation?.languages || []} />,
      isEmpty: () => !candidateInformation?.languages?.length, // No condition for this one, always display
      BodyEdit: () => (
        <LanguageEdit vettedSkills={candidateInformation?.languages || []}  handleClickUpdate={handleClickUpdate} headerName="Language"/>
      ),
    },
    {
      header: "Interests",
      Body: () => (
        <Interests data={candidateInformation?.interests || []} />
      ),
      isEmpty: () => !candidateInformation?.interests?.length,
      BodyEdit: () => (
        <InterestEdit skills={candidateInformation?.interests || []}  handleClickUpdate={handleClickUpdate} headerName="Interests"/>
      ),
    },
  ];
  
  // Filter out empty accordion items based on the isEmpty condition
  const filteredAccordionItems = accordionItems.filter((item) => !item.isEmpty());
  

  return (
    <div className="candidate-info-right">
      <Summary summary={candidateInformation?.executiveSummary || ""} handleClickUpdate={handleClickUpdate} />
      <Accordion
      accordionItems={accordionItems }
      classes={{ main: "accordion-main", header: "header", body: "body" }}
      handleClickUpdate={handleClickUpdate}
      editClicked={editClicked}
      handleEditClicked={handleEditClicked}

    />
    
     
      <div className="share-main">
        <ShareOutlinedIcon className="share" />
        <span className="share-title"> Share with your network</span>
        <div className="social-media">
          <LinkedInIcon className="linkedin" />
          <InstagramIcon className="instagram" />
        </div>
      </div>
      
    </div>
  );
};

const Summary = ({summary,handleClickUpdate}) => {
  const [editClicked,setEditClicked]=useState(false);
  const [editSummary,setEditSummary]=useState(summary || "")
  const handleEditClicked=()=>{
    setEditClicked(true);

  }
  useEffect(()=>{
    if(summary?.length>0){
      setEditSummary(summary)
    }
  },[summary])
  const getDynamicRows = () => {
    const lineBreaks = editSummary.split('\n').length;
    const avgCharsPerRow = 60; // Average number of characters per row (adjustable)
    const wrappedRows = Math.ceil(editSummary.length / avgCharsPerRow);
    return Math.max(lineBreaks, wrappedRows); // Ensure dynamic height based on content
  };
  const handleClickUpdateButton=()=>{
    handleClickUpdate("executiveSummary",editSummary)
    setEditClicked(false)
  }

  const handleInputChange=(e)=>{
    setEditSummary(e?.target?.value)
  }
  return (
    <div className="accordion-main">
      <div className="header">
        <span> Executive Summary
       { !editClicked && <EditIcon
              style={{ marginLeft: '8px', fontSize: '20px', position: 'relative', top: '3px', cursor: 'pointer' }}
              onClick={() => handleEditClicked()}
            />}

        </span>
      </div>
      <div className="body">
        {" "}
       {!editClicked? <div>
         {summary?.length!==0 ?summary:""}
        </div>:
        <>
       <TextField
       value={editSummary}
      onChange={handleInputChange}
       variant="outlined"
       fullWidth
       multiline // Enable multiline input
       rows={getDynamicRows()
       } // Set dynamic rows based on content
     />
     <UpdateButton name="summary" handleClickUpdateButton={handleClickUpdateButton} />
     </>
          }
      </div>
    </div>
  );
};

const VettedTechnicalSkills = ({ vettedSkills }) => {
  return (
    <div className={styles.vetted}>
    <TableContainer component={Paper} className={styles['table-container']}>
      <Table>
        <TableHead>
          <TableRow className={styles['table-header-row']} sx={{padding:'0px'}}>
            <TableCell className={styles['table-header']} sx={{padding:'0px'}}>Skills</TableCell>
            <TableCell className={styles['table-header']} sx={{padding:'0px'}}>Experience</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody sx={{padding:'0px'}}>
          {vettedSkills?.map((skillItem, index) => (
            <TableRow key={index} className={styles['table-row']} sx={{padding:'0px'}}>
              <TableCell sx={{padding:'0px',paddingTop:'10px'}} className={styles['table-cell']}>
              <Typography
  sx={{
    color: '#4F4F4F',
    // textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    padding:'0px'
  }}
>
  {skillItem?.skill}
</Typography>
              </TableCell>
              <TableCell sx={{padding:'0px',paddingTop:'10px'}} className={styles['table-cell']}>
              <Typography
  sx={{
    color: '#4F4F4F',
    // textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
  }}
>
  {skillItem?.experience}
</Typography>
                
              </TableCell>
              <TableCell className={styles['table-cell']} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    {/* Update Button */}
   
  </div>
  );
};

const VettedTechnicalSkillItem = ({ progress, skillItem }) => {
  return (
    <div className="vetted-skills-item">
      <div className="column">{skillItem?.skill}</div>
      <div className="column">{skillItem?.experience}</div>
    </div>
  );
};



const WorkExperience = ({workData}) => {
  return (
    <div className="work-experience-items">
      {
        workData.map((work)=>(
          <WorkExperienceItem  work={work}/>
        ))
      }
    </div>
  );
};

const WorkExperienceItem = ({work}) => {
  return (
    <div className="work-experience-item">
      <div className="item-container">
        <div className="designation-main">
          <div className="designation">
            <div className="circle-with-green-dot">
              <img src={circleWithGreenDot} alt="circle-with-green-dot"></img>
            </div>
            <span>{work?.position || ""}</span>
            <div className="dot">
              <CircleIcon style={{ color: "green", fontSize: "10px" }} />
            </div>
           { (work?.startDate!=="" && work?.endDate!=="")? <span> {formatDateWithYearAndMonth(work?.startDate || "")} - {work?.endDate==="present"?"Present":formatDateWithYearAndMonth(work?.endDate || "")} ({calculateTotalExperience(work?.startDate,work?.endDate || "")})</span> : ""}
          </div>
          <div className="company">{work?.company}</div>
          <div className="location">
          { work?.location && <span>Location: </span>}
            <span>{work?.location}</span>
          </div>
        </div>
        {work?.projects?.map((project,index)=>(
          <>
          <div className="project-main" style={{marginTop:'10px'}}>
          { project?.projectName && <div className="project-header">Project:</div>}
          <div className="project-title">{project?.projectName || ""}</div>
          <div className="project-description">
           {project?.projectSummary || ""}
          </div>
        </div>
        <div className="responsibilities-main">
          {project?.responsibilities?.length>0 && <div className="responsibilities-header">
            Responsibilities & Achievements:
          </div>}
          <ul className="responsibilities-list">
            { project?.responsibilities.map((highligh)=>(
               <li>{highligh}</li>
            ))
             
            }
          </ul>
        </div>
        <div className="tech-stacks">
         { project?.techStack?.length>0 &&  <div className="tech-stack-header">Technologies stack:</div>}
          <div className="tech-stack-list" >
           {project?.techStack?.map((tech)=>(
            <span>{tech}</span>
           ))}
          </div>
        </div>
          </>
        ))}
        
      </div>
    </div>
  );
};

const Skills = ({skills}) => {
  return (
    <div className="skills">
      {
        skills?.map((skill)=>(
          <SkillItem skill={skill} />
        ))
      }
      
    </div>
  );
};

const SkillItem = ({skill}) => {
  return (
    <span className="skill-item">
      <span className="skill">{skill?.name}</span>
      
    </span>
  );
};

const Education = ({education}) => {
  return (
    <div className="education-certifications">
      {
        education.map((item)=>(
          <EducationCertificationItem  education={item}/>
        ))
      }
     
    </div>
  );
};

const Certifications = ({ data, achievements ,handleClickUpdate,handleAddCertAndAchiev}) => {
  // Check if data and achievements are empty
  const hasData = data && data.length > 0;
  const hasAchievements = achievements && achievements.length > 0;

  return (
    <div className="education-certifications">
      {/* Show "No data" if both are empty */}
      {(!hasData || !hasAchievements) && <div><NoDataScreenCertification handleAddCertAndAchiev={handleAddCertAndAchiev}  hasData={hasData} hasAchievements={hasAchievements} /></div>}
      
      {/* Render CertificationItem if data is present */}
      {hasData && data.map((item, index) => (
        <CertificationItem key={index} education={item} />
      ))}

      {/* Render Achievement if achievements are present */}
      {hasAchievements && <Achievement achievements={achievements} />}
    </div>
  );
};


const Achievement=({achievements})=>{
return (

  <div className="responsibilities-main">
          <div className="responsibilities-header">
             Achievements:
          </div>
          <ul className="responsibilities-list">
            { achievements?.map((highligh)=>(
               <li>{highligh}</li>
            ))
             
            }
          </ul>
        </div>
)
}
const EducationCertificationItem = ({education}) => {
  return (
    <div className="education-certification-item">
      <div className="name">
        <span className="title">
          {education?.degree}
        </span>
        <div className="dot">
          <CircleIcon style={{ color: "green", fontSize: "10px" }} />
        </div>
        <span>{formatDateWithYearAndMonth(education?.startDate || "")} - {formatDateWithYearAndMonth(education?.endDate || "")}</span>
      </div>
      <div className="place">{education?.institution}</div>
      <div className="location">
        <span>Location: </span>
        <span>{education?.location}</span>
      </div>
      <div className="location">
        <span>CGPA/Percentage: </span>
        <span>{education?.cgpa || education?.percentage}</span>
      </div>
    </div>
  );
};

const CertificationItem = ({education}) => {
  return (
    <div className="education-certification-item">
      <div className="name">
        <span className="title">
          {education?.name}
        </span>
        { (education?.startDate!=="" && education?.endDate!=="") && <div className="dot">
          <CircleIcon style={{ color: "green", fontSize: "10px" }} />
        </div>}
      {  (education?.startDate!=="" && education?.endDate!=="") && <span>{formatDateWithYearAndMonthExp(education?.startDate || "")} - {formatDateWithYearAndMonthExp(education?.endDate || "")}</span>}
      </div>
      <div className="place">{education?.organization}</div>
      <div className="location">
      { education?.location &&  <span>Location: </span>}
        <span>{education?.location}</span>
      </div>
      
    </div>
  );
};

const Recommendations = ({data}) => {
  return (
    <div className="recommendations">
      {data?.map((item)=>(
         <RecommendationItem recommed={item} />
      ))}
     
      
    </div>
  );
};

const RecommendationItem = ({recommed}) => {
  return (
    <div className="recommendation-item">
      <div className="header">{recommed?.recommenderRole || ""}</div>
      <div className="name">{recommed?.recommenderName || ""}</div>
      <div className="description">
        {recommed?.recommendationNote || ""}
      </div>
    </div>
  );
};

const SoftSkills = ({data}) => {
  return (
    <div className="soft-skills-main">
      <div className="soft-skills-items">
        {
          data?.map((soft)=>(
            <SoftSkillItem progress={80} softSkill={soft}/>
          ))
        }
       
      </div>
      {/* <div className="description">
        Archie excels in problem-solving, quickly identifying issues and
        developing effective solutions. His strong communication skills ensure
        clear and concise information exchange. He efficiently manages time,
        prioritizing tasks to meet deadlines. As a leader, he inspires and
        motivate his team, fostering a collaborative environment.
      </div> */}
    </div>
  );
};

const SoftSkillItem = ({ softSkill }) => {
  const getProgressValue = (level) => {
    switch (level) {
      case 'Beginner':
        return 30;
      case 'Intermediate':
        return 60;
      case 'Expert':
        return 90;
      default:
        return 0; // Default progress if level is not specified
    }
  };
  return (
    <div className="soft-skill-item">
      <div className="skill">
        <img src={bulb} alt="problem-solving"></img>
        <span>{softSkill?.softSkillName || ""}</span>
      </div>
      <ProgressBar progress={getProgressValue(softSkill?.softSkillLevel || "")} />
      <div className="remarks">{softSkill?.softSkillLevel || ""}</div>
    </div>
  );
};

const Languages = ({ data }) => {
  return (
    <Stack direction="row" flexWrap="wrap" gap={2} className="languages">
      {data?.map((lan, index) => (
        <LanguageItem key={index} language={lan} />
      ))}
    </Stack>
  );
};

const LanguageItem = ({ language }) => {
  const getRatingValue = (level) => {
    switch (level) {
      case 'Fluent':
        return 2;
      case 'Intermediate':
        return 3;
      case 'Advanced':
        return 4;
      default:
        return 1; // Default rating if none match
    }
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ width: { xs: '100%', sm: '45%', md: '30%', lg: '40%' } }}
      className="language-item"
    >
      <span style={{ fontWeight: 500, fontSize: '16px' }}>{language?.languageName || ""}</span>
      <Rating
      name="language-rating"
      readOnly
      value={getRatingValue(language?.languageLevel)} // Set the rating value dynamically
      icon={<CircleIcon style={{ color: "#062B6E" }} />}
      emptyIcon={<CircleIcon style={{ color: "#A5B3CB" }} />}
      sx={{ fontSize: '20px' }}
    />
      <span style={{ fontSize: '14px', color: '#666' }}>{language?.languageLevel || ""}</span>
    </Stack>
  );
};

const IconContainer = ({ children }) => {
  return <span style={{ margin: "0 4px", display: "flex" }}>{children}</span>;
};

const Interests = ({ data }) => {
  console.log(data,"data")
  return (
    <div className="interests">
      {data.map((item) => (
        <span>{item}</span>
      ))}
    </div>
  );
};

export default CandidateInfo;
