import React, { useState, useEffect, useRef } from "react";
import { formatDateWithYearAndMonth, calculateTotalExperience } from "../../../../utils/dateUtils";
import CircleIcon from "@mui/icons-material/Circle";
import circleWithGreenDot from "../../../../assets/svgs/circle-with-green-dot.svg";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import UpdateButton from "../UpdateButton";
import { IconButton, Button ,Box} from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import WorkExpAdd from "./WorkExpAdd";
import DeleteIcon from "../../../../assets/svgs/delete.svg";
import SkillAdd from "../SkillAdd/SkillAdd"; 

// Helper function to format date to "MM/YYYY"
const formatToMonthYear = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${year}`;
};

// Helper function to convert "MM/YYYY" to "YYYY-MM" for input
const formatToInputValue = (dateString) => {
  if (!dateString) return "";
  if (dateString === "present") {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
  }
  const [month, year] = dateString.split("/");
  return `${year}-${month}`;
};

const WorkExpEdit = ({ workData, handleClickUpdate }) => {
  const [workExperiences, setWorkExperiences] = useState(workData);
  const [openWorkAdd, setOpenWorkAdd] = useState(false);
  const [errors, setErrors] = useState({});


 

  const validateFields = () => {
    const fieldErrors = {};
    workExperiences.forEach((work, index) => {
      fieldErrors[index] = {
        location: !work.location,
        company: !work.company,
        startDate: !work.startDate,
        endDate: !work.endDate,
        position: !work.position ,
      };
    });
    setErrors(fieldErrors);
    return !Object.values(fieldErrors).some((error) => Object.values(error).includes(true));
  };

  const handleFieldChange = (index, field, value) => {
    const updatedExperiences = workExperiences.map((work, idx) =>
      idx === index ? { ...work, [field]: value } : work
    );
    setWorkExperiences(updatedExperiences);
  };

  const handleAddHighlight = (index) => {
    const updatedExperiences = [...workExperiences];
    updatedExperiences[index].responsibilities.push("");
    setWorkExperiences(updatedExperiences);
  };

  const handleClickUpdateButton = () => {
    if (validateFields()) {
    handleClickUpdate("Work Experience & Project Highlights", workExperiences);
    }
    console.log(workExperiences)
  };

  const handleAddWorkSubmit = (newWork) => {
    if (Object.keys(newWork).length > 0) {
      setWorkExperiences([...workExperiences, newWork]);
    }
    console.log(newWork,"newWork")
    setOpenWorkAdd(false);
  };

  const handleCloseWorkAdd = () => {
    setOpenWorkAdd(false);
  };

  const handleDeleteWorkExperience = (index) => {
    const updatedExperiences = workExperiences.filter((_, i) => i !== index);
    setWorkExperiences(updatedExperiences);
  };

  return (
    <div className="work-experience-items">
      <WorkExpAdd open={openWorkAdd} onClose={handleCloseWorkAdd} handleSubmit={handleAddWorkSubmit} />
     
      <Button
        variant="outlined"
        size="small"
        onClick={() => { setOpenWorkAdd(true); }}
        style={{
          color: "#062B6E",
          fontFamily: "Poppins",
          fontSize: "13px",
          fontWeight: 500,
          letterSpacing: "0.2px",
          alignSelf: 'end'
        }}
      >
        Add Work Experience & Project Highlights
      </Button>
      {workExperiences.map((work, index) => (
        <WorkExperienceItemEdit
          key={index}
          work={work}
          index={index}
          onFieldChange={handleFieldChange}
          onAddHighlight={() => handleAddHighlight(index)}
          onDelete={() => handleDeleteWorkExperience(index)}
          errors={errors[index] || {}} 
        />
      ))}
      <UpdateButton name="summary" handleClickUpdateButton={handleClickUpdateButton} />
    </div>
  );
};

const WorkExperienceItemEdit = ({ work, index, onFieldChange ,onDelete,errors}) => {
  const [highlightTexts, setHighlightTexts] = useState(work.projects.map(() => "")); // Track highlights per project
  const textFieldRefs = useRef(work.projects.map(() => React.createRef())); // Track refs per project
const [skillOpen,setSkillOpen]=useState(false);
const [projectIndex,setProjectIndex]=useState(0);
  useEffect(() => {
    const formattedHighlights = work.projects.map((project) => {
      if (project.responsibilities && Array.isArray(project.responsibilities)) {
        return project.responsibilities.map((highlight) => `• ${highlight}`).join("\n");
      }
      return "";
    });
    setHighlightTexts(formattedHighlights);
  }, [work.projects]);

  const handleHighlightsChange = (expIndex, projIndex, value) => {
    const newHighlights = [...highlightTexts];
    newHighlights[projIndex] = value;
    setHighlightTexts(newHighlights);
    saveHighlights(expIndex, projIndex, value);
  };

  const handleAddBulletPoint = (projIndex) => {
    const updatedText = `${highlightTexts[projIndex]}\n• `;
    const newHighlights = [...highlightTexts];
    newHighlights[projIndex] = updatedText;
    setHighlightTexts(newHighlights);
  };

  const saveHighlights = (expIndex, projIndex, value) => {
    const highlightsArray = value
      .split("\n")
      .map((line) => line.replace(/^•\s*/, "").trim())
      .filter((line) => line !== "");

    onFieldChange(expIndex, "projects", work.projects.map((project, idx) =>
      idx === projIndex ? { ...project, responsibilities: highlightsArray } : project
    ));
  };

  // Function to handle start date change and format it to "MM/YYYY"
  const handleStartDateChange = (value) => {
    if (!value) {
      onFieldChange(index, "startDate", "");
      return;
    }
    const formattedDate = formatToMonthYear(value);
    onFieldChange(index, "startDate", formattedDate);
  };

  // Function to handle end date change and format it to "MM/YYYY"
  const handleEndDateChange = (value) => {
    if (!value) {
      onFieldChange(index, "endDate", "");
      return;
    }
    const formattedDate = formatToMonthYear(value);
    onFieldChange(index, "endDate", formattedDate);
  };

  // Function to remove a skill from the tech stack

  const handleSkillAddSubmit = (newSkillArr) => {
    const updatedProjects = work.projects.map((project, idx) => {
      if (idx === projectIndex) {
        return {
          ...project,
          techStack: [
            ...(Array.isArray(project?.techStack) ? project.techStack : []), // Ensure techStack is always an array
            ...newSkillArr,
          ],
        };
      }
      return project;
    });
  
    // Update the projects in the state
    onFieldChange(index, "projects", updatedProjects);
    setSkillOpen(false); // Close the skill modal
  };
  

  const handleOpenSkill=(ind)=>{
    setProjectIndex(ind);
    setSkillOpen(true);
  }
  
  // Rest of the existing functions
  const handleCloseSkill = () => {
    setSkillOpen(false);
  };
  
  const handleRemoveSkill = (projIndex, skillIdx) => {
    const updatedProjects = work.projects.map((project, idx) => {
      if (idx === projIndex) {
        return {
          ...project,
          techStack: project.techStack.filter((_, index) => index !== skillIdx),
        };
      }
      return project;
    });
    onFieldChange(index, "projects", updatedProjects);
  };
  

  const handleDeleteProject = (projIndex) => {
    const updatedProjects = work.projects.filter((_, idx) => idx !== projIndex);
    onFieldChange(index, "projects", updatedProjects);
  };

  return (
    <div className="work-experience-item" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div className="item-container" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
       
        {/* Designation Section */}
        <div className="designation-main" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div 
  className="designation" 
  style={{ display: "flex", alignItems: "center", gap: "12px", justifyContent: "space-between", width: "100%" }}
>
  <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
    <div className="circle-with-green-dot">
      <img src={circleWithGreenDot} alt="circle-with-green-dot" />
    </div>
    <SkillAdd open={skillOpen} onClose={handleCloseSkill} handleSumbit={handleSkillAddSubmit} />
    
    <TextField
     error={!!errors.position}
      value={work.position || ""}
      onChange={(e) => onFieldChange(index, "position", e.target.value)}
      variant="outlined"
      size="small"
      style={{ width: "220px", marginRight: "12px" }}
      InputLabelProps={{
        shrink: true,
        style: {
          color: "#212121",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: 600,
          letterSpacing: "0.2px",
        },
      }}
      InputProps={{
        style: {
          color: "#212121",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: 600,
          letterSpacing: "0.2px",
          textAlign: "center",
        },
      }}
    />

    <div className="dot">
      <CircleIcon style={{ color: "green", fontSize: "10px" }} />
    </div>

    <Stack direction="row" spacing={2} alignItems="center" gap="12px">
      <TextField
       error={!!errors.startDate}
        label="Start Date"
        type="month"
        value={formatToInputValue(work.startDate)}
        onChange={(e) => handleStartDateChange(e.target.value)}
        InputLabelProps={{ shrink: true }}
        size="small"
        style={{ width: "125px", marginRight: "12px" }}
      />

      <span>-</span>

      <TextField
       error={!!errors.endDate}
        label="End Date"
        type="month"
        value={formatToInputValue(work.endDate)}
        onChange={(e) => handleEndDateChange(e.target.value)}
        InputLabelProps={{ shrink: true }}
        size="small"
        style={{ width: "125px", marginRight: "12px" }}
      />

      <span>
        {work.startDate && work.endDate
          ? `(${calculateTotalExperience(work.startDate, work.endDate)})`
          : ""}
      </span>
    </Stack>
  </div>

  {/* Delete Icon placed at the far right */}
  <IconButton onClick={() => onDelete()} aria-label="delete" sx={{ padding: 0 }}>
    <img src={DeleteIcon} alt="delete" style={{ width: '25px', height: '25px' }} />
  </IconButton>
</div>


          {/* Company and Location */}
          <TextField
           error={!!errors.company}
            value={work.company || ""}
            onChange={(e) => onFieldChange(index, "company", e.target.value)}
            variant="outlined"
            size="small"
            style={{ width: "200px", textAlign: "center" }}
            InputProps={{
              inputProps: {
                style: {
                  color: "#4F4F4F",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 600,
                  letterSpacing: "0.2px",
                  textAlign: "left",
                },
              },
            }}
          />

          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span style={{
              color: "#4F4F4F",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 500,
              letterSpacing: "0.2px"
            }}>
              Location:
            </span>

            <TextField
             error={!!errors.location}
              value={work.location || ""}
              onChange={(e) => onFieldChange(index, "location", e.target.value)}
              variant="outlined"
              size="small"
              style={{ width: "200px" }}
              InputProps={{
                inputProps: {
                  style: {
                    color: "#4F4F4F",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    letterSpacing: "0.2px",
                    textAlign: "left",
                  },
                },
              }}
            />
          </div>

          {/* Project Summary and Highlights Container */}
          {work.projects.map((project, projIndex) => (
            <div key={projIndex} className="project-highlights-container" style={{ display: "flex", flexDirection: "column" }}>
              <div className="project-main">
              <Stack direction="row" spacing={1} alignItems="center">
  <TextField
    placeholder="Project Title"
    value={project.projectName || ""}
    onChange={(e) => onFieldChange(index, "projects", work.projects.map((p, i) =>
      i === projIndex ? { ...p, projectName: e.target.value } : p
    ))}
    variant="outlined"
    size="small"
    fullWidth
    
    InputProps={{
      sx: {
        color: "#062B6E",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        textAlign: "center",
        lineHeight: "normal",
        letterSpacing: "0.2px",
      },
    }}
    inputProps={{
      style: {
        textAlign: "left",
      },
    }}
    sx={{
      flex:11,
      "& .MuiInputBase-input::placeholder": {
        color: "#062B6E",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "normal",
        letterSpacing: "0.2px",
        opacity: 1,
      },
    }}
  />
  <IconButton
    onClick={() => handleDeleteProject(projIndex)}
    aria-label="delete"
    sx={{ flex: 1, padding: 0, justifyContent: 'center' }} // Flex for 1 part, centered
  >
    <img src={DeleteIcon} alt="delete" style={{ width: '25px', height: '25px' }} />
  </IconButton>
</Stack>

<TextField
  placeholder="Project Description"
  value={project.projectSummary || ""}
  onChange={(e) => onFieldChange(index, "projects", work.projects.map((p, i) =>
    i === projIndex ? { ...p, projectSummary: e.target.value } : p
  ))}
  multiline
  rows={4}
  variant="outlined"
  size="small"
  fullWidth
  style={{ marginTop: "8px" }}
  InputProps={{
    sx: {
      color: "#4F4F4F", // Text color
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "23px",
      letterSpacing: "0.2px",
    },
  }}
  sx={{
    "& .MuiInputBase-input::placeholder": {
      color: "#4F4F4F", // Placeholder color
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "23px",
      letterSpacing: "0.2px",
      opacity: 1, // Ensure placeholder visibility
    },
  }}
/>

              </div>
              <div className="responsibilities-main">
                <div className="responsibilities-header">Responsibilities & Achievements:</div>
                <TextField
  inputRef={textFieldRefs.current[projIndex]} // Attach ref to the specific TextField
  value={highlightTexts[projIndex]}
  onChange={(e) => handleHighlightsChange(index, projIndex, e.target.value)}
  variant="outlined"
  multiline
  rows={4}
  fullWidth
  size="small"
  onKeyDown={(e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddBulletPoint(projIndex);
    }
  }}
  onBlur={() => saveHighlights(index, projIndex, highlightTexts[projIndex])}
  InputProps={{
    sx: {
      color: "#333",           // Text color
      fontFamily: "Poppins",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "23px",
      letterSpacing: "0.2px",
    },
  }}
  sx={{
    "& .MuiInputBase-input::placeholder": {
      color: "#333", // Placeholder color
      fontFamily: "Poppins",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "23px",
      letterSpacing: "0.2px",
      opacity: 1, // Ensures placeholder is visible
    },
  }}
/>

              </div>

              {/* Tech Stack Container */}
              <div className="tech-stacks">
              <Box
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '8px', // Optional spacing below the row
            }}
        >
            <div className="tech-stack-header">Technologies stack:</div>
            <Button
                onClick={() => {
                    handleOpenSkill(projIndex);
                }}
                variant="outlined" // Optional variant for styling
                size="small" // Optional for smaller button
                sx={{marginTop:'4px'}}
                style={{
                  color: "#062B6E",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontWeight: 500,
                  letterSpacing: "0.2px",
                  alignSelf: 'end'
                }}
            >
                Add Technology
            </Button>
        </Box>
                <div className="tech-stack-list" style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                  {project?.techStack?.map((tech, skillIdx) => (
                    <div
                      key={skillIdx}
                      style={{
                        position: "relative",
                        display: "inline-flex",
                        alignItems: "center",
                        padding: "7px 10px",
                        background: "rgba(201, 215, 240, 0.6)",
                        borderRadius: "4px",
                        margin: "5px",
                      }}
                    >
                      <span style={{ color: "#062b6e", fontSize: "14px", fontWeight: "500" }}>{tech}</span>
                      <IconButton
                        size="small"
                        style={{ position: "absolute", top: "-15px", right: "-15px", zIndex: 1 }}
                        onClick={() => handleRemoveSkill(projIndex, skillIdx)}
                        aria-label="remove skill"
                      >
                        <CancelRoundedIcon fontSize="small" style={{ color: "#062b6e" }} />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkExpEdit;
