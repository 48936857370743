import axios from "axios";
import { toast } from "react-toastify";
import { CANDIDATE_PROFILE ,CANDIDATE_CARD_INFO,CANDIDATE_PROFILE_DATA,CANDIDATE_PROFILE_DATA_SAVE} from "./URLS";

export const candidateProfile = async (data) => {
    try {
        const endPoint = CANDIDATE_PROFILE;
        const Config = {
            // headers: {
            //     Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            // }
        };
        return await axios.post(endPoint, data, Config).then(res => res?.data)
    } catch (err) {
        console.log("error in candidateProfile==>", err)
        toast.error(err?.response?.data?.exception)
    }
}
export const saveCandidateInfo = async (data) => {
    try {
        const endPoint = `${CANDIDATE_PROFILE_DATA_SAVE}${data?.email}`;
        const { email, ...dataWithoutEmail } = data;
        const Config = {
            // headers: {
            //     Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            // }
        };
        return await axios.put(endPoint, dataWithoutEmail, Config).then(res => res?.data)
    } catch (err) {
        console.log("error in saveCandidateInfo==>", err)
        toast.error(err?.response?.data?.exception)
    }
}


export const getCandidateInfo = async (data) => {

    try {
        const endPoint = CANDIDATE_CARD_INFO;
        
        const Config = {
            // headers: {
            //     Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            // }
        };
        return await axios.post(endPoint, data, Config).then(res => res?.data)
    } catch (err) {
        console.log("error in getCandidateInfo==>", err)
        toast.error(err?.response?.data?.exception)
    }
}

export const getCandidateAllInfo = async (data) => {
    try {
        const endPoint = `${CANDIDATE_PROFILE_DATA}${data.name}`;
        console.log(endPoint,"endPoint")
        const Config = {
            // headers: {
            //     Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            // }
        };
        return await axios.get(endPoint, data, Config).then(res => res?.data)
    } catch (err) {
        console.log("error in candidateProfile==>", err)
        toast.error(err?.response?.data?.exception)
    }



}