import axios from "axios";
import { GET_ALL_REFERRALS_LIST } from "./URLS";
import { toast } from "react-toastify";
import axiosInstance from "./axiosInstance";

export const getReferralsList = async (data) => {
    try {
        const endPoint = GET_ALL_REFERRALS_LIST + `?pageNumber=${data?.page}&pageSize=${data?.size}`;
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            }
        };

        return await axiosInstance.get(endPoint, config).then(res => res?.data)
    } catch (err) {
        console.log("error in getReferralsList==>", err)
        toast.error(err.response.data.exception)
    }
}