import React, { useState } from 'react';
import { IconButton ,Button} from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import UpdateButton from '../UpdateButton';
import SkillAdd from '../SkillAdd/SkillAdd';


const Skills = ({ skills: initialSkills,handleClickUpdate,headerName }) => {
  // State to manage skills
  const [skills, setSkills] = useState(initialSkills);
  const [openAddSkill,setOpenAddSkill]=useState(false);

  // Remove a skill from the list
  const onRemove = (skillToRemove) => {
    const updatedSkills = skills.filter((skill) => skill !== skillToRemove);
    setSkills(updatedSkills);
  };

  // Handle update button click
  const handleClickUpdateButton = () => {
    handleClickUpdate(headerName, skills);
  };
  const handleCloseAddSkill=()=>{
    setOpenAddSkill(false);
  }

  const handleSubmitAddSkill = (newskills) => {
    // Check if newskills is not empty
    if (newskills && newskills.length > 0) {
      // Transform each string into an object with the format { name: "<string>" }
      const formattedSkills = newskills.map((skill) => ({ name: skill }));
  
      // Update the skills state with the new skills
      setSkills([...skills, ...formattedSkills]);
    }
  
    // Close the add skill modal
    setOpenAddSkill(false);
  };
  return (
    <>
    <SkillAdd  open={openAddSkill} onClose={handleCloseAddSkill} handleSumbit={handleSubmitAddSkill} />
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpenAddSkill(true)}
          sx={{
            borderRadius: '4px',         // Border radius
            border: '1px solid #062B6E', // Border color
            color: '#062B6E',            // Text color
            fontFamily: 'Poppins',       // Font family
            fontSize: '13px',            // Font size
            fontWeight: 500,             // Font weight
            letterSpacing: '0.2px',      // Letter spacing
            textTransform: 'none',   
            marginBottom:'10px'    // Disable uppercase transformation
          }}
        >
          Add Skills
        </Button>
      </div>
      <div className="skills">
        {skills?.map((skill, index) => (
          <SkillItem key={index} skill={skill} onRemove={onRemove} />
        ))}
      </div>
      <UpdateButton name="summary" handleClickUpdateButton={handleClickUpdateButton} />
    </>
  );
};

const SkillItem = ({ skill, onRemove }) => {
  return (
    <div className="skill-item">
      <span className="skill">{skill?.name}</span>

      {/* Cancel Icon */}
      <IconButton
        className="cancel-icon"
        size="small"
        onClick={() => onRemove(skill)}
        aria-label="remove skill"
      >
        <CancelRoundedIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

export default Skills;
