export const getTodayDateAsMinDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};


export const getDateAfterDays = (days) => {
  const today = new Date();
  const targetDate = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
  const year = targetDate.getFullYear();
  const month = String(targetDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(targetDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getTodayTimeAsMinTime = () => {
  const today = new Date();
  let hours = today.getHours();
  const minutes = today.getMinutes();
  hours = hours < 10 ? '0' + hours : hours;
  const strMinutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${strMinutes}`;
};