import React, { useEffect } from "react";
import styles from "./popUp.module.css";
const Popup = ({ children, style, onClick }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  });

  // Function to handle click events
  const handleClick = (event) => {
    // Check if the event target is the same as the current target
    if (
      event.target === event.currentTarget &&
      onClick &&
      typeof onClick === "function"
    ) {
      onClick(event);
    }
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={`${styles.popup}`} style={style}>
        {children}
      </div>
    </div>
  );
};

export default Popup;
