import { useEffect, useRef, useState } from "react";
import "./Questions.scss";
import UploadIcon from "../../Assessts/SVGs/upload.svg";
import crossFile from "../../Assessts/SVGs/crossFile.svg";
import { CustomDropDown } from "../../Components/common/CustomDropDown/CustomDropDown";
import { FilesError } from "../../Components/common/FileUploadError/FileUploadError";
import { fileUploadErrors } from "../../mockData/fileUploadErrors";
import { saveAs } from "file-saver";
import { createNewQuestion } from "../../APIs/questions";
import { toast } from "react-toastify";
import {
  CREATE_QUESTION_ROUND1,
  CREATE_QUESTION_ROUND2,
} from "../../APIs/URLS";
import { GlobalLoader } from "../../Components/common/GlobalLoader/GlobalLoader";
import { CommonDialog } from "../../Components/common/Dialog/CommonDialog";
import { fileUploadSuccess } from "../../mockData/fileUploadSuccess";

const initialQuestionDetail = {
  field: "",
  round: "",
  topic: "",
  level: "",
  fileName: "",
};
const fieldOptions = ["Development", "Data Science"];
const roundOptions = ["Round 1", "Round 2"];
const languages = ["Java", "Python", "Javascript"];
const database = ["MongoDB", "MySQL"];
const topicOptionsForDevelopment = [
  "Aptitude",
  "DSA",
  ...languages,
  ...database,
];
const topicOptionsForDataScience = ["Statistics", "Python", ...database];
const levelOptions = ["Easy", "Medium", "Difficult"];

export const Questions = () => {
  const [questionDetails, setQuestionDetails] = useState(initialQuestionDetail);

  const [selectedFile, setSelectedFile] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [viewError, setViewError] = useState(false);
  const [fileError, setFileError] = useState({});
  const [disableButton, setDisableButton] = useState({
    upload: true,
    download: true,
    submit: true,
  });
  const [successDialog, setSuccessDialog] = useState({
    ...fileUploadSuccess?.correctFileUpload,
  });
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const topicOptions =
    questionDetails.field === "Development"
      ? topicOptionsForDevelopment
      : topicOptionsForDataScience;

  const showLevelOptions =
    questionDetails.field === "Development" &&
    questionDetails.round === "Round 1" &&
    questionDetails.topic === "DSA";

  useEffect(() => {
    const { field, round, topic, level } = questionDetails;
    const disableButtonLogic =
      !field ||
      !round ||
      (round === "Round 1" && (!topic || (topic === "DSA" && !level)));
    setDisableButton({
      upload: disableButtonLogic,
      download: disableButtonLogic,
      submit: !selectedFile || disableButtonLogic,
    });
  }, [questionDetails, selectedFile]);

  const handleChange = (key, value) => {
    setQuestionDetails((prevValues) => ({
      ...prevValues,
      [key]: value,
      ...(key === "field" && { round: "", topic: "", level: "" }),
      ...(key === "round" && { topic: "", level: "" }),
      ...(key === "topic" && { level: "" }),
    }));
  };

  const checkQuestionData = (questionData) => {
    const { file, type, level = "", topic = "", round } = questionData;
    const fileName = file?.name?.split(".")[0].toLowerCase();
    const lowerType = type.toLowerCase();
    const lowerLevel = level.toLowerCase();
    const lowerTopic = topic.toLowerCase();
    if (round === "Round 1") {
      return lowerTopic === "dsa"
        ? fileName === `${lowerTopic} ${lowerLevel}`
        : fileName === lowerTopic;
    }
    return round === "Round 2" && fileName === lowerType;
  };

  const getFileName = () => {
    const { topic, level, field, round } = questionDetails;
    if (round === "Round 1") {
      if (topic === "DSA" && !level) return null;
      return topic === "DSA" ? `${topic} ${level}` : topic;
    }

    if (round === "Round 2") {
      return field;
    }

    return null;
  };

  const handleDownloadButton = () => {
    const fileName = getFileName();
    fetch(`/docs/${fileName}.xlsx`)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const fileBlob = new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(fileBlob, `${fileName}.xlsx`);
        toast.success("Sample file downloaded successfully!");
      })
      .catch((error) => {
        toast.error("Sample file downloaded successfully!");
      });
  };

  const generateFormData = (questionData) => {
    const { type, file } = questionData;
    const formData = new FormData();

    formData.append("type", type);

    if (file) {
      formData.append("file", file);
    }
    return formData;
  };

  const getType = () => {
    const topic = questionDetails?.topic;
    if (languages.includes(topic)) {
      return "Language";
    } else if (database.includes(topic)) {
      return "Database";
    } else {
      return topic;
    }
  };

  const generateQuestionData = () => {
    const { round, level, topic, field } = questionDetails;

    if (!field || !round) return null;

    const type = getType();

    if (round === "Round 1") {
      if (topic === "DSA" && !level) return null;
      if (!topic) return null;

      return { file: selectedFile, type, level, topic, round };
    }

    if (round === "Round 2") {
      return { file: selectedFile, type: field, round };
    }

    return null;
  };

  const handleUploadError = () => {
    setViewError(true);
    setFileError({ ...fileUploadErrors?.incorrectFileInput });
  };

  const handleServerError = () => {
    setViewError(true);
    setFileError({ ...fileUploadErrors?.serverIssue });
  };

  const handleMismatchError = () => {
    setViewError(true);
    setFileError({ ...fileUploadErrors?.mismatchFile });
  };

  const handleSubmitButton = async () => {
    try {
      setShowLoader(true);
      const questionData = generateQuestionData();
      if (checkQuestionData(questionData)) {
        const data = generateFormData(questionData);
        const url =
          questionDetails?.round === "Round 1"
            ? CREATE_QUESTION_ROUND1
            : CREATE_QUESTION_ROUND2;
        let res = await createNewQuestion(data, url);
        if (!res || res?.error === "Server issue") {
          handleServerError();
          return;
        }
        if (res?.error === "File issue") {
          handleUploadError();
          return;
        }
        if (res) {
          setQuestionDetails(initialQuestionDetail);
          setSelectedFile(null);
          setShowSuccessDialog(true);
          setSuccessDialog({ ...fileUploadSuccess?.correctFileUpload });
        }
      } else {
        handleMismatchError();
      }
    } catch (err) {
      handleServerError(err);
    } finally {
      setShowLoader(false);
    }
  };

  const handleClose = () => {
    setShowSuccessDialog(false);
    setSuccessDialog({});
  };

  return (
    <>
      {showLoader && <GlobalLoader />}
      <div className="question-container">
        <div className="question-title">Questions</div>
        <div className="info">
          Please select the respective build and upload the questions
          accordingly.
        </div>
        <div className="dropdown-container">
          <Field
            fieldOptions={fieldOptions}
            handleChange={handleChange}
            questionDetails={questionDetails}
          ></Field>
          <Round
            roundOptions={roundOptions}
            handleChange={handleChange}
            questionDetails={questionDetails}
          ></Round>
          <Topic
            topicOptions={topicOptions}
            handleChange={handleChange}
            questionDetails={questionDetails}
          ></Topic>
          <Level
            levelOptions={levelOptions}
            handleChange={handleChange}
            questionDetails={questionDetails}
            showLevelOptions={showLevelOptions}
          ></Level>
        </div>
        <div className="question-files">
          <FileUpload
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            fileName={questionDetails.fileName}
            handleChange={handleChange}
            viewError={viewError}
            setViewError={setViewError}
            fileError={fileError}
            setFileError={setFileError}
            disableUpload={disableButton.upload}
          />
          <div className={`download-sample-file `}>
            <input
              type="button"
              onClick={handleDownloadButton}
              value={"Download Sample File"}
              className={`${disableButton?.download ? "disabled-button" : ""}`}
              disabled={disableButton?.download}
            />
          </div>
        </div>
        <div>
          <input
            className={`question-submit ${
              disableButton?.submit ? "disabled-button" : ""
            }`}
            onClick={handleSubmitButton}
            disabled={disableButton?.submit}
            type="button"
            value={"Submit"}
          ></input>
        </div>
      </div>
      {showSuccessDialog && (
        <CommonDialog
          buttonsList={{
            Okay: {
              name: "Okay",
              action: handleClose,
            },
            Close: {
              name: "Close",
              action: handleClose,
            },
          }}
          data={successDialog}
        ></CommonDialog>
      )}
    </>
  );
};

const Field = ({ fieldOptions, handleChange, questionDetails }) => {
  return (
    <div className="field-container">
      <CustomDropDown
        label="Field"
        options={fieldOptions}
        handleChange={(value) => handleChange("field", value)}
        value={questionDetails.field}
      />
    </div>
  );
};

const Round = ({ roundOptions, handleChange, questionDetails }) => {
  return (
    <div className="round-container">
      <CustomDropDown
        label="Round"
        options={roundOptions}
        handleChange={(value) => handleChange("round", value)}
        value={questionDetails.round}
        isDisabled={!questionDetails.field}
      />
    </div>
  );
};

const Topic = ({ topicOptions, handleChange, questionDetails }) => {
  return questionDetails?.round === "Round 1" ? (
    <div className="questions-on-container">
      <CustomDropDown
        label="Topic"
        options={topicOptions}
        handleChange={(value) => handleChange("topic", value)}
        value={questionDetails.topic}
        isDisabled={!questionDetails.field || !questionDetails.round}
      />
    </div>
  ) : (
    <></>
  );
};

const Level = ({
  levelOptions,
  handleChange,
  questionDetails,
  showLevelOptions,
}) => {
  return (
    showLevelOptions && (
      <div className="level-container">
        <CustomDropDown
          label="Level"
          options={levelOptions}
          handleChange={(value) => handleChange("level", value)}
          value={questionDetails.level}
        />
      </div>
    )
  );
};

const FileUpload = ({
  selectedFile,
  setSelectedFile,
  fileName,
  handleChange,
  viewError,
  setViewError,
  fileError,
  setFileError,
  disableUpload,
}) => {
  const fileInputRef = useRef(null);

  const fileCheck = (file, fileType) => {
    if (fileType !== "xls" && fileType !== "xlsx") {
      setFileError({ ...fileUploadErrors?.excelFileFormat });
      setViewError(true);
      return;
    }
    if (file && file.size === 0) {
      setFileError({ ...fileUploadErrors?.fileEmpty });
      setViewError(true);
      return;
    } else if (file && file.size > 10 * 1024 * 1024) {
      setFileError({ ...fileUploadErrors?.excelFileSize });
      setViewError(true);
      return;
    } else {
      setSelectedFile(file);
      handleChange("fileName", file.name);
      setViewError(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const type = file?.name?.split(".");
    const fileType = type?.length > 0 ? type[type.length - 1] : "";
    if (fileType.length > 0) fileCheck(file, fileType);
    fileInputRef.current.value = "";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (disableUpload) {
      return;
    }
    const file = e.dataTransfer.files[0];
    const type = file?.name?.split(".");
    const fileType = type[type.length - 1];
    fileCheck(file, fileType);
    fileInputRef.current.value = "";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleCloseError = () => {
    setViewError(false);
    setFileError({});
  };

  const handleDeleteIcon = () => {
    setSelectedFile(null);
    handleChange("fileName", "");
    fileInputRef.current.value = "";
  };

  return (
    <>
      <div className="file-main">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className={`dropBox ${disableUpload ? "disabled-button" : ""}`}
        >
          <input
            type="file"
            id="fileInput"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            disabled={disableUpload}
            accept=".xls,.xlsx"
          />
          <label
            htmlFor="fileInput"
            className={`uploadIcon ${disableUpload ? "disabled-button" : ""}`}
          >
            <img src={UploadIcon} alt="" />
          </label>
          <div
            className={`firstLabel ${disableUpload ? "disabled-button" : ""}`}
          >
            <label
              htmlFor="fileInput"
              className={`label1 ${disableUpload ? "disabled-button" : ""}`}
            >
              Drag & drop
            </label>
            <label
              htmlFor="fileInput"
              className={`label2 ${disableUpload ? "disabled-button" : ""}`}
            >
              {" "}
              or <span>Choose file</span> to upload questions
            </label>
          </div>
          <div
            className={`secondLabel ${disableUpload ? "disabled-button" : ""}`}
          >
            Upload excel file of max 10 MB only
          </div>
        </div>
        {selectedFile ? (
          <div className="selected">
            {" "}
            <div>{selectedFile.name}</div>
            <div onClick={handleDeleteIcon}>
              <img src={crossFile} alt="" />{" "}
            </div>
          </div>
        ) : (
          <div className="selected">
            <div>{fileName}</div>
          </div>
        )}
      </div>
      {viewError && (
        <FilesError setviewFileError={handleCloseError} fileError={fileError} />
      )}
    </>
  );
};
