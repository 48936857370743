import { useState } from "react";

export const useUtils = () => {
    const [timeOutId, setTimeOutId] = useState("");

    // Function to handle debouncing
    const debounce = (func, delay = 500) => {
        return function (...args) {
            clearTimeout(timeOutId);
            let id = setTimeout(() => func.apply(this, args), delay);
            setTimeOutId(id);
        };
    };

    return { debounce }
}