import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DeveloperIcon } from "../../Assessts/SVGs/developer.svg";
import { ReactComponent as Dashboard } from "../../Assessts/SVGs/dashboard.svg";
import { ReactComponent as Referrals } from "../../Assessts/SVGs/referrals.svg";
import { ReactComponent as Questions } from "../../Assessts/SVGs/questions.svg";
import { ReactComponent as Users } from "../../Assessts/SVGs/users.svg";
import { ReactComponent as Interview } from "../../Assessts/SVGs/interview.svg";
import { ReactComponent as Rubric } from "../../Assessts/SVGs/rubric.svg";
export const SideNavBar = () => {
  const [selectedRoute, setSelectedRoute] = React.useState("");
  const navigate = useNavigate();

  const routes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      Icon: Dashboard,
    },
    {
      path: "/view-users?page=1",
      name: "Users",
      Icon: Users,
    },
    {
      path: "/view-developers?page=1",
      name: "Developers",
      Icon: DeveloperIcon,
    },
    {
      path: "/questions",
      name: "Questions",
      Icon: Questions,
    },
    {
      path: "/referrals?page=1",
      name: "Referrals",
      Icon: Referrals,
    },
    {
      path: "/interview",
      name: "Interview",
      Icon: Interview,
    },
    {
      path: "/rubric",
      name: "Rubric",
      Icon: Rubric,
    },
  ];

  function handleChangeRoute(route) {
    setSelectedRoute(route);
    navigate(route);
  }

  return (
    <>
      <Drawer variant="permanent">
        <List>
          {routes.map((route) => (
            <ListItem
              key={route.path}
              button
              onClick={() => handleChangeRoute(route?.path)}
              selected={route?.path === selectedRoute}
              style={{
                color: route?.path === selectedRoute ? "#4318FF" : "",
              }}
            >
              <ListItemIcon style={{ color: "inherit" }}>
                {route?.Icon && <route.Icon />}
              </ListItemIcon>

              <ListItemText primary={route?.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};
