import uploadSuccess from "../Assessts/SVGs/upload-success.svg";

export const fileUploadSuccess = {
  correctFileUpload: {
    message1: "Upload Successful!",
    message2: "Your file has been uploaded successfully.",
    icon: uploadSuccess,
    style1: {
      color: "#34a853",
    },
  },
};
