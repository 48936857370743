import { Pagination } from "@mui/material";
import "./Pagination_data.scss";
import noDataFound from "../../../Assessts/SVGs/no-data-found.svg";

export const TablePages = ({ page, handlePageChange, totalPages = 50 }) => {
  return (
    <div className="pagination-page-wrapper">
      {/* Your paginated content here */}
      {totalPages > 1 && (
        <Pagination
          count={totalPages} // Total number of pages
          page={page} // Current page
          onChange={handlePageChange} // Callback function for page change
          color="primary" // Color of the pagination buttons
          size="large" // Size of the pagination buttons
          variant="outlined" // Variant of the pagination buttons
          shape="rounded" // Shape of the pagination buttons
        />
      )}
      {totalPages === 1 && (
        <Pagination
          count={totalPages} // Total number of pages
          page={page} // Current page
          onChange={handlePageChange} // Callback function for page change
          color="primary" // Color of the pagination buttons
          size="large" // Size of the pagination buttons
          variant="outlined" // Variant of the pagination buttons
          shape="rounded" // Shape of the pagination buttons
          hideNextButton
          hidePrevButton
        />
      )}
      {totalPages < 1 && (
        <div>
          <img src={noDataFound} alt="no-data-found"></img>
          <div className="no-data-found">No data found!</div>
        </div>
      )}
    </div>
  );
};
