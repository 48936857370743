export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedTime = hours % 12 || 12;

  return `${day} ${month} ${year}, ${formattedTime} ${ampm}`;
};

export const formatTimeSlots = (slots) => {
  return slots
    .map((slot) => {
      const start = slot.startTime ? formatTimeforHR(slot.startTime) : "-";
      const end = slot.endTime ? formatTimeforHR(slot.endTime) : "-";
      return `${start} - ${end}`;
    })
    .join(", ");
};

const formatTimeforHR = (time) => {
  // Remove leading zeros from the hour part
  const [timePart, period] = time.split(" ");
  const [hour, minute] = timePart.split(":");
  const formattedHour = parseInt(hour, 10).toString();
  return `${formattedHour}${minute ? `:${minute}` : ""} ${period}`;
};

export const formatTime = (time) => {
  // Remove leading zeros from the hour part
  const [timePart, period] = time.split(" ");
  const [hour, minute] = timePart.split(":");
  const formattedHour = parseInt(hour, 10).toString();
  return `${formattedHour}${minute ? `:${minute}` : ""} ${period}`;
};

export const formatTimeTo12Hour = (time) => {
  let [hours, minutes] = time.split(":");
  hours = parseInt(hours, 10);
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert '0' to '12' for 12 AM
  hours = hours < 10 ? "0" + hours : hours; // Pad single digit hours with leading zero if needed
  return `${hours}:${minutes} ${ampm}`;
};

//Date to time without periods in 24 hours (O/P : 03:30, 14:15)
export const formatTimeWithoutPeriod = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  const strTime = hours + ":" + minutes;
  return strTime;
};

//Date to date without time (O/P : 18 July 2024)
export const formatDate = (inputDate) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateParts = inputDate.split("-");
  const year = dateParts[0];
  const month = months[parseInt(dateParts[1], 10) - 1];
  const day = parseInt(dateParts[2], 10);

  return `${day} ${month} ${year}`;
};


export const formatDateCandidate = (dateString, withTime = false) => {
  const date = new Date(dateString);
  const now = new Date();

  const dateOptions = { month: 'long', day: 'numeric', year: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

  if (date.toDateString() === now.toDateString()) {
    return withTime ? `Today, ${date.toLocaleTimeString('en-US', timeOptions)}` : 'Today';
  } else {
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    return withTime ? `${formattedDate}, ${date.toLocaleTimeString('en-US', timeOptions)}` : formattedDate;
  }
};



// function to convert date fromat from 03/2023 to Mar 2023

export const formatDateWithYearAndMonth = (dateString) => {
  if(dateString==="") return ""
 
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  
  const [month, year] = dateString.split('/');
  
 
  const monthIndex = parseInt(month, 10) - 1; 
  
  
  if (monthIndex < 0 || monthIndex >= monthNames.length) {
    throw new Error("Invalid month in date string");
  }
  
  
  return `${monthNames[monthIndex]} ${year}`;
};

export const formatDateWithYearAndMonthExp = (dateString) => {
  if(dateString==="") return ""
 
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  
  const [year,month,date] = dateString.split('-');
  
 
  const monthIndex = parseInt(month, 10) - 1; 
  
  
  if (monthIndex < 0 || monthIndex >= monthNames.length) {
    throw new Error("Invalid month in date string");
  }
  
  
  return `${monthNames[monthIndex]} ${year}`;
};

 // function to calculate total years of experience.

 export  const calculateTotalExperience = (startDate, endDate) => {
  // Helper function to parse "MM/YYYY" into a Date object
  const parseDate = (dateStr) => {
    const [month, year] = dateStr.split('/').map(Number);
    // Use the first day of the month
    return new Date(year, month - 1, 1);
  };

  // Parse the start date
  const start = parseDate(startDate);

  // Parse the end date or use the current date
  const end = endDate!=="present" ? parseDate(endDate) : new Date();

  // Ensure the start date is before the end date
  if (start > end) {
    throw new Error("Start date must be before end date");
  }

  // Calculate the difference in years, months, and days
  let years = end.getFullYear() - start.getFullYear();
  let months = end.getMonth() - start.getMonth();
  let days = end.getDate() - start.getDate();

  // Adjust days and months if necessary
  if (days < 0) {
    months -= 1;
    const prevMonth = new Date(end.getFullYear(), end.getMonth() - 1, 0);
    days += prevMonth.getDate();
  }

  if (months < 0) {
    years -= 1;
    months += 12;
  }

  // Format the result
  if (years === 0) {
    return `${months} months`;
  }

  return `${years} years, ${months} months`;
};




