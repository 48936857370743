import { useEffect } from "react";
import "./GlobalLoader.scss";
import { CircularProgress } from "@mui/material";

export const GlobalLoader = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);

  return (
    <div className="global-loader">
      <CircularProgress />
    </div>
  );
};
