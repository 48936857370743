import styles from "./Round5Report.module.scss";
import GradeIcon from "@mui/icons-material/Grade";

export const Round5Report = ({ r5Details }) => {
  return (
    <>
      {r5Details && (
        <div className={styles.round5Container}>
          <R4Report r4Details={r5Details?.round4Assessment} />
          <R5FinalReport r5Details={r5Details?.round5Assessment} />
        </div>
      )}
    </>
  );
};

const R4Report = ({ r4Details }) => {
  return (
    <div>
      <Summary data={r4Details?.summary} round={4} title={"Summary"} />
      <SubSummary
        title={"Project Evaluation"}
        desc={r4Details?.projectEvaluation}
      />
      <SubSummary
        title={"Technical Skill Evaluation"}
        desc={r4Details?.technicalSkillEvaluation}
      />
      <TotalScore
        rating={r4Details?.totalScore}
        comments={r4Details?.comments}
      />
      {r4Details?.sections?.map((item, index) => (
        <EachCompetency key={index} data={item} />
      ))}
    </div>
  );
};

const R5FinalReport = ({ r5Details }) => {
  return (
    <div className={styles.round5Wrapper}>
      <Summary data={r5Details?.summary} round={5} />
      <TotalScore
        rating={r5Details?.totalScore}
        comments={r5Details?.comments}
      />
      {r5Details?.languages?.map((item, index) => (
        <EachCompetency key={index} data={item} />
      ))}
    </div>
  );
};

const Summary = ({ data, round, title }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.title}>Round {round} : </div>
        {title && <div className={styles.title}>{title}</div>}
      </div>
      {title && <div className={styles.row}>{data}</div>}
    </div>
  );
};

const SubSummary = ({ title, desc }) => {
  return (
    <div className={`${styles.wrapper} ${styles.wrapper2}`}>
      <div className={styles.row2}>{title} : </div>
      <div className={styles.desc}>{desc}</div>
    </div>
  );
};

const TotalScore = ({ rating, comments }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.title}>Total Score : </div>
        <div className={`${styles.flex} ${styles.colorBlue}`}>
          <div>{rating}</div>
          <GradeIcon />
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.title} ${styles.title2}`}>
          Overall Comment :
        </div>
        <div className={styles.comments}>{comments}</div>
      </div>
    </div>
  );
};

const EachCompetency = ({ data }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.title}>{data?.section || data?.language} : </div>
        <div>
          {Array(5)
            .fill(0)
            ?.map((item, index) => (
              <GradeIcon
                key={index}
                style={{
                  color: index + 1 <= data?.rating ? "#062B6E" : "#e0e0e0",
                }}
              />
            ))}
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.title} ${styles.title2}`}>
          HR Observation :
        </div>
        <div className={styles.comments}>{data?.comments}</div>
      </div>
    </div>
  );
};
