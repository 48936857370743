import React, { useState } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import styles from './VettedSkillsEdit.module.scss'; // Import CSS Module
import UpdateButton from '../UpdateButton';
import VettedAdd from './VettedAdd';
import DeleteIcon from "../../../../assets/svgs/delete.svg"; // Import custom delete SVG

const VettedSkillsEdit = ({ vettedSkills, handleClickUpdate }) => {
  const [skillsData, setSkillsData] = useState(vettedSkills);
  const [openVettedAdd, setOpenVettedAdd] = useState(false);

  // Handle input changes for skills and experience
  const handleInputChange = (index, field, value) => {
    const updatedSkills = skillsData.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setSkillsData(updatedSkills);
  };

  // Handle delete row
  const handleDeleteRow = (index) => {
    const updatedSkills = skillsData.filter((_, i) => i !== index);
    setSkillsData(updatedSkills);
  };

  // Handle update button click
  const handleClickUpdateButton = () => {
    const validSkillsData = skillsData.filter((item) => item.skill);
    if (validSkillsData.length > 0) {
      handleClickUpdate('Vetted Technical Skills', validSkillsData);
    }
    else{
      handleClickUpdate('Vetted Technical Skills', []);
    }
  };

  const handleSubmitVettedAdd = (vettedArray) => {
    setSkillsData([...skillsData, ...vettedArray]);
    setOpenVettedAdd(false);
  };

  const handleCloseVettedAdd = () => {
    setOpenVettedAdd(false);
  };

  return (
    <div className={styles.vetted}>
      <VettedAdd open={openVettedAdd} onClose={handleCloseVettedAdd} handleSubmit={handleSubmitVettedAdd} />
      <TableContainer component={Paper} className={styles['table-container']}>
        <Table>
          <TableHead>
            <TableRow className={styles['table-header-row']}>
              <TableCell sx={{ padding: '0px' }} className={styles['table-header']}>Skills</TableCell>
              <TableCell sx={{ padding: '0px' }} className={styles['table-header']}>Experience</TableCell>
              <TableCell className={styles['table-header']} align="right">
                <Button
                  onClick={() => setOpenVettedAdd(true)}
                  variant="outlined"
                  sx={{
                    borderRadius: '4px',
                    border: '1px solid #062B6E',
                    color: '#062B6E',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '0.2px',
                    textTransform: 'none',
                  }}
                >
                  Add Vetted Skills
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skillsData?.map((skillItem, index) => (
              <TableRow key={index} className={styles['table-row']}>
                <TableCell className={styles['table-cell']} sx={{ padding: '0px', paddingBottom: '10px' }}>
                  <TextField
                    value={skillItem?.skill}
                    onChange={(e) => handleInputChange(index, 'skill', e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px' }}
                  />
                </TableCell>
                <TableCell className={styles['table-cell']} sx={{ padding: '0px', paddingBottom: '10px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <TextField
                    value={skillItem?.experience}
                    onChange={(e) => handleInputChange(index, 'experience', e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px' }}
                  />
                  <IconButton onClick={() => handleDeleteRow(index)} aria-label="delete" sx={{ padding: 0 }}>
                    <img src={DeleteIcon} alt="delete" style={{ width: '25px', height: '25px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <UpdateButton name="summary" handleClickUpdateButton={handleClickUpdateButton} />
    </div>
  );
};

export default VettedSkillsEdit;
